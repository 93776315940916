import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import plu from "./img/plus.png";
import min from "./img/minus.png";
import React, { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import "./topping.css";
import { useLocation } from "react-router-dom";
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
  NavLink,
} from "react-router-dom";

function Partywings() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();
  const pizzaname = searchparams.get("Name");
  const pizzaprice = searchparams.get("price");
  const wingsize = searchparams.get("size");
  const lbsize = searchparams.get("count");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [width, setWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCrust, setSelectedCrust] = useState(null);
  const [priceS, setPriceS] = useState(1);
  const [sauceoption, setSauceOption] = useState(null);
  const [instructions, setInstructions] = useState("");

  const size = [
    { name: "1 LB", price: "+$0.00" },
    { name: "2 LB", price: "+$8.50" },
    { name: "3 LB", price: "+$19.50" },
    { name: "4 LB", price: "+$29.50" },
  ];
  useEffect(() => {
    size.map((mapp) => {
      if (mapp.name.includes(wingsize)) {
        setSelectedOption(mapp.name);
      }
    });
  }, []);
  const [pre, setPre] = useState([]);
  useEffect(() => {
    const temp = searchparams.get("Name").split(" ");

    setPre(temp);
    console.log(temp[0]);
    if (temp[0] == "3") {
      setSelectedOption("3 LB");
    } else {
      setSelectedOption("4 LB");
    }
  }, [searchparams]);
  const handleInstructionsChange = (event) => {
    setInstructions(event.target.value);
  };

  const sauceside = [
    { name: "on the wings" },
    { name: "on side" },
    { name: "Others" },
  ];
  //   useEffect(() => {
  //     if (
  //       (selectedOption != "Small" && selectedCrust == "Cauliflower") ||
  //       (selectedOption != "Medium" && selectedCrust == "Gluten-free")
  //     ) {
  //       setSelectedCrust(null);
  //     }
  //   }, [selectedOption]);

  const Toppin = [
    { name: "Mild", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "boneless", multi: 1, quantity: 1, type: "boneless" },
    { name: "No breading", multi: 0, quantity: 1, type: "boneless" },
    { name: "Medium", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Honey Garlic", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "BBQ", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Whiskey", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Pineapple curry", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Thai", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Tequila lime", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Teriyaki", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Honey mustard", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Fire & ice", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Caesar cajun", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Buffalo", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Bundy", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Red thai", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Honey BBQ", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Spicy thai", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Buffalo Blue", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Gar par", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Hot ranch", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Maple cajun", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Hot (spicy)", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "3rd degree (spicy)", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Jerk (spicy)", multi: 1.75, quantity: 1, type: "sauce" },
    { name: "Sriracha (spicy)", multi: 1.75, quantity: 1, type: "sauce" },
    {
      name: "Roasted garlic pepper",
      multi: 1.75,
      quantity: 1,
      type: "dry rub",
    },
    { name: "Tex mex", multi: 1.75, quantity: 1, type: "dry rub" },
    { name: "Lemon pepper", multi: 1.75, quantity: 1, type: "dry rub" },
    { name: "Parm pepper", multi: 1.75, quantity: 1, type: "dry rub" },
    { name: "Cajun const", multi: 1.75, quantity: 1, type: "dry rub" },
  ];
  const [toppings, setToppings] = useState(Toppin);
  const handleChange = (e) => {
    const { name, checked } = e.target;
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          return {
            ...topping,
            selected: checked,
            quantity: checked ? topping.quantity : 1,
          };
        }
        return topping;
      })
    );
  };
  const submit = (event) => {
    var items = "";

    if (selectedOption == null || sauceoption == null) {
      event.preventDefault();
      alert(
        "Size and Sauce option is required options please select the size."
      );
    } else {
      console.log(getSelectedToppings());
      console.log(selectedCrust);
      console.log(selectedOption);
      console.log(instructions);
      items =
        "Wings" +
        "," +
        selectedOption +
        "," +
        toppings
          .filter((topping) => topping.selected)
          .map((t) => t.quantity + " " + t.name)
          .join(",") +
        "," +
        (instructions!=""?"Instructions: " +instructions:"" 
        )+
        "," +
        sauceoption +
        "," +
        parseFloat(1) +
        "," +
        parseFloat(
          getSelectedToppings().reduce(
            (acc, topping) => acc + topping.multi * topping.quantity * priceS,
            0
          )
        ).toFixed(2);
      const itemarr = items.split(",");
      localStorage.setItem("wings", JSON.stringify(itemarr));
    }
  };

  const handleQuantityChange = (name, operation) => {
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          let newQuantity =
            operation === "add" ? topping.quantity + 1 : topping.quantity - 1;
          if (newQuantity < 1) newQuantity = 1;
          return { ...topping, quantity: newQuantity };
        }
        return topping;
      })
    );
  };

  const getSelectedToppings = () => {
    return toppings.filter((topping) => topping.selected);
  };

  const sizeadd = () => {
    if (selectedCrust == "Gluten-free" || selectedCrust == "Cauliflower") {
      if (selectedOption == "Large") {
        return 8.55;
      } else if (selectedOption == "Medium") {
        return 6.55;
      } else {
        return 5.0;
      }
    } else {
      if (selectedOption == "4 LB") {
        return 29.5;
      } else if (selectedOption == "3 LB") {
        return 19.5;
      } else if (selectedOption == "2 LB") {
        return 8.5;
      } else {
        return 0.0;
      }
    }
  };
  return (
    <div>
      <div>
        <div
          style={{
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
            <img
              src={logo}
              style={{
                width: width > "450" ? "30vw" : "40vw",
                alignSelf: "flex-start",
                margin: 20,
              }}
            />
          </NavLink>
          <CartIcon color={"#ff0000"} />
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <h1
          style={{
            color: "#ff0000",
            fontSize: width < "468" ? "8vw" : "5vw",
            fontFamily: "MyFont",
            marginTop: 0,
            textAlign: "center",
          }}
        >
          {pizzaname !=
          "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
            ? pizzaname
            : "Mega deal"}
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "800px",
              width: "90vw",
            }}
          >
            <p
              style={{
                fontFamily: "MyFont",
                fontSize: width < "468" ? "8vw" : "3vw",
                color: "#ff0000",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              Size
            </p>
            <div className="size">
              {size.map((siz) => {
                return (
                  <div className="radio-button-container">
                    <input
                      type="radio"
                      id={siz.name}
                      name="size"
                      value={siz.name}
                      checked={siz.name == selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      disabled
                    />
                    <label
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "2vw",
                      }}
                      htmlFor={siz.name}
                    >
                      {siz.name}
                    </label>
                    {/* <p
                      style={{
                        fontFamily: "MyFont",
                        color: "#ff0000",
                        fontSize: width < "468" ? "6vw" : "2vw",
                        marginLeft: "auto",
                        marginTop: 0,
                        marginBottom: 10,
                      }}
                    >
                      {siz.price}
                    </p> */}
                  </div>
                );
              })}
            </div>
            <div className="toppings">
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Boneless?
              </p>
              {toppings.map(
                (topping) =>
                  topping.type == "boneless" && (
                    <div
                      key={topping.name}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        name={topping.name}
                        onChange={handleChange}
                        checked={topping.selected}
                      />
                      <label
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                      >
                        {topping.name}
                      </label>
                      {topping.selected ? (
                        <p></p>
                      ) : (
                        <p
                          style={{
                            fontFamily: "MyFont",
                            color: "#ff0000",
                            fontSize: width < "468" ? "6vw" : "2vw",
                            marginLeft: "auto",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          +${(topping.multi * priceS).toFixed(2)}
                        </p>
                      )}
                    </div>
                  )
              )}
            </div>

            <div className="toppings">
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Sauce
              </p>
              {toppings.map(
                (topping) =>
                  topping.type == "sauce" && (
                    <div
                      key={topping.name}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        name={topping.name}
                        onChange={handleChange}
                        checked={topping.selected}
                      />
                      <label
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                      >
                        {topping.name}
                      </label>
                      {topping.selected ? (
                        <div
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          <button
                            style={{ border: "0px", background: "#ffffff" }}
                            onClick={() =>
                              handleQuantityChange(topping.name, "minus")
                            }
                          >
                            <img
                              src={min}
                              style={{
                                width: width < "468" ? "3vw" : "1vw",
                                marginTop: 8,
                              }}
                            />
                          </button>
                          <span
                            style={{
                              margin: "0 10px",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                            }}
                          >
                            {topping.quantity}
                          </span>
                          <button
                            style={{ border: "0px", background: "#ffffff" }}
                            onClick={() =>
                              handleQuantityChange(topping.name, "add")
                            }
                          >
                            <img
                              src={plu}
                              style={{
                                width: width < "468" ? "4vw" : "1.5vw",
                                marginTop: 8,
                              }}
                            />
                          </button>
                        </div>
                      ) : (
                        <p
                          style={{
                            fontFamily: "MyFont",
                            color: "#ff0000",
                            fontSize: width < "468" ? "6vw" : "2vw",
                            marginLeft: "auto",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          +${(topping.multi * priceS).toFixed(2)}
                        </p>
                      )}
                    </div>
                  )
              )}
            </div>
            <div className="toppings">
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Dry rub
              </p>
              {toppings.map(
                (topping) =>
                  topping.type == "dry rub" && (
                    <div
                      key={topping.name}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        name={topping.name}
                        onChange={handleChange}
                        checked={topping.selected}
                      />
                      <label
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                      >
                        {topping.name}
                      </label>
                      {topping.selected ? (
                        <div
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          <button
                            style={{ border: "0px", background: "#ffffff" }}
                            onClick={() =>
                              handleQuantityChange(topping.name, "minus")
                            }
                          >
                            <img
                              src={min}
                              style={{
                                width: width < "468" ? "3vw" : "1vw",
                                marginTop: 8,
                              }}
                            />
                          </button>
                          <span
                            style={{
                              margin: "0 10px",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                            }}
                          >
                            {topping.quantity}
                          </span>
                          <button
                            style={{ border: "0px", background: "#ffffff" }}
                            onClick={() =>
                              handleQuantityChange(topping.name, "add")
                            }
                          >
                            <img
                              src={plu}
                              style={{
                                width: width < "468" ? "4vw" : "1.5vw",
                                marginTop: 8,
                              }}
                            />
                          </button>
                        </div>
                      ) : (
                        <p
                          style={{
                            fontFamily: "MyFont",
                            color: "#ff0000",
                            fontSize: width < "468" ? "6vw" : "2vw",
                            marginLeft: "auto",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          +${(topping.multi * priceS).toFixed(2)}
                        </p>
                      )}
                    </div>
                  )
              )}
            </div>
            <p
              style={{
                fontFamily: "MyFont",
                fontSize: width < "468" ? "8vw" : "3vw",
                color: "#ff0000",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              Sauce Options
            </p>
            <p style={{ fontFamily: "MyFont" }}>
              For other instruction please fill in special instruction box.
            </p>
            <div className="sauceoption">
              {sauceside.map((siz) => {
                return (
                  <div className="radio-button-container">
                    <input
                      type="radio"
                      id={siz.name}
                      name="sauceoption"
                      value={siz.name}
                      checked={sauceoption === siz.name}
                      onChange={(e) => setSauceOption(e.target.value)}
                    />
                    <label
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "2vw",
                      }}
                      htmlFor={siz.name}
                    >
                      {siz.name}
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="instructions" style={{ marginBottom: "100px" }}>
              <label>
                <p
                  style={{
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "8vw" : "3vw",
                    color: "#ff0000",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  Special Instructions
                </p>

                <textarea
                  className="resizable"
                  value={instructions}
                  onChange={handleInstructionsChange}
                  style={{
                    width: "93%",
                    maxWidth: "800px",
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "6vw" : "2vw",
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <NavLink
          to={{
            pathname: "/party",
            search: createSearchParams({
              Name: pizzaname,
              price: pizzaprice,
            }).toString(),
          }}
          onClick={submit}
        >
          <button
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              position: "fixed",
              bottom: "10px",
              height: "auto",
              padding: 15,
              borderRadius: 25,
              background: "#ff0000",
            }}
          >
            <span
              style={{
                color: "white",
                fontFamily: "MyFont",
                fontSize: width < "450" ? 20 : 30,
              }}
            >
              Submit +$
              {parseFloat(
                getSelectedToppings().reduce(
                  (acc, topping) =>
                    acc + topping.multi * topping.quantity * priceS,
                  0
                )
              ).toFixed(2)}
            </span>
          </button>
        </NavLink>
      </div>
    </div>
  );
}

export default Partywings;
