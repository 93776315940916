import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import plu from "./img/plus.png";
import min from "./img/minus.png";
import React, { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import "./topping.css";
import { useLocation } from "react-router-dom";
import pizzawhole from "./img/pizzawhole.png";
import pizzaleft from "./img/pizzaleft.png";
import pizzaright from "./img/pizzaright.png";
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
  NavLink,
} from "react-router-dom";

function Pop() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();
  const pizzaname = searchparams.get("Name");
  const pizzaprice = searchparams.get("price");
  const from = searchparams.get("from");

  var pre = [];
  useEffect(() => {
    if (searchparams.get("disc")) {
      pre = searchparams.get("disc").split(/,\s| and\s/);
    }
    console.log(from);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [width, setWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSauce, setSelectedSauce] = useState("Pizza sauce");
  const [selectedCrust, setSelectedCrust] = useState(null);
  const [priceS, setPriceS] = useState(1);
  const [instructions, setInstructions] = useState("");
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");
  const handleInstructionsChange = (event) => {
    setInstructions(event.target.value);
  };
  useEffect(() => {
    console.log(selectedSauce);
  }, [selectedSauce]);
  const crusts = [
    { name: "Regular", size: "All", price: 0 },
    { name: "Thick", size: "All", price: 0 },
    { name: "Crispy", size: "All", price: 0 },
    { name: "Thin", size: "All", price: 0 },
    { name: "Gluten-free", size: "Medium", price: 5 },
    { name: "Cauliflower", size: "Small", price: 5 },
  ];

  const size = [
    { name: "Small", price: "+$0.00" },
    { name: "Medium", price: "+$2.00" },
    { name: "Large", price: "+$3.00" },
  ];
  const toppingcounts = () => {
    let toppingcount = 0;
    console.log(getSelectedToppings().length);
    getSelectedToppings().map((toppingg) => {
      toppingcount = toppingcount + toppingg.multi * toppingg.quantity;
    });

    return toppingcount;
  };
  const pricecount = () => {
    let toppingcount = 0;
    let toppingprice = 0;
    let sauce = 0;
    console.log(from);
    getSelectedToppings().map((toppingg) => {
      toppingcount = toppingcount + toppingg.multi * toppingg.quantity;
    });
    console.log(toppingcount);
    if (from == "pwo") {
      if (toppingcount > 7.8) {
        toppingprice = toppingcount - 7.8;
      } else {
        toppingprice = 0;
      }
    } else {
      toppingprice = toppingcount;
    }
    return toppingprice;
  };
  useEffect(() => {
    if (selectedOption == "Large") {
      setPriceS(2);
    } else if (selectedOption == "Medium") {
      setPriceS(1.5);
    } else {
      setPriceS(1);
    }
  }, [selectedOption]);
  useEffect(() => {
    if (
      (selectedOption != "Small" && selectedCrust == "Cauliflower") ||
      (selectedOption != "Medium" && selectedCrust == "Gluten-free")
    ) {
      setSelectedCrust(null);
    }
  }, [selectedOption]);

  const Toppin = [
    { name: "Pepsi Can", multi: 1.95, quantity: 1, type: "Pop", side: "whole" },
    {
      name: "Diet Pepsi Can",
      multi: 1.95,
      quantity: 1,
      type: "Pop",
      side: "whole",
    },
    {
      name: "Root beer Can",
      multi: 1.95,
      quantity: 1,
      type: "Pop",
      side: "whole",
    },
    {
      name: "Dr. pepper Can",
      multi: 1.95,
      quantity: 1,
      type: "Pop",
      side: "whole",
    },
    {
      name: "Mountain Dew Can",
      multi: 1.95,
      quantity: 1,
      type: "Pop",
      side: "whole",
    },
    {
      name: "iced tea Can",
      multi: 1.95,
      quantity: 1,
      type: "Pop",
      side: "whole",
    },
    {
      name: "Diet coke Can",
      multi: 1.95,
      quantity: 1,
      type: "Pop",
      side: "whole",
    },
    {
      name: "Coca-cola Can",
      multi: 1.95,
      quantity: 1,
      type: "Pop",
      side: "whole",
    },
    {
      name: "Sprite Can",
      multi: 1.95,
      quantity: 1,
      type: "Pop",
      side: "whole",
    },
    { name: "7up Can", multi: 1.95, quantity: 1, type: "Pop", side: "whole" },

    {
      name: "Pepsi Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Diet Pepsi Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Root beer Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Dr. pepper Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Mountain Dew Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "iced tea Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Diet coke Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Coca-cola Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Sprite Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "7up Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Water Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Cream Soda Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
    {
      name: "Grape Bottle",
      multi: 2.5,
      quantity: 1,
      type: "Bottle",
      side: "whole",
    },
  ];

  const [toppings, setToppings] = useState(Toppin);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          return {
            ...topping,
            selected: checked,
            quantity: checked ? topping.quantity : 1,
          };
        }

        return topping;
      })
    );
  };
  const handleside = (indexx, sides) => {
    const { name } = toppings[indexx];
    console.log(name, sides);
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          return {
            ...topping,
            side: sides,
          };
        }

        return topping;
      })
    );
  };

  const [unedit, setUnedit] = useState([]);
  useEffect(() => {
    console.log(pre);
    pre.map((ma) => {
      setToppings((prevToppings) =>
        prevToppings.map((topping) => {
          if (topping.name === ma) {
            return {
              ...topping,
              selected: true,
              quantity: true ? topping.quantity : 1,
            };
          }
          return topping;
        })
      );

      console.log(unedit);
    });
  }, []);

  const submit = (event) => {
    var items = "";

    if (from == "pwo" && toppingcounts() < 7.8) {
      event.preventDefault();
      alert("Please choose atlease 4 drinks");
    } else if (toppingcounts() == 0) {
      event.preventDefault();
      alert("Please choose a drinks");
    } else {
      console.log(getSelectedToppings());
      console.log(selectedCrust);
      console.log(selectedOption);
      console.log(instructions);
      items =
        "Pop" +
        "," +
        "" +
        "," +
        toppings
          .filter((topping) => topping.selected)
          .map((t) => t.quantity + " " + t.name)
          .join(",") +
        "," +
        instructions +
        "," +
        parseFloat(1) +
        "," +
        pricecount().toFixed(2);
      itemarr = [...itemarr, items.split(",")];
      if (from == "pwo") {
        localStorage.setItem("pop", JSON.stringify(items.split(",")));
      } else {
        localStorage.setItem("array", JSON.stringify(itemarr));
      }
    }
  };

  const handleQuantityChange = (name, operation) => {
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          let newQuantity =
            operation === "add" ? topping.quantity + 1 : topping.quantity - 1;
          if (newQuantity < 1) newQuantity = 1;
          return { ...topping, quantity: newQuantity };
        }
        return topping;
      })
    );
  };

  const getSelectedToppings = () => {
    return toppings.filter((topping) => topping.selected);
  };

  const sizeadd = () => {
    if (selectedCrust == "Gluten-free" || selectedCrust == "Cauliflower") {
      if (selectedOption == "Large") {
        return 8.0;
      } else if (selectedOption == "Medium") {
        return 7.0;
      } else {
        return 5.0;
      }
    } else {
      if (selectedOption == "Large") {
        return 3.0;
      } else if (selectedOption == "Medium") {
        return 2.0;
      } else {
        return 0.0;
      }
    }
  };
  return (
    <div>
      <div>
        <div
          style={{
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
            <img
              src={logo}
              style={{
                width: width > "450" ? "30vw" : "40vw",
                alignSelf: "flex-start",
                margin: 20,
              }}
            />
          </NavLink>
          <CartIcon color={"#ff0000"} />
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <h1
          style={{
            color: "#ff0000",
            fontSize: width < "468" ? "8vw" : "5vw",
            fontFamily: "MyFont",
            marginTop: 0,
            textAlign: "center",
          }}
        >
          Pop
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "800px",

              width: "90vw",
            }}
          >
            <div className="Pop" style={{ marginBottom: "50px" }}>
              {toppings.map((topping) => {
                if (from == "pwo") {
                  if (topping.type == "Pop") {
                    return (
                      <div
                        key={topping.name}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          name={topping.name}
                          onChange={handleChange}
                          checked={topping.selected}
                        />
                        <label
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                        >
                          {topping.name}
                        </label>
                        {topping.selected ? (
                          <div
                            style={{
                              marginLeft: "auto",
                              display: "flex",
                              alignItems: "center",
                              marginTop: 0,
                              marginBottom: 10,
                            }}
                          >
                            <button
                              style={{ border: "0px", background: "#ffffff" }}
                              onClick={() =>
                                handleQuantityChange(topping.name, "minus")
                              }
                            >
                              <img
                                src={min}
                                style={{
                                  width: width < "468" ? "3vw" : "1vw",
                                  marginTop: 8,
                                }}
                              />
                            </button>
                            <span
                              style={{
                                margin: "0 10px",
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "2vw",
                              }}
                            >
                              {topping.quantity}
                            </span>
                            <button
                              style={{
                                border: "0px",
                                background: "#ffffff",
                              }}
                              onClick={() =>
                                handleQuantityChange(topping.name, "add")
                              }
                            >
                              <img
                                src={plu}
                                style={{
                                  width: width < "468" ? "4vw" : "1.5vw",
                                  marginTop: 8,
                                }}
                              />
                            </button>
                          </div>
                        ) : (
                          <div
                            style={{
                              marginLeft: "auto",
                              display: "flex",
                              alignItems: "center",
                              marginTop: 0,
                              marginBottom: 10,
                            }}
                          >
                            <p
                              style={{
                                color: "#ff0000",
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "2vw",
                                margin: 0,
                                justifyContent: "flex-end",
                              }}
                            >
                              +$
                              {(from == "pwo"
                                ? toppingcounts() >= 7.8
                                  ? topping.multi * 1
                                  : 0
                                : topping.multi * 1
                              ).toFixed(2)}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  }
                } else {
                  return (
                    <div
                      key={topping.name}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        name={topping.name}
                        onChange={handleChange}
                        checked={topping.selected}
                      />
                      <label
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                      >
                        {topping.name}
                      </label>
                      {topping.selected ? (
                        <div
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          <button
                            style={{ border: "0px", background: "#ffffff" }}
                            onClick={() =>
                              handleQuantityChange(topping.name, "minus")
                            }
                          >
                            <img
                              src={min}
                              style={{
                                width: width < "468" ? "3vw" : "1vw",
                                marginTop: 8,
                              }}
                            />
                          </button>
                          <span
                            style={{
                              margin: "0 10px",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                            }}
                          >
                            {topping.quantity}
                          </span>
                          <button
                            style={{
                              border: "0px",
                              background: "#ffffff",
                            }}
                            onClick={() =>
                              handleQuantityChange(topping.name, "add")
                            }
                          >
                            <img
                              src={plu}
                              style={{
                                width: width < "468" ? "4vw" : "1.5vw",
                                marginTop: 8,
                              }}
                            />
                          </button>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          <p
                            style={{
                              color: "#ff0000",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                              margin: 0,
                              justifyContent: "flex-end",
                            }}
                          >
                            +$
                            {(from == "pwo"
                              ? toppingcounts() >= 7.8
                                ? topping.multi * 1
                                : 0
                              : topping.multi * 1
                            ).toFixed(2)}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>{" "}
      </div>
      <div
        style={{
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <NavLink
          to={{
            pathname: from == "pwo" ? "/pwo" : "/cart",
            search: createSearchParams({
              Name: pizzaname,
              price: pizzaprice,
            }).toString(),
          }}
          onClick={submit}
        >
          <button
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              position: "fixed",
              bottom: "10px",
              height: "auto",
              padding: 15,
              borderRadius: 25,
              background: "#ff0000",
            }}
          >
            <span
              style={{
                color: "white",
                fontFamily: "MyFont",
                fontSize: width < "450" ? 20 : 30,
              }}
            >
              Submit $
              {
                /* {parseFloat(
                getSelectedToppings().reduce(
                  (acc, topping) =>
                    acc + topping.multi * topping.quantity * priceS,
                  sizeadd() +
                    parseFloat(pizzaprice) +
                    unedit.length *
                      (selectedOption == "Large"
                        ? 2
                        : selectedOption == "Medium"
                        ? 1.5
                        : 1)
                )
              ).toFixed(2)} */
                pricecount().toFixed(2)
              }
            </span>
          </button>
        </NavLink>
      </div>
    </div>
  );
}

export default Pop;
