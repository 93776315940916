import React, { useState, useEffect } from "react";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "./store.css";
import {
  createSearchParams,
  useLocation,
  useSearchParams,
  useNavigate,
  NavLink,
} from "react-router-dom";
import { CloseCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";

import { db, auth } from "./firebase";
import { serverTimestamp } from "firebase/firestore";
import { notification } from "antd";
import logo from "./img/logonobgf.png";
const phoneNumberRegex = /^\d{10}$/;
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const option = [{ name: "Delivery" }, { name: "Pick Up" }];
dayjs.extend(timezone);
dayjs.extend(utc);
const payoptions = [
  { name: "Cash", price: 4 },
  { name: "Debit", price: 5 },
];
const timeoptions = [{ name: "Now" }, { name: "Schedule" }];
const Form = () => {
  const [timeOption, setTimeOption] = useState(null);
  const navigate = useNavigate();
  const [selectedTime, setSelectedTime] = useState(null);
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();
  const prosmall = searchparams.get("prosmall");
  const promed = searchparams.get("promed");
  const prolar = searchparams.get("prolar");
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  function handleTimeChange(time, timeString) {
    // convert to UTC format
    // console.log(dayjs.utc(selectedTime).tz("America/Toronto").format("hh:mm A"));
    console.log(time);
    setSelectedTime(time);
    console.log( dayjs(time).tz("America/Toronto").format("hh:mm A"));
  }
  useEffect(() => {
    check();
  }, [selectedTime, dayjs(), timeOption]);

  const check = () => {
    const day = dayjs().day();
    const hour = dayjs().hour();
    console.log(selectedTime);
    if (timeOption == "Now" || timeOption == null) {
      setError(false);
    } else if (selectedTime != null) {
      if (day >= 1 && day <= 4) {
        if (selectedTime.hour() < 15 && selectedTime.hour() >= 1) {
          setError(true);
        } else {
          setError(false);
        }
      } else if (day === 5) {
        if (hour < 1) {
          if (selectedTime.hour() < 15 && selectedTime.hour() >= 1) {
            setError(true);
          } else {
            setError(false);
          }
        } else {
          if (selectedTime.hour() < 15 && selectedTime.hour() >= 2) {
            setError(true);
          } else {
            setError(false);
          }
        }
      } else if (day === 6) {
        if (selectedTime.hour() < 15 && selectedTime.hour() >= 2) {
          setError(true);
        } else {
          setError(false);
        }
      } else if (day === 0) {
        if (hour < 2) {
          if (
            (selectedTime.hour() < 15 && selectedTime.hour() >= 2) ||
            selectedTime.hour() > 22
          ) {
            setError(true);
          } else {
            setError(false);
          }
        } else {
          if (selectedTime.hour() < 15 || selectedTime.hour() > 22) {
            setError(true);
          } else {
            setError(false);
          }
        }
      }
    }
  };
  const openNotification = (sucess) => {
    notification.open({
      message: sucess ? "Sucess" : "Error",
      description: sucess
        ? "Order submitted sucessfully."
        : "There was an error submitting your order.",
      icon: sucess ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#ff0000" />
      ),
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    street: "",
    apartmentNumber: "",
    city: "",
    province: "Ontario",
    postalCode: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    street: "",
    apartmentNumber: "",
    city: "",
    province: "",
    postalCode: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const validateForm = () => {
    let tempErrors = {};
    let formIsValid = true;
    console.log(!formData.name);
    if (!formData.name) {
      tempErrors.name = "Name is required";
      formIsValid = false;
    }

    if (!formData.phoneNumber) {
      tempErrors.phoneNumber = "Phone number is required";
      formIsValid = false;
    } else if (!phoneNumberRegex.test(formData.phoneNumber)) {
      tempErrors.phoneNumber = "Invalid phone number";
      formIsValid = false;
    }
    if (!formData.email) {
      tempErrors.email = "Email is required";
      formIsValid = false;
    } else if (!emailRegex.test(formData.email)) {
      tempErrors.email = "Invalid email";
      formIsValid = false;
    }
    if (selectedOption == "Delivery") {
      if (!formData.street) {
        tempErrors.street = "Street is required";
        formIsValid = false;
      }

      if (!formData.city) {
        tempErrors.city = "City is required";
        formIsValid = false;
      }

      if (!formData.province) {
        tempErrors.province = "Province is required";
        formIsValid = false;
      }

      if (!formData.postalCode) {
        tempErrors.postalCode = "Postal code is required";
        formIsValid = false;
      } else if (!postalCodeRegex.test(formData.postalCode)) {
        tempErrors.postalCode = "Invalid postal code";
        formIsValid = false;
      }
    }
    setErrors(tempErrors);
    console.log(formIsValid, tempErrors);
    return formIsValid;
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState(null);
  const [payOption, setPayOption] = useState(null);

  const [storedData, setStoredData] = useState(
    JSON.parse(localStorage.getItem("array") || "[]")
  );
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(selectedTime);
    if (!selectedOption || !payOption || !timeOption) {
      alert("Please select delivery option, time and payment option.");
    } else if (error || (timeOption == "Schedule" && selectedTime == null)) {
      alert("Selected Time is not available");
    } else {
      if (validateForm() && !error) {
        console.log(validateForm());
        console.log(!error);
        db.collection("Neworder")
          .add({
            Name: formData.name,
            Phone: formData.phoneNumber,
            Email: formData.email,
            Address:
              (formData.apartmentNumber && formData.apartmentNumber + " - ") +
              formData.street +
              "," +
              formData.city +
              "," +
              formData.postalCode,
            Method: selectedOption,
            Pay: payOption,
            Timeoption: timeOption,
            Timewant:
              timeOption == "Schedule" && 
              dayjs(selectedTime).tz("America/Toronto").format("hh:mm A"),
            Order: JSON.stringify(storedData),
            Price: (
              (selectedOption == "Delivery"
                ? payOption == null
                  ? parseFloat(count)
                  : payOption == "Cash"
                  ? parseFloat(count) + 4
                  : parseFloat(count) + 5
                : parseFloat(count)) * 1.13
            ).toFixed(2),
            Time: serverTimestamp(),
          })
          .catch((e) => openNotification(true))
          .then(
            openNotification(true),
            localStorage.removeItem("array"),
            navigate("/")
          );
      }
    }
  };
  const [count, setCount] = useState(0);
  useEffect(() => {
    var temp = 0;
    storedData.map((item) => {
      temp = temp + parseFloat(item[item.length - 1]) * item[item.length - 2];

      console.log(temp);
    });
    temp = temp - prosmall * 2.95 - promed * 3.95 - prolar * 4.95;
    setCount(parseFloat(temp).toFixed(2));
  }, []);

  return (
    <div>
      <div>
        <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
          <img
            src={logo}
            style={{
              width: width > "450" ? "30vw" : "40vw",
              alignSelf: "flex-start",
              margin: 20,
            }}
          />
        </NavLink>
      </div>

      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "90vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontFamily: "MyFont",
                fontSize: width < "468" ? "8vw" : "3vw",
                color: "#ff0000",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              Method
            </p>
            <div
              className="option"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: 20,
                maxWidth: "800px",
                width: "90vw",
              }}
            >
              {option.map((siz) => {
                return (
                  <div className="radio-button-container">
                    <input
                      type="radio"
                      id={siz.name}
                      name="size"
                      value={siz.name}
                      checked={selectedOption === siz.name}
                      onChange={(e) => setSelectedOption(e.target.value)}
                    />
                    <label
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "2vw",
                      }}
                      htmlFor={siz.name}
                    >
                      {siz.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                  width: "45%",
                }}
              >
                <label
                  style={{
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "7vw" : "2.5vw",
                    color: "#ff0000",
                  }}
                  htmlFor="name"
                >
                  Name:
                </label>
                <input
                  style={{
                    border: "0px",
                    borderBottom: "1px solid black",
                    height: width < "468" ? "7vw" : "2.5vw",
                    fontSize: width < "468" ? "6.5vw" : "2vw",
                    fontFamily: "MyFont",
                  }}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {formSubmitted && errors.name && (
                  <div style={{ color: "red" }}>{errors.name}</div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                  width: "45%",
                }}
              >
                <label
                  style={{
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "7vw" : "2.5vw",
                    color: "#ff0000",
                  }}
                  htmlFor="phoneNumber"
                >
                  Phone Number:
                </label>
                <input
                  style={{
                    border: "0px",
                    borderBottom: "1px solid black",
                    height: width < "468" ? "7vw" : "2.5vw",
                    fontSize: width < "468" ? "6.5vw" : "2vw",
                    fontFamily: "MyFont",
                  }}
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
                {formSubmitted && errors.phoneNumber && (
                  <div style={{ color: "red" }}>{errors.phoneNumber}</div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              <label
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "7vw" : "2.5vw",
                  color: "#ff0000",
                }}
                htmlFor="email"
              >
                E-mail:
              </label>
              <input
                style={{
                  border: "0px",
                  borderBottom: "1px solid black",
                  height: width < "468" ? "7vw" : "2.5vw",
                  fontSize: width < "468" ? "6.5vw" : "2vw",
                  fontFamily: "MyFont",
                }}
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {formSubmitted && errors.email && (
                <div style={{ color: "red" }}>{errors.email}</div>
              )}
            </div>
            {selectedOption == "Delivery" && (
              <div style={{ marginBottom: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 10,
                  }}
                >
                  <label
                    style={{
                      fontFamily: "MyFont",
                      fontSize: width < "468" ? "7vw" : "2.5vw",
                      color: "#ff0000",
                    }}
                    htmlFor="street"
                  >
                    Street:
                  </label>
                  <input
                    style={{
                      border: "0px",
                      borderBottom: "1px solid black",
                      height: width < "468" ? "7vw" : "2.5vw",
                      fontSize: width < "468" ? "6.5vw" : "2vw",
                      fontFamily: "MyFont",
                    }}
                    type="text"
                    id="street"
                    name="street"
                    value={formData.street}
                    onChange={handleChange}
                  />
                  {formSubmitted && errors.street && (
                    <div style={{ color: "red" }}>{errors.street}</div>
                  )}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 10,
                      width: "45%",
                    }}
                  >
                    <label
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "7vw" : "2.5vw",
                        color: "#ff0000",
                      }}
                      htmlFor="apartmentNumber"
                    >
                      Apt Number:
                    </label>
                    <input
                      style={{
                        border: "0px",
                        borderBottom: "1px solid black",
                        height: width < "468" ? "7vw" : "2.5vw",
                        fontSize: width < "468" ? "6.5vw" : "2vw",
                        fontFamily: "MyFont",
                      }}
                      type="text"
                      id="apartmentNumber"
                      name="apartmentNumber"
                      value={formData.apartmentNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 10,
                      width: "45%",
                    }}
                  >
                    <label
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "7vw" : "2.5vw",
                        color: "#ff0000",
                      }}
                      htmlFor="city"
                    >
                      City:
                    </label>
                    <input
                      style={{
                        border: "0px",
                        borderBottom: "1px solid black",
                        height: width < "468" ? "7vw" : "2.5vw",
                        fontSize: width < "468" ? "6.5vw" : "2vw",
                        fontFamily: "MyFont",
                      }}
                      type="text"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                    {formSubmitted && errors.city && (
                      <div style={{ color: "red" }}>{errors.city}</div>
                    )}
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 10,
                      width: "45%",
                    }}
                  >
                    <label
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "7vw" : "2.5vw",
                        color: "#ff0000",
                      }}
                      htmlFor="province"
                    >
                      Province:
                    </label>
                    <input
                      style={{
                        border: "0px",
                        borderBottom: "1px solid black",
                        height: width < "468" ? "7vw" : "2.5vw",
                        fontSize: width < "468" ? "6.5vw" : "2vw",
                        fontFamily: "MyFont",
                        border: "0px",
                        borderBottom: "1px solid black",
                      }}
                      type="text"
                      id="province"
                      name="province"
                      value={formData.province}
                      onChange={handleChange}
                      disabled
                    />
                    {formSubmitted && errors.province && (
                      <div style={{ color: "red" }}>{errors.province}</div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 10,
                      width: "45%",
                    }}
                  >
                    <label
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "7vw" : "2.5vw",
                        color: "#ff0000",
                      }}
                      htmlFor="postalCode"
                    >
                      Postal Code:
                    </label>
                    <input
                      style={{
                        border: "0px",
                        borderBottom: "1px solid black",
                        height: width < "468" ? "7vw" : "2.5vw",
                        fontSize: width < "468" ? "6.5vw" : "2vw",
                        fontFamily: "MyFont",
                      }}
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleChange}
                    />
                    {formSubmitted && errors.postalCode && (
                      <div style={{ color: "red" }}>{errors.postalCode}</div>
                    )}
                  </div>{" "}
                </div>{" "}
              </div>
            )}{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Payment Method
              </p>
              <div
                className="option"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                  maxWidth: "800px",
                  width: "90vw",
                }}
              >
                {payoptions.map((siz) => {
                  return (
                    <div
                      className="radio-button-container"
                      style={{
                        marginTop: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="radio"
                          id={siz.name}
                          name="size"
                          value={siz.name}
                          checked={payOption === siz.name}
                          onChange={(e) => setPayOption(e.target.value)}
                        />
                        <label
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                          htmlFor={siz.name}
                        >
                          {siz.name}
                        </label>
                      </div>
                      {selectedOption == "Delivery" && (
                        <div>
                          <p
                            style={{
                              fontFamily: "MyFont",
                              color: "#ff0000",
                              fontSize: width < "468" ? "6vw" : "2vw",

                              margin: 0,
                            }}
                          >
                            +${parseFloat(siz.price).toFixed(2)}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Select Time
              </p>
              <div
                className="option"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                  maxWidth: "800px",
                  width: "90vw",
                }}
              >
                <div
                  className="radio-button-container"
                  style={{
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id={timeoptions[0].name}
                      name="time"
                      value={timeoptions[0].name}
                      checked={timeOption === timeoptions[0].name}
                      onChange={(e) => setTimeOption(e.target.value)}
                    />
                    <label
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "2vw",
                      }}
                      htmlFor={timeoptions[0].name}
                    >
                      {timeoptions[0].name}
                    </label>
                  </div>
                  {/* {selectedOption == "Delivery" && (
                        <div>
                          <p
                            style={{
                              fontFamily: "MyFont",
                              color: "#ff0000",
                              fontSize: width < "468" ? "6vw" : "2vw",

                              margin: 0,
                            }}
                          >
                            +${parseFloat(siz.price).toFixed(2)}
                          </p>
                        </div>
                      )} */}
                </div>
                <div
                  className="radio-button-container"
                  style={{
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id={timeoptions[1].name}
                      name="time"
                      value={timeoptions[1].name}
                      checked={timeOption === timeoptions[1].name}
                      onChange={(e) => setTimeOption(e.target.value)}
                    />
                    <label
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "2vw",
                      }}
                      htmlFor={timeoptions[1].name}
                    >
                      {timeoptions[1].name}
                    </label>
                  </div>
                  {timeOption == "Schedule" && (
                    <div>
                      <p
                        style={{
                          fontFamily: "MyFont",
                          color: "#ff0000",
                          fontSize: width < "468" ? "6vw" : "2vw",

                          margin: 0,
                        }}
                      >
                        <TimePicker
                          style={{ border: "1px solid black", color: "black" }}
                          use12Hours
                          inputReadOnly
                          showNow={false}
                          format="hh:mm A"
                          onChange={handleTimeChange}
                          // onClick={check}
                        />
                      </p>
                    </div>
                  )}
                </div>

                {error && (
                  <p
                    style={{
                      color: "#ff0000",
                      fontFamily: "MyFont",
                      marginLeft: 10,
                    }}
                  >
                    Store is not open on the selected time.
                  </p>
                )}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                marginTop: 10,
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  bottom: "10px",
                  fontFamily: "MyFont",
                  color: "white",
                  width: "30vh",
                  fontSize: width < "468" ? "6.5vw" : "2vw",
                  height: "auto",
                  padding: 15,
                  borderRadius: 25,
                  background: "#ff0000",
                }}
                type="submit"
              >
                Submit $
                {(
                  (selectedOption == "Delivery"
                    ? payOption == null
                      ? parseFloat(count)
                      : payOption == "Cash"
                      ? parseFloat(count) + 4
                      : parseFloat(count) + 5
                    : parseFloat(count)) * 1.13
                ).toFixed(2)}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Form;
