import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import background from "./img/pizzabg.jpg";
import background2 from "./img/bg2.jpg";
import background3 from "./img/bg3.jpg";
import { useLocation } from "react-router-dom";
import lasagna from "./img/lasagna.png";
import pizza from "./img/pizza.png";
import coke from "./img/coke.png";
import fish from "./img/fishchip.png";
import salad from "./img/salad.png";
import sides from "./img/sides.png";
import slide2 from "./img/slide2.png";
import React, { useState, useEffect } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import { useSearchParams, createSearchParams, NavLink } from "react-router-dom";

function Pizza() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();
  const from = searchparams.get("from");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const sizes = ["Small", "Medium", "Large"];
  const [selectedSizes, setSelectedSizes] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");
  const properties = {
    duration: 2000,
    transitionDuration: 250,
    infinite: true,
    indicators: false,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
  };
  const handleButtonClick = (where) => {
    const targetClass = document.querySelector(where);
    setIsButtonClicked(true);
    targetClass.scrollIntoView({ behavior: "smooth" });
  };
  const [flavour, setFlavour] = useState([
    {
      name: "Canadian",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Pepperoni, 1 Bacon, 1 Mushroom, 1 extra cheese",
      description: "Pepperoni, Bacon, Mushroom and extra cheese",
    },
    {
      name: "Deluxe",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Pepperoni, 1 Bacon, 1 Ham, 1 Mushroom, 1 Onions, 1 Green peppers",
      description: "Pepperoni, Bacon, Ham, Mushroom, Onions and Green peppers",
    },
    {
      name: "Hawaiian",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Ham, 1 Pineapple, 1 Bacon , 1 extra cheese",
      description: "Ham, Pineapple, extra cheese and Bacon",
    },
    {
      name: "Meat Lovers",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Pepperoni, 1 Ham, 1 Bacon, 1 Italian sausage",
      description: "Pepperoni, Ham, Bacon and Italian sausage",
    },
    {
      name: "Big Bacon",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Bacon, 1 Bacon crumble, 1 Cheddar cheese",
      description: "Bacon, Bacon crumble and Cheddar cheese",
    },
    {
      name: "Cheeseburger",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Bacon, 1 Ground beef, 1 Cheddar cheese",
      description: "Bacon, Ground beef and Cheddar cheese",
    },
    {
      name: "Greek",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Tomatoes, 1 Black olives, 1 Feta, 1 Onions, 1 Oregano",
      description: "Tomatoes, Black olives, Feta, Onions and Oregano",
    },
    {
      name: "Cheese Lovers",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Parmesan, 1 Feta, 1 Cheddar cheese, 1 Mozzarella cheese",
      description: "Parmesan, Feta, Cheddar cheese and Mozzarella cheese",
    },
    {
      name: "Veggie",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 extra cheese, 1 Mushroom, 1 Onions, 1 Green peppers, 1 Green olives, 1 Tomatoes",
      description:
        "extra cheese, Mushroom, Onions, Green peppers, Green olives and Tomatoes",
    },
    {
      name: "Chicken burger",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Chicken, 1 extra cheese, 1 Ground beef, 1 Green peppers, 1 Onions",
      description:
        "Chicken, extra cheese, Ground beef, Green peppers and Onions",
    },
    {
      name: "Sicilian",
      price: 10.95,
      toggle: false,
      type: "flavour",
      tops: "1 Italian sausage, 1 Red onions, 1 Green peppers, 1 extra cheese",
      description: "Italian sausage, Red onions, Green peppers, extra cheese",
    },
    {
      name: "Chicken parmesan",
      tops: "1 Garlic spread, 1 Chicken, 1 Bacon crumble, 1 Parmesan",
      price: 10.95,
      toggle: false,
      type: "special",
      description: "Garlic spread, Chicken, Bacon crumble, Parmesan",
    },
    {
      name: "Chicken club",
      tops: "1 Chicken, 1 Green peppers, 1 Cheddar cheese, 1 Bacon",
      price: 10.95,
      toggle: false,
      type: "special",
      description: "Chicken, Green peppers, Cheddar cheese, Bacon",
    },
    {
      name: "Philly steak",
      tops: "1 Cheddar cheese sauce, 1 Philly steak, 1 Red onions, 1 Green peppers, 1 Parmesan",
      price: 10.95,
      toggle: false,
      type: "special",
      description:
        "Cheddar cheese sauce, Philly steak, Red onions, Green peppers, Parmesan",
    },
    {
      name: "Caesar",
      tops: "1 Caesar dressing, 1 Chicken, 1 Mushroom, 1 Onions, 1 Green peppers, 1 Bacon crumble",
      price: 10.95,
      toggle: false,
      type: "special",
      description:
        "Caesar dressing, Chicken, Mushroom, Onions, Green peppers, Bacon crumble",
    },
    {
      name: "Garlic",
      tops: "1 Garlic spread, 1 Chicken, 1 Bacon crumble, 1 Mushroom, 1 Onions, 1 Fresh garlic",
      price: 10.95,
      toggle: false,
      type: "special",
      description:
        "Garlic spread, Chicken, Bacon crumble, Mushroom, Onions, Fresh garlic",
    },
    {
      name: "BBQ Chicken",
      tops: "1 BBQ sauce, 1 Chicken, 1 Onions, 1 Green peppers, 1 Hot peppers, 1 Oregano",
      price: 10.95,
      toggle: false,
      type: "special",
      description:
        "BBQ sauce, Chicken, Onions, Green peppers, Hot peppers, Oregano",
    },
    {
      name: "Whiskey steak",
      tops: "1 BBQ whiskey sauce, 1 Bacon strips, 1 Chicken, 1 Steak",
      price: 10.95,
      toggle: false,
      type: "special",
      description: "BBQ whiskey sauce, Bacon strips, Chicken, Steak",
    },
    {
      name: "Olive",
      tops: "1 Green olives, 1 Black olives, 1 Bacon, 1 Onions, 1 Oregano, 1 Garlic spread",
      price: 10.95,
      toggle: false,
      type: "special",
      description:
        "Green olives, Black olives, Bacon, Onions, Oregano, Garlic spread",
    },
    {
      name: "Feta bruschetta",
      tops: "1 Feta, 1 Tomatoes, 1 Spinach, 1 Onions, 1 Oregano",
      price: 10.95,
      toggle: false,
      type: "special",
      description: "Feta, Tomatoes, Spinach, Onions, Oregano",
    },
    {
      name: "Chicken alfredo",
      description: "Alfredo sauce, Chicken, Mushroom, Onions, Bacon",
      price: 10.95,
      toggle: false,
      type: "special",
      tops: "1 Alfredo sauce, 1 Chicken, 1 Mushroom, 1 Onions, 1 Bacon",
    },
    {
      name: "Maria’s pizza",
      description: "Alfredo sauce, Chicken, Bacon, Cheddar cheese, Broccoli",
      price: 10.95,
      toggle: false,
      type: "special",
      tops: "1 Alfredo sauce, 1 Chicken, 1 Bacon, 1 Cheddar cheese, 1 Broccoli",
    },
    {
      name: "Pineapple curry",
      description: "Pineapple, Chicken, Ham, Onions, Green peppers",
      price: 10.95,
      toggle: false,
      type: "special",
      tops: "1 Pineapple, 1 Chicken, 1 Ham, 1 Onions, 1 Green peppers",
    },
    {
      name: "Pickle pizza",
      description: "Garlic butter, Pickle, Bacon, Pickle spice",
      price: 10.95,
      toggle: false,
      type: "special",
      tops: "1 Garlic butter, 1 Pickle, 1 Bacon, 1 Pickle spice",
    },
    {
      name: "Bacon ranch pizza",
      description: "Ranch base, Bacon, Bacon crumble, Chicken",
      price: 10.95,
      toggle: false,
      type: "special",
      tops: "1 Ranch base, 1 Bacon, 1 Bacon crumble, 1 Chicken",
    },
    {
      name: "Nacho pizza",
      description:
        "Cheddar cheese sauce, Red onions, Ground beef, Jalapeno, Chili flakes",
      price: 10.95,
      toggle: false,
      type: "special",
      tops: "1 Cheddar cheese sauce, 1 Red onions, 1 Ground beef, 1 Jalapeno, 1 Chili flakes",
    },
    {
      name: "Koka’s pizza",
      description:
        "Virgin oil base, Spinach, Fresh Oregano, Broccoli, Pepperoni",
      price: 10.95,
      toggle: false,
      type: "special",
      tops: "1 Virgin oil base, 1 Spinach, 1 Fresh Oregano, 1 Broccoli, 1 Pepperoni",
    },
  ]);
  const fadeImages = [
    {
      url: background,
      caption: "First Slide",
    },
    {
      url: background2,
      caption: "Second Slide",
    },
    {
      url: background3,
      caption: "Third Slide",
    },
  ];
  const handleSizeChange = (index) => (e) => {
    setSelectedSizes({ ...selectedSizes, [index]: e.target.value });
  };
  const [prices, setPrices] = useState([]);
  useEffect(() => {
    const updatedPrices = [];
    let pre;
    let count;
    flavour.map((mapp, index) => {
      pre = mapp.description.split(/,\s| and\s/);
      count = 0;
      pre.map((pree) => {
        console.log(selectedSizes[index]);
        if (
          [
            "Chicken",
            "Philly steak",
            "Steak",
            "Gyromeat",
            "Mozzarella cheese",
            "Feta",
          ].includes(pree)
        ) {
          count = count + 2;
        } else {
          count = count + 1;
          console.log(count);
        }
      });
      if (count > 4) {
        count =
          4 +
          (count - 4) *
            (selectedSizes[index] == "Medium"
              ? 1.5
              : selectedSizes[index] == "Large"
              ? 2
              : 1) +
          mapp.price +
          (selectedSizes[index] == "Medium"
            ? 2
            : selectedSizes[index] == "Large"
            ? 3
            : 0);
      } else {
        count =
          count +
          mapp.price +
          (selectedSizes[index] == "Medium"
            ? 2
            : selectedSizes[index] == "Large"
            ? 3
            : 0);
      }
      console.log(count);
      updatedPrices.push(count.toFixed(2));
    });
    setPrices(updatedPrices);
  }, [selectedSizes]);
  const handleSubmit = (index) => (e) => {
    var items = "";
    if (selectedSizes[index]) {
      items =
        flavour[index].name +
        "," +
        selectedSizes[index] +
        "," +
        flavour[index].tops +
        "," +
        parseFloat(1) +
        "," +
        prices[index];
      itemarr = [...itemarr, items.split(",")];
      localStorage.setItem("array", JSON.stringify(itemarr));
    } else {
      e.preventDefault();
      alert(`Please select a size for your ${flavour[index].name} pizza.`);
    }
  };
  return (
    <div className="slide-container">
      <Fade {...properties}>
        {fadeImages.map((fadeImage, index) => (
          <div className="each-fade" key={index}>
            <div
              style={{
                position: "absolute",
                width: "100vw",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "flex-start",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
                  <img
                    src={logo}
                    style={{
                      width: width > "450" ? "30vw" : "40vw",
                      alignSelf: "flex-start",
                      margin: 20,
                    }}
                  />
                </NavLink>
                <CartIcon color={"white"} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontSize: "8vw",
                    fontFamily: "MyFont",
                    textAlign: "center",
                    justifySelf: "flex-start",
                    marginTop: 0,
                  }}
                >
                  choose any pizza
                </h1>
                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      width: "auto",
                      maxWidth: 200,
                      height: "auto",
                      padding: 15,
                      borderRadius: 25,
                      background: "#ff0000",
                    }}
                    onClick={() => handleButtonClick(".special")}
                  >
                    <span
                      style={{
                        color: "white",
                        fontFamily: "MyFont",
                        fontSize: width < "450" ? 20 : 30,
                      }}
                    >
                      Speciality
                    </span>
                  </button>
                  <button
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      width: "auto",
                      maxWidth: 200,
                      height: "auto",
                      padding: 15,
                      borderRadius: 25,
                      background: "#ff0000",
                    }}
                    onClick={() => handleButtonClick(".flavour")}
                  >
                    <span
                      style={{
                        color: "white",
                        fontFamily: "MyFont",
                        fontSize: width < "450" ? 20 : 30,
                      }}
                    >
                      Flavours
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="image-container"
              style={{ overflow: "hidden", maxHeight: "100vh" }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  background: "black",
                  position: "absolute",
                  zIndex: 1,
                  opacity: 0.5,
                }}
              ></div>
              <img
                src={fadeImage.url}
                style={{
                  width: "100vw",
                  filter: "blur(3px)",
                }}
              />
            </div>
          </div>
        ))}
      </Fade>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="flavour"
            style={{
              width: "100vw",
              maxWidth: "800px",
            }}
          >
            <h1
              style={{
                color: "#ff0000",
                fontSize: width < "468" ? "8vw" : "5vw",
                fontFamily: "MyFont",
                textAlign: "center",
              }}
            >
              Flavoured
            </h1>
            {flavour.map((flav, index) => {
              if (flav.type == "flavour") {
                return (
                  <div
                    style={{
                      margin: 20,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      onClick={() => {
                        const newFlavour = [...flavour];
                        const index = newFlavour.indexOf(flav);
                        newFlavour[index].toggle = !newFlavour[index].toggle;
                        setFlavour(newFlavour);
                        console.log(flav.toggle);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <h1
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "3vw",
                          color: "#ff0000",
                        }}
                      >
                        {flav.name}
                      </h1>
                      {from != "pwo" && (
                        <h1
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "3vw",
                            color: "#ff0000",
                          }}
                        >
                          $ {prices[index]}
                          {/* {!selectedSizes[index]
                          ? flav.price.toFixed(2)
                          : selectedSizes[index] == "Small"
                          ? flav.price.toFixed(2)
                          : selectedSizes[index] == "Medium"
                          ? parseFloat(flav.price.toFixed(2)) + 2
                          : parseFloat(flav.price.toFixed(2)) + 3} */}
                        </h1>
                      )}
                    </div>

                    <p
                      style={{
                        fontFamily: "MyFont",
                        margin: 0,
                        fontSize: width < "468" ? "4vw" : "2vw",
                        color: "black",
                      }}
                    >
                      {flav.description}
                    </p>
                    {flav.toggle && (
                      <div
                        style={{
                          alignSelf: "flex-end",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <select
                          value={selectedSizes[index] || ""}
                          onChange={handleSizeChange(index)}
                          style={{
                            fontSize: width < "468" ? "4vw" : "2vw",
                            fontFamily: "MyFont",
                          }}
                        >
                          <option value="" disabled>
                            Select Size
                          </option>
                          {sizes.map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                        <NavLink
                          to={{
                            pathname: "/toppings",
                            search: createSearchParams({
                              Name: flav.name,
                              price: flav.price,
                              disc: flav.description,
                            }).toString(),
                          }}
                          style={{
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        >
                          <button
                            style={{
                              marginLeft: 10,
                              marginRight: 5,
                              width: "auto",
                              maxWidth: 200,
                              height: "auto",
                              padding: 10,
                              borderRadius: 25,
                              border: "1px solid #ff0000",
                              background: "white",
                            }}
                          >
                            <span
                              style={{
                                color: "#ff0000",
                                fontFamily: "MyFont",
                                fontSize: width < "450" ? "5vw" : "1.5vw",
                              }}
                            >
                              customize
                            </span>
                          </button>
                        </NavLink>
                        <NavLink
                          to={"/cart"}
                          onClick={handleSubmit(index)}
                          style={{
                            marginLeft: 5,
                            marginRight: 10,
                          }}
                        >
                          <button
                            style={{
                              marginLeft: 5,
                              marginRight: 10,
                              width: "auto",
                              maxWidth: 200,
                              height: "auto",
                              padding: 10,
                              borderRadius: 25,
                              background: "#ff0000",
                            }}
                          >
                            <span
                              style={{
                                color: "white",
                                fontFamily: "MyFont",
                                fontSize: width < "450" ? "5vw" : "1.5vw",
                              }}
                            >
                              add to cart
                            </span>
                          </button>
                        </NavLink>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
          <div
            className="special"
            style={{
              width: "100vw",
              maxWidth: "800px",
            }}
          >
            <h1
              style={{
                color: "#ff0000",
                fontSize: width < "468" ? "8vw" : "5vw",
                fontFamily: "MyFont",
                textAlign: "center",
                marginTop: 50,
              }}
            >
              Speciality
            </h1>
            {flavour.map((spec, index) => {
              if (spec.type == "special") {
                return (
                  <div
                    style={{
                      margin: 20,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      onClick={() => {
                        const newFlavour = [...flavour];
                        const index = newFlavour.indexOf(spec);
                        newFlavour[index].toggle = !newFlavour[index].toggle;
                        setFlavour(newFlavour);
                        console.log(spec.toggle);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textDecoration: "none",
                      }}
                    >
                      <h1
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "3vw",
                          color: "#ff0000",
                        }}
                      >
                        {spec.name}
                      </h1>
                      <h1
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "3vw",
                          color: "#ff0000",
                        }}
                      >
                        ${prices[index]}
                        {/* {!selectedSizes[index]
                          ? spec.price.toFixed(2)
                          : selectedSizes[index] == "Small"
                          ? spec.price.toFixed(2)
                          : selectedSizes[index] == "Medium"
                          ? parseFloat(spec.price.toFixed(2)) + 2
                          : parseFloat(spec.price.toFixed(2)) + 3} */}
                      </h1>
                    </div>

                    <p
                      style={{
                        fontFamily: "MyFont",
                        margin: 0,
                        fontSize: width < "468" ? "4vw" : "2vw",
                        color: "black",
                      }}
                    >
                      {spec.description}
                    </p>
                    {spec.toggle && (
                      <div
                        style={{
                          alignSelf: "flex-end",
                          display: "flex",
                          marginTop: 20,
                        }}
                      >
                        <select
                          value={selectedSizes[index] || ""}
                          onChange={handleSizeChange(index)}
                          style={{
                            fontSize: width < "468" ? "4vw" : "2vw",
                            fontFamily: "MyFont",
                          }}
                        >
                          <option value="" disabled>
                            Select Size
                          </option>
                          {sizes.map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>

                        <NavLink
                          to={{
                            pathname: "/toppings",
                            search: createSearchParams({
                              Name: spec.name,
                              price: spec.price,
                              disc: spec.description,
                            }).toString(),
                          }}
                          style={{
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        >
                          <button
                            style={{
                              marginLeft: 10,
                              marginRight: 5,
                              width: "auto",
                              maxWidth: 200,
                              height: "auto",
                              padding: 10,
                              borderRadius: 25,
                              border: "1px solid #ff0000",
                              background: "white",
                            }}
                          >
                            <span
                              style={{
                                color: "#ff0000",
                                fontFamily: "MyFont",
                                fontSize: width < "450" ? "5vw" : "1.5vw",
                              }}
                            >
                              customize
                            </span>
                          </button>
                        </NavLink>
                        <NavLink
                          to={"/cart"}
                          onClick={handleSubmit(index)}
                          style={{
                            marginLeft: 5,
                            marginRight: 10,
                          }}
                        >
                          <button
                            style={{
                              marginLeft: 5,
                              marginRight: 10,
                              width: "auto",
                              maxWidth: 200,
                              height: "auto",
                              padding: 10,
                              borderRadius: 25,
                              background: "#ff0000",
                            }}
                          >
                            <span
                              style={{
                                color: "white",
                                fontFamily: "MyFont",
                                fontSize: width < "450" ? "5vw" : "1.5vw",
                              }}
                            >
                              add to cart
                            </span>
                          </button>
                        </NavLink>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pizza;
