import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import plu from "./img/plus.png";
import min from "./img/minus.png";
import React, { useState, useEffect } from "react";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import "./topping.css";
import { useLocation } from "react-router-dom";
import { useSearchParams, useNavigate, NavLink } from "react-router-dom";

function Garlicfingers() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();
  const pizzaname = searchparams.get("Name");
  const pizzaprice = searchparams.get("price");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [toggletopping, setTogggletopping] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedforn, setSelectedforn] = useState(null);
  const [selectedCrust, setSelectedCrust] = useState(null);
  const [priceS, setPriceS] = useState(1);
  const [instructions, setInstructions] = useState("");
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");

  const handleInstructionsChange = (event) => {
    setInstructions(event.target.value);
  };
  const toppingcounts = () => {
    let toppingcount = 0;
    getSelectedToppings().map((toppingg) => {
      toppingcount = toppingcount + toppingg.multi * toppingg.quantity;
    });
    console.log(toppingcount);
    return true;
  };
  const pricecount = () => {
    let toppingcount = 0;
    let toppingprice = 0;
    let dipcount = 0;
    getSelectedToppings().map((toppingg) => {
      toppingcount = toppingcount + toppingg.multi * toppingg.quantity;
    });
    toppingprice = toppingcount * 2;
    return toppingprice;
  };

  const size = [
    { name: "Small", price: "+$0.00" },
    { name: "Medium", price: "+$2.00" },
    { name: "Large", price: "+$4.00" },
  ];
  const forn = [
    {
      name: "Fries",
    },
    { name: "Nachos" },
  ];
  useEffect(() => {
    if (
      (selectedOption != "Small" && selectedCrust == "Cauliflower") ||
      (selectedOption != "Medium" && selectedCrust == "Gluten-free")
    ) {
      setSelectedCrust(null);
    }
  }, [selectedOption]);

  const Toppin = [
    { name: "Pepperoni", multi: 1, quantity: 1, type: "meat", side: "whole" },
    {
      name: " Shredded Pepperoni",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: " Cup-Char Pepperoni",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    { name: "Ham", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Bacon", multi: 1, quantity: 1, type: "meat", side: "whole" },
    {
      name: "Bacon crumble",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: "Bacon strips",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: "New York pepperoni",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: "Bacon sliced",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: "Italian sausage",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    { name: "Ground beef", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Salami", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Chicken", multi: 1, quantity: 1, type: "meat", side: "whole" },
    {
      name: "Philly steak",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    { name: "Steak", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Gyromeat", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Anchovies", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Mushroom", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Garlic", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Pineapple", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Tomatoes", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Onions", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Red onions", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Black olives", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Green olives", multi: 1, quantity: 1, type: "veg", side: "whole" },
    {
      name: "Green peppers",
      multi: 1,
      quantity: 1,
      type: "veg",
      side: "whole",
    },
    { name: "Red peppers", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Hot peppers", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Jalapeno", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Spinach", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Broccoli", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Pickle", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Cheddar cheese", multi: 1, quantity: 1, type: "cheese" },
    { name: "Parmesan", multi: 1, quantity: 1, type: "cheese" },
    { name: "extra cheese", multi: 1, quantity: 1, type: "cheese" },
    { name: "Mozzarella cheese", multi: 1, quantity: 1, type: "cheese" },
    { name: "Feta", multi: 1, quantity: 1, type: "cheese" },
  ];

  const [toppings, setToppings] = useState(Toppin);
  const handleChange = (e) => {
    const { name, checked } = e.target;
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          return {
            ...topping,
            selected: checked,
            quantity: checked ? topping.quantity : 1,
          };
        }
        return topping;
      })
    );
  };
  const submit = (event) => {
    var items = "";

    if (selectedOption == null) {
      event.preventDefault();
      alert("Size is required options please select the size.");
    } else {
      console.log(getSelectedToppings());
      console.log(selectedCrust);
      console.log(selectedOption);
      console.log(instructions);
      items =
        pizzaname +
        "," +
        selectedOption +
        "," +
        toppings
          .filter((topping) => topping.selected)
          .map((t) => t.quantity + " " + t.name)
          .join(",") +
        "," +
        (instructions!=""?"Instructions: " +instructions:"" 
        )+
        "," +
        parseFloat(1) +
        "," +
        parseFloat(pricecount() + sizeadd() + parseFloat(pizzaprice)).toFixed(
          2
        );
      itemarr = [...itemarr, items.split(",")];
      localStorage.setItem("array", JSON.stringify(itemarr));
    }
  };

  const handleQuantityChange = (name, operation) => {
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          let newQuantity =
            operation === "add" ? topping.quantity + 1 : topping.quantity - 1;
          if (newQuantity < 1) newQuantity = 1;
          return { ...topping, quantity: newQuantity };
        }
        return topping;
      })
    );
  };

  const getSelectedToppings = () => {
    return toppings.filter((topping) => topping.selected);
  };

  const sizeadd = () => {
    if (selectedCrust == "Gluten-free" || selectedCrust == "Cauliflower") {
      if (selectedOption == "Large") {
        return 8.55;
      } else if (selectedOption == "Medium") {
        return 6.55;
      } else {
        return 5.0;
      }
    } else {
      if (selectedOption == "Large") {
        return 4;
      } else if (selectedOption == "Medium") {
        return 2;
      } else {
        return 0.0;
      }
    }
  };
  return (
    <div>
      <div>
        <div
          style={{
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
            <img
              src={logo}
              style={{
                width: width > "450" ? "30vw" : "40vw",
                alignSelf: "flex-start",
                margin: 20,
              }}
            />
          </NavLink>
          <CartIcon color={"#ff0000"} />
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <h1
          style={{
            color: "#ff0000",
            fontSize: width < "468" ? "8vw" : "5vw",
            fontFamily: "MyFont",
            marginTop: 0,
            textAlign: "center",
          }}
        >
          {pizzaname}
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "800px",

              width: "90vw",
            }}
          >
            <>
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Size
              </p>
              <div className="size">
                {size.map((siz) => {
                  return (
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        id={siz.name}
                        name="size"
                        value={siz.name}
                        checked={selectedOption === siz.name}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      <label
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                        htmlFor={siz.name}
                      >
                        {siz.name}
                      </label>
                      <p
                        style={{
                          fontFamily: "MyFont",
                          color: "#ff0000",
                          fontSize: width < "468" ? "6vw" : "2vw",
                          marginLeft: "auto",
                          marginTop: 0,
                          marginBottom: 10,
                        }}
                      >
                        {siz.price}
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setTogggletopping(!toggletopping)}
            >
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Additional toppings
              </p>
              {toggletopping ? (
                <VscTriangleUp
                  style={{
                    fontSize: width < "468" ? "8vw" : "3vw",
                    color: "#ff0000",
                    margin: 10,
                  }}
                />
              ) : (
                <VscTriangleDown
                  style={{
                    fontSize: width < "468" ? "8vw" : "3vw",
                    color: "#ff0000",
                    margin: 10,
                  }}
                />
              )}
            </div>
            {toggletopping && (
              <>
                <div className="cheese">
                  <p
                    style={{
                      fontFamily: "MyFont",
                      fontSize: width < "468" ? "8vw" : "3vw",
                      color: "#ff0000",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    Cheese
                  </p>
                  {toppings.map(
                    (topping) =>
                      topping.type == "cheese" && (
                        <div
                          key={topping.name}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            name={topping.name}
                            onChange={handleChange}
                            checked={topping.selected}
                          />
                          <label
                            style={{
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                            }}
                          >
                            {topping.name}
                          </label>
                          {topping.selected ? (
                            <div
                              style={{
                                marginLeft: "auto",
                                display: "flex",
                                alignItems: "center",
                                marginTop: 0,
                                marginBottom: 10,
                              }}
                            >
                              <button
                                style={{ border: "0px", background: "#ffffff" }}
                                onClick={() =>
                                  handleQuantityChange(topping.name, "minus")
                                }
                              >
                                <img
                                  src={min}
                                  style={{
                                    width: width < "468" ? "3vw" : "1vw",
                                    marginTop: 8,
                                  }}
                                />
                              </button>
                              <span
                                style={{
                                  margin: "0 10px",
                                  fontFamily: "MyFont",
                                  fontSize: width < "468" ? "6vw" : "2vw",
                                }}
                              >
                                {topping.quantity}
                              </span>
                              <button
                                style={{
                                  border: "0px",
                                  background: "#ffffff",
                                }}
                                onClick={() =>
                                  handleQuantityChange(topping.name, "add")
                                }
                              >
                                <img
                                  src={plu}
                                  style={{
                                    width: width < "468" ? "4vw" : "1.5vw",
                                    marginTop: 8,
                                  }}
                                />
                              </button>
                            </div>
                          ) : (
                            <div
                              style={{
                                marginLeft: "auto",
                                display: "flex",
                                alignItems: "center",
                                marginTop: 0,
                                marginBottom: 10,
                              }}
                            >
                              <p
                                style={{
                                  color: "#ff0000",
                                  fontFamily: "MyFont",
                                  fontSize: width < "468" ? "6vw" : "2vw",
                                  margin: 0,
                                  justifyContent: "flex-end",
                                }}
                              >
                                +$
                                {(!toppingcounts()
                                  ? 0
                                  : topping.multi * 2
                                ).toFixed(2)}
                              </p>
                            </div>
                          )}
                        </div>
                      )
                  )}
                </div>

                <div className="meat">
                  <p
                    style={{
                      fontFamily: "MyFont",
                      fontSize: width < "468" ? "8vw" : "3vw",
                      color: "#ff0000",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    Meat
                  </p>
                  {toppings.map(
                    (topping, index) =>
                      topping.type == "meat" && (
                        <div>
                          <div
                            key={topping.name}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              name={topping.name}
                              onChange={handleChange}
                              checked={topping.selected}
                            />
                            <label
                              style={{
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "2vw",
                              }}
                            >
                              {topping.name}
                            </label>
                            {topping.selected ? (
                              <div
                                style={{
                                  marginLeft: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 0,
                                  marginBottom: 10,
                                }}
                              >
                                <button
                                  style={{
                                    border: "0px",
                                    background: "#ffffff",
                                  }}
                                  onClick={() =>
                                    handleQuantityChange(topping.name, "minus")
                                  }
                                >
                                  <img
                                    src={min}
                                    style={{
                                      width: width < "468" ? "3vw" : "1vw",
                                      marginTop: 8,
                                    }}
                                  />
                                </button>
                                <span
                                  style={{
                                    margin: "0 10px",
                                    fontFamily: "MyFont",
                                    fontSize: width < "468" ? "6vw" : "2vw",
                                  }}
                                >
                                  {topping.quantity}
                                </span>
                                <button
                                  style={{
                                    border: "0px",
                                    background: "#ffffff",
                                  }}
                                  onClick={() =>
                                    handleQuantityChange(topping.name, "add")
                                  }
                                >
                                  <img
                                    src={plu}
                                    style={{
                                      width: width < "468" ? "4vw" : "1.5vw",
                                      marginTop: 8,
                                    }}
                                  />
                                </button>
                              </div>
                            ) : (
                              <div
                                style={{
                                  marginLeft: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 0,
                                  marginBottom: 10,
                                }}
                              >
                                <p
                                  style={{
                                    color: "#ff0000",
                                    fontFamily: "MyFont",
                                    fontSize: width < "468" ? "6vw" : "2vw",
                                    margin: 0,
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  +$
                                  {(!toppingcounts()
                                    ? 0
                                    : topping.multi * 2
                                  ).toFixed(2)}
                                </p>
                              </div>
                            )}
                          </div>
                          {/* {topping.selected && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <img
                              src={pizzaleft}
                              style={{
                                height: 25,
                                opacity: topping.side == "left" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "left")}
                            />
                            <img
                              src={pizzawhole}
                              style={{
                                height: 25,
                                margin: "0 15px",
                                opacity: topping.side == "whole" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "whole")}
                            />
                            <img
                              src={pizzaright}
                              style={{
                                height: 25,
                                opacity: topping.side == "right" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "right")}
                            />
                          </div>
                        </div>
                      )} */}
                        </div>
                      )
                  )}
                </div>
                <div className="veg">
                  <p
                    style={{
                      fontFamily: "MyFont",
                      fontSize: width < "468" ? "8vw" : "3vw",
                      color: "#ff0000",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    Veggies
                  </p>
                  {toppings.map(
                    (topping, index) =>
                      topping.type == "veg" && (
                        <div>
                          <div
                            key={topping.name}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              name={topping.name}
                              onChange={handleChange}
                              checked={topping.selected}
                            />
                            <label
                              style={{
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "2vw",
                              }}
                            >
                              {topping.name}
                            </label>
                            {topping.selected ? (
                              <div
                                style={{
                                  marginLeft: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 0,
                                  marginBottom: 10,
                                }}
                              >
                                <button
                                  style={{
                                    border: "0px",
                                    background: "#ffffff",
                                  }}
                                  onClick={() =>
                                    handleQuantityChange(topping.name, "minus")
                                  }
                                >
                                  <img
                                    src={min}
                                    style={{
                                      width: width < "468" ? "3vw" : "1vw",
                                      marginTop: 8,
                                    }}
                                  />
                                </button>
                                <span
                                  style={{
                                    margin: "0 10px",
                                    fontFamily: "MyFont",
                                    fontSize: width < "468" ? "6vw" : "2vw",
                                  }}
                                >
                                  {topping.quantity}
                                </span>
                                <button
                                  style={{
                                    border: "0px",
                                    background: "#ffffff",
                                  }}
                                  onClick={() =>
                                    handleQuantityChange(topping.name, "add")
                                  }
                                >
                                  <img
                                    src={plu}
                                    style={{
                                      width: width < "468" ? "4vw" : "1.5vw",
                                      marginTop: 8,
                                    }}
                                  />
                                </button>
                              </div>
                            ) : (
                              <div
                                style={{
                                  marginLeft: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 0,
                                  marginBottom: 10,
                                }}
                              >
                                <p
                                  style={{
                                    color: "#ff0000",
                                    fontFamily: "MyFont",
                                    fontSize: width < "468" ? "6vw" : "2vw",
                                    margin: 0,
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  +$
                                  {(!toppingcounts()
                                    ? 0
                                    : topping.multi * 2
                                  ).toFixed(2)}
                                </p>
                              </div>
                            )}
                          </div>{" "}
                          {/* {topping.selected && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <img
                              src={pizzaleft}
                              style={{
                                height: 25,
                                opacity: topping.side == "left" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "left")}
                            />
                            <img
                              src={pizzawhole}
                              style={{
                                height: 25,
                                margin: "0 15px",
                                opacity: topping.side == "whole" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "whole")}
                            />
                            <img
                              src={pizzaright}
                              style={{
                                height: 25,
                                opacity: topping.side == "right" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "right")}
                            />
                          </div>
                        </div>
                      )}{" "} */}
                        </div>
                      )
                  )}
                </div>
              </>
            )}
            {/* <div
              style={{
                maxWidth: "800px",

                width: "90vw",
              }}
            >
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Crust
              </p>
              <div>
                {crusts.map((crust, index) => {
                  if (crust.size === "All" || crust.size === selectedOption)
                    return (
                      <div className="radio-button-container-crust" key={index}>
                        <input
                          type="radio"
                          name="crust"
                          value={crust.name}
                          checked={selectedCrust === crust.name}
                          onChange={() => setSelectedCrust(crust.name)}
                        />
                        <label
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                          onClick={() => setSelectedCrust(crust.name)}
                        >
                          {crust.name}
                        </label>
                        <p
                          style={{
                            fontFamily: "MyFont",
                            color: "#ff0000",
                            fontSize: width < "468" ? "6vw" : "2vw",
                            marginLeft: "auto",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          +${crust.price.toFixed(2)}
                        </p>
                      </div>
                    );
                })}
              </div>
            </div> */}
            <div className="instructions" style={{ marginBottom: "100px" }}>
              <label>
                <p
                  style={{
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "8vw" : "3vw",
                    color: "#ff0000",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  Special Instructions
                </p>

                <textarea
                  className="resizable"
                  value={instructions}
                  onChange={handleInstructionsChange}
                  style={{
                    width: "93%",
                    maxWidth: "800px",
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "6vw" : "2vw",
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <NavLink to={"/cart"} onClick={submit}>
          <button
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              position: "fixed",
              bottom: "10px",
              height: "auto",
              padding: 15,
              borderRadius: 25,
              background: "#ff0000",
            }}
          >
            <span
              style={{
                color: "white",
                fontFamily: "MyFont",
                fontSize: width < "450" ? 20 : 30,
              }}
            >
              Submit $
              {parseFloat(
                pricecount() + sizeadd() + parseFloat(pizzaprice)
              ).toFixed(2)}
            </span>
          </button>
        </NavLink>
      </div>
    </div>
  );
}

export default Garlicfingers;
