import React, { useState,useRef } from "react";
import "./test.css";
import emailjs from '@emailjs/browser';
export default function App() {
  const [isBox1OnScreen, setIsBox1OnScreen] = useState(true);
  const form = useRef();
  const handleButtonClick = () => {
    setIsBox1OnScreen(!isBox1OnScreen);
  };

  
  const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_h8ns29k', 'template_tlmscmo', form.current, 'PS-3HQmZAiUE38W5Y')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

  return (
    <div>
      <form ref={form} onSubmit={sendEmail}>
      
      <input style={{width:0,height:0, visibility:"hidden"}} type="text" name="from_name" value="Anmol" />
      
      <input type="email" name="send_to" style={{width:0,height:0, visibility:"hidden"}}  value="anmolriar8070@gmail.com"/>
      
      <input type="text" name="message" style={{width:0,height:0, visibility:"hidden"}} value="Your pizza is ready"/>
      <input type="submit" value="Send" />
    </form>
    </div>
  );
}
