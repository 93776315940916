import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { createSearchParams, NavLink } from "react-router-dom";
function CartIcon(props) {
  const [storedData, setStoredData] = useState(
    JSON.parse(localStorage.getItem("array") || "[]")
  );
  const [width, setWidth] = useState(window.innerWidth);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        height: width < "468" ? "10vw" : "6vw",
        width: "auto",
      }}
    >
      <NavLink to={"/cart"} style={{ textDecoration: "none" }}>
        <FontAwesomeIcon
          icon={faShoppingCart}
          style={{
            color: props.color,
            fontFamily: "MyFont",
            fontSize: width < "468" ? "6vw" : "2vw",
          }}
        />
        {storedData.length > 0 && (
          <span
            className="badge"
            style={{
              color: props.color,
              fontFamily: "MyFont",
              margin: 10,
              fontSize: width < "468" ? "8vw" : "2vw",
            }}
          >
            {storedData.length}
          </span>
        )}
      </NavLink>
    </div>
  );
}

export default CartIcon;
