import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import plu from "./img/plus.png";
import min from "./img/minus.png";
import React, { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import "./topping.css";
import pizzawhole from "./img/pizzawhole.png";
import pizzaleft from "./img/pizzaleft.png";
import pizzaright from "./img/pizzaright.png";
import { useLocation } from "react-router-dom";
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
  NavLink,
} from "react-router-dom";

function Partytopps() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();
  const pizzaname = searchparams.get("Name");
  const pizzaprice = searchparams.get("price");
  const [pizza1, setPizza1] = useState(
    JSON.parse(localStorage.getItem("pizza1") || "[]")
  );
  const [pizza2, setPizza2] = useState(
    JSON.parse(localStorage.getItem("pizza2") || "[]")
  );
  const [pizza3, setPizza3] = useState(
    JSON.parse(localStorage.getItem("pizza3") || "[]")
  );
  const [pizza4, setPizza4] = useState(
    JSON.parse(localStorage.getItem("pizza4") || "[]")
  );

  var pre = [];
  useEffect(() => {
    console.log(pizzaname);

    setSelectedOption("Large");
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [width, setWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSauce, setSelectedSauce] = useState("Pizza sauce");
  const [selectedCrust, setSelectedCrust] = useState(null);
  const [priceS, setPriceS] = useState(1);
  const [instructions, setInstructions] = useState("");

  const handleInstructionsChange = (event) => {
    setInstructions(event.target.value);
  };
  const crusts = [
    { name: "Regular", size: "All", price: 0 },
    { name: "Thick", size: "All", price: 0 },
    { name: "Crispy", size: "All", price: 0 },
    { name: "Thin", size: "All", price: 0 },
    { name: "Gluten-free", size: "Medium", price: 5 },
    { name: "Cauliflower", size: "Small", price: 5 },
  ];

  const size = [
    { name: "Small", price: "+$0.00" },
    {
      name: "Medium",
      price:
        pizzaname ==
        "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
          ? "+$3.00"
          : "+$4.00",
    },
    {
      name: "Large",
      price:
        pizzaname ==
        "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
          ? "+$6.00"
          : "+$8.00",
    },
  ];
  const toppingcounts = () => {
    let toppingcount = 0;
    getSelectedToppings().map((toppingg) => {
      toppingcount = toppingcount + toppingg.multi * toppingg.quantity;
    });
    toppingcount =
      toppingcount + unedit.length + (selectedSauce != "Pizza sauce" && 1);
    console.log(toppingcount);
    if (toppingcount >= 4) {
      return true;
    } else {
      return false;
    }
  };
  const pricecount = () => {
    let toppingcount = 0;
    let toppingprice = 0;
    let dipcount = 0;
    getSelectedToppings().map((toppingg) => {
      toppingcount =
        toppingcount + toppingg.multi * toppingg.quantity + unedit.length;
    });
    toppingcount =
      toppingcount + unedit.length + (selectedSauce != "Pizza sauce" && 1);
    if (toppingcount > 4) {
      toppingprice =
        (toppingcount - 4) *
          (selectedOption == "Medium"
            ? 1.5
            : selectedOption == "Large"
            ? 2
            : 1) +
        dipcount;
    } else {
      toppingprice = 0 + dipcount;
    }
    return toppingprice;
  };
  useEffect(() => {
    if (selectedOption == "Large") {
      setPriceS(2);
    } else if (selectedOption == "Medium") {
      setPriceS(1.5);
    } else {
      setPriceS(1);
    }
  }, [selectedOption]);
  useEffect(() => {
    if (
      (selectedOption != "Small" && selectedCrust == "Cauliflower") ||
      (selectedOption != "Medium" && selectedCrust == "Gluten-free")
    ) {
      setSelectedCrust(null);
    }
  }, [selectedOption]);

  const Toppin = [
    { name: "Pepperoni", multi: 1, quantity: 1, type: "meat", side: "whole" },
    {
      name: " Shredded Pepperoni",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: " Cup-Char Pepperoni",
      multi: 2,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    { name: "Ham", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Bacon", multi: 1, quantity: 1, type: "meat", side: "whole" },
    {
      name: "Bacon crumble",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: "Bacon strips",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: "New York pepperoni",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: "Bacon sliced",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    {
      name: "Italian sausage",
      multi: 1,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    { name: "Ground beef", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Salami", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Chicken", multi: 2, quantity: 1, type: "meat", side: "whole" },
    {
      name: "Philly steak",
      multi: 2,
      quantity: 1,
      type: "meat",
      side: "whole",
    },
    { name: "Steak", multi: 2, quantity: 1, type: "meat", side: "whole" },
    { name: "Gyromeat", multi: 2, quantity: 1, type: "meat", side: "whole" },
    { name: "Anchovies", multi: 1, quantity: 1, type: "meat", side: "whole" },
    { name: "Mushroom", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Garlic", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Pineapple", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Tomatoes", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Onions", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Red onions", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Black olives", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Green olives", multi: 1, quantity: 1, type: "veg", side: "whole" },
    {
      name: "Green peppers",
      multi: 1,
      quantity: 1,
      type: "veg",
      side: "whole",
    },
    { name: "Red peppers", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Hot peppers", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Jalapeno", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Spinach", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Broccoli", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Pickle", multi: 1, quantity: 1, type: "veg", side: "whole" },
    { name: "Cheddar cheese", multi: 1, quantity: 1, type: "cheese" },
    { name: "Parmesan", multi: 1, quantity: 1, type: "cheese" },
    { name: "extra cheese", multi: 1, quantity: 1, type: "cheese" },
    { name: "Mozzarella cheese", multi: 2, quantity: 1, type: "cheese" },
    { name: "Feta", multi: 2, quantity: 1, type: "cheese" },
  ];
  const sauce = [
    { name: "Pizza sauce", multi: 0, quantity: 1, type: "sauce" },
    { name: "BBQ sauce", multi: 1, quantity: 1, type: "sauce" },
    { name: "Cheddar cheese sauce", multi: 1, quantity: 1, type: "sauce" },
    { name: "Garlic spread", multi: 1, quantity: 1, type: "sauce" },
    { name: "Alfredo sauce", multi: 1, quantity: 1, type: "sauce" },
    { name: "BBQ whiskey sauce", multi: 1, quantity: 1, type: "sauce" },
    { name: "Garlic butter", multi: 1, quantity: 1, type: "sauce" },
    { name: "Virgin oil base", multi: 1, quantity: 1, type: "sauce" },
  ];
  const [toppings, setToppings] = useState(Toppin);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          return {
            ...topping,
            selected: checked,
            quantity: checked ? topping.quantity : 1,
          };
        }

        return topping;
      })
    );
  };
  const handleside = (indexx, sides) => {
    const { name } = toppings[indexx];
    console.log(name, sides);
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          return {
            ...topping,
            side: sides,
          };
        }

        return topping;
      })
    );
  };

  const [unedit, setUnedit] = useState([]);
  useEffect(() => {
    console.log(pre);
    pre.map((ma) => {
      setToppings((prevToppings) =>
        prevToppings.map((topping) => {
          if (topping.name === ma) {
            return {
              ...topping,
              selected: true,
              quantity: true ? topping.quantity : 1,
            };
          }
          return topping;
        })
      );
      if (Toppin.filter((item) => !item.name.includes(ma)).length > 36) {
        sauce.map((mapp) => {
          console.log(mapp.name, ma);
          if (mapp.name === ma) {
            console.log("This was same");
            setSelectedSauce(mapp.name);
          }
        });
        if (sauce.filter((items) => !items.name.includes(ma)).length > 7) {
          setUnedit((unedits) => [...unedits, ma]);
        }
      }
      console.log(unedit);
    });
  }, []);

  const submit = (event) => {
    var items = "";

    if (selectedOption == null || selectedCrust == null) {
      event.preventDefault();
      alert(
        "Size and Crust are required options please select one of the options."
      );
    } else {
      console.log(getSelectedToppings());
      console.log(selectedCrust);
      console.log(selectedOption);
      console.log(instructions);
      items =
        "Pizza" +
        "," +
        selectedOption +
        "," +
        selectedCrust +
        "," +
        selectedSauce +
        "," +
        unedit.join(",") +
        "," +
        toppings
          .filter((topping) => topping.selected)
          .map(
            (t) =>
              t.quantity +
              " " +
              t.name +
              (t.type == "meat"
                ? t.side != "whole"
                  ? " - " + t.side
                  : ""
                : "") +
              (t.type == "veg" ? (t.side != "whole" ? " - " + t.side : "") : "")
          )
          .join(",") +
        "," +
        (instructions!=""?"Instructions: " +instructions:"" 
        )+
        "," +
        parseFloat(1) +
        "," +
        (pricecount() + sizeadd()).toFixed(2);
      const itemarr = items.split(",");
      if (pizza1.length == 0) {
        localStorage.setItem("pizza1", JSON.stringify(itemarr));
      } else if (pizza2.length == 0) {
        localStorage.setItem("pizza2", JSON.stringify(itemarr));
      } else if (pizza3.length == 0) {
        localStorage.setItem("pizza3", JSON.stringify(itemarr));
      } else {
        localStorage.setItem("pizza4", JSON.stringify(itemarr));
      }
    }
  };

  const handleQuantityChange = (name, operation) => {
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          let newQuantity =
            operation === "add" ? topping.quantity + 1 : topping.quantity - 1;
          if (newQuantity < 1) newQuantity = 1;
          return { ...topping, quantity: newQuantity };
        }
        return topping;
      })
    );
  };

  const getSelectedToppings = () => {
    return toppings.filter((topping) => topping.selected);
  };

  const sizeadd = () => {
    if (selectedCrust == "Gluten-free" || selectedCrust == "Cauliflower") {
      return 5;
    } else {
      return 0;
    }
  };
  return (
    <div>
      <div>
        <div
          style={{
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
            <img
              src={logo}
              style={{
                width: width > "450" ? "30vw" : "40vw",
                alignSelf: "flex-start",
                margin: 20,
              }}
            />
          </NavLink>
          <CartIcon color={"#ff0000"} />
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <h1
          style={{
            color: "#ff0000",
            fontSize: width < "468" ? "8vw" : "5vw",
            fontFamily: "MyFont",
            marginTop: 0,
            textAlign: "center",
          }}
        >
          {pizzaname !=
          "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
            ? pizzaname
            : "Mega deal"}
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "800px",

              width: "90vw",
            }}
          >
            <p
              style={{
                fontFamily: "MyFont",
                fontSize: width < "468" ? "8vw" : "3vw",
                color: "#ff0000",
                marginBottom: 0,
                marginTop: 10,
              }}
            >
              Size
            </p>
            {pizza1.length > 0 && (
              <p
                style={{ fontFamily: "MyFont", color: "#ff0000", marginTop: 0 }}
              >
                Size can only be changed when choosing the first pizza.
              </p>
            )}
            {pizza2.length > 0 && (
              <p
                style={{ fontFamily: "MyFont", color: "#ff0000", marginTop: 0 }}
              >
                To change the size please delete the previous selection.
              </p>
            )}
            <div className="size">
              {size.map((siz) => {
                return (
                  <div className="radio-button-container">
                    <div style={{ marginBottom: pizza1.length > 0 ? 10 : 0 }}>
                      <input
                        type="radio"
                        id={siz.name}
                        name="size"
                        value={siz.name}
                        checked={
                          pizza1.length > 0
                            ? siz.name.includes(pizza1[1])
                            : pizza2.length > 0
                            ? siz.name.includes(pizza2[1])
                            : selectedOption == siz.name
                        }
                        onChange={(e) => setSelectedOption(e.target.value)}
                        disabled={true}
                      />
                      <label
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                        htmlFor={siz.name}
                      >
                        {siz.name}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="cheese">
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Cheese
              </p>
              {toppings.map(
                (topping) =>
                  topping.type == "cheese" && (
                    <div
                      key={topping.name}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        name={topping.name}
                        onChange={handleChange}
                        checked={topping.selected}
                      />
                      <label
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                      >
                        {topping.name}
                      </label>
                      {topping.selected ? (
                        <div
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          <button
                            style={{ border: "0px", background: "#ffffff" }}
                            onClick={() =>
                              handleQuantityChange(topping.name, "minus")
                            }
                          >
                            <img
                              src={min}
                              style={{
                                width: width < "468" ? "3vw" : "1vw",
                                marginTop: 8,
                              }}
                            />
                          </button>
                          <span
                            style={{
                              margin: "0 10px",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                            }}
                          >
                            {topping.quantity}
                          </span>
                          <button
                            style={{ border: "0px", background: "#ffffff" }}
                            onClick={() =>
                              handleQuantityChange(topping.name, "add")
                            }
                          >
                            <img
                              src={plu}
                              style={{
                                width: width < "468" ? "4vw" : "1.5vw",
                                marginTop: 8,
                              }}
                            />
                          </button>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          <p
                            style={{
                              color: "#ff0000",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                              margin: 0,
                              justifyContent: "flex-end",
                            }}
                          >
                            +$
                            {(!toppingcounts()
                              ? 0 * topping.multi
                              : selectedOption == "Large"
                              ? topping.multi * 2
                              : selectedOption == "Medium"
                              ? topping.multi * 1.5
                              : topping.multi * 1
                            ).toFixed(2)}
                          </p>
                        </div>
                      )}
                    </div>
                  )
              )}
            </div>

            <div className="meat">
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Meat
              </p>
              {toppings.map(
                (topping, index) =>
                  topping.type == "meat" && (
                    <div>
                      <div
                        key={topping.name}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          name={topping.name}
                          onChange={handleChange}
                          checked={topping.selected}
                        />
                        <label
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                        >
                          {topping.name}
                        </label>
                        {topping.selected ? (
                          <div
                            style={{
                              marginLeft: "auto",
                              display: "flex",
                              alignItems: "center",
                              marginTop: 0,
                              marginBottom: 10,
                            }}
                          >
                            <button
                              style={{ border: "0px", background: "#ffffff" }}
                              onClick={() =>
                                handleQuantityChange(topping.name, "minus")
                              }
                            >
                              <img
                                src={min}
                                style={{
                                  width: width < "468" ? "3vw" : "1vw",
                                  marginTop: 8,
                                }}
                              />
                            </button>
                            <span
                              style={{
                                margin: "0 10px",
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "2vw",
                              }}
                            >
                              {topping.quantity}
                            </span>
                            <button
                              style={{ border: "0px", background: "#ffffff" }}
                              onClick={() =>
                                handleQuantityChange(topping.name, "add")
                              }
                            >
                              <img
                                src={plu}
                                style={{
                                  width: width < "468" ? "4vw" : "1.5vw",
                                  marginTop: 8,
                                }}
                              />
                            </button>
                          </div>
                        ) : (
                          <div
                            style={{
                              marginLeft: "auto",
                              display: "flex",
                              alignItems: "center",
                              marginTop: 0,
                              marginBottom: 10,
                            }}
                          >
                            <p
                              style={{
                                color: "#ff0000",
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "2vw",
                                margin: 0,
                                justifyContent: "flex-end",
                              }}
                            >
                              +$
                              {(!toppingcounts()
                                ? 0 * topping.multi
                                : selectedOption == "Large"
                                ? topping.multi * 2
                                : selectedOption == "Medium"
                                ? topping.multi * 1.5
                                : topping.multi * 1
                              ).toFixed(2)}
                            </p>
                          </div>
                        )}
                      </div>
                      {topping.selected && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <img
                              src={pizzaleft}
                              style={{
                                height: 25,
                                opacity: topping.side == "left" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "left")}
                            />
                            <img
                              src={pizzawhole}
                              style={{
                                height: 25,
                                margin: "0 15px",
                                opacity: topping.side == "whole" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "whole")}
                            />
                            <img
                              src={pizzaright}
                              style={{
                                height: 25,
                                opacity: topping.side == "right" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "right")}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )
              )}
            </div>
            <div className="veg">
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Veggies
              </p>
              {toppings.map(
                (topping, index) =>
                  topping.type == "veg" && (
                    <div>
                      {" "}
                      <div
                        key={topping.name}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          name={topping.name}
                          onChange={handleChange}
                          checked={topping.selected}
                        />
                        <label
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                        >
                          {topping.name}
                        </label>
                        {topping.selected ? (
                          <div
                            style={{
                              marginLeft: "auto",
                              display: "flex",
                              alignItems: "center",
                              marginTop: 0,
                              marginBottom: 10,
                            }}
                          >
                            <button
                              style={{ border: "0px", background: "#ffffff" }}
                              onClick={() =>
                                handleQuantityChange(topping.name, "minus")
                              }
                            >
                              <img
                                src={min}
                                style={{
                                  width: width < "468" ? "3vw" : "1vw",
                                  marginTop: 8,
                                }}
                              />
                            </button>
                            <span
                              style={{
                                margin: "0 10px",
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "2vw",
                              }}
                            >
                              {topping.quantity}
                            </span>
                            <button
                              style={{ border: "0px", background: "#ffffff" }}
                              onClick={() =>
                                handleQuantityChange(topping.name, "add")
                              }
                            >
                              <img
                                src={plu}
                                style={{
                                  width: width < "468" ? "4vw" : "1.5vw",
                                  marginTop: 8,
                                }}
                              />
                            </button>
                          </div>
                        ) : (
                          <div
                            style={{
                              marginLeft: "auto",
                              display: "flex",
                              alignItems: "center",
                              marginTop: 0,
                              marginBottom: 10,
                            }}
                          >
                            <p
                              style={{
                                color: "#ff0000",
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "2vw",
                                margin: 0,
                                justifyContent: "flex-end",
                              }}
                            >
                              +$
                              {(!toppingcounts()
                                ? 0 * topping.multi
                                : selectedOption == "Large"
                                ? topping.multi * 2
                                : selectedOption == "Medium"
                                ? topping.multi * 1.5
                                : topping.multi * 1
                              ).toFixed(2)}
                            </p>
                          </div>
                        )}
                      </div>
                      {topping.selected && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <img
                              src={pizzaleft}
                              style={{
                                height: 25,
                                opacity: topping.side == "left" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "left")}
                            />
                            <img
                              src={pizzawhole}
                              style={{
                                height: 25,
                                margin: "0 15px",
                                opacity: topping.side == "whole" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "whole")}
                            />
                            <img
                              src={pizzaright}
                              style={{
                                height: 25,
                                opacity: topping.side == "right" ? 1 : 0.5,
                              }}
                              onClick={() => handleside(index, "right")}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )
              )}
            </div>
            <div
              style={{
                maxWidth: "800px",

                width: "90vw",
              }}
            >
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Crust
              </p>
              <div>
                {crusts.map((crust, index) => {
                  if (crust.size === "All" || crust.size === selectedOption)
                    return (
                      <div className="radio-button-container-crust" key={index}>
                        <input
                          type="radio"
                          name="crust"
                          value={crust.name}
                          checked={selectedCrust === crust.name}
                          onChange={() => setSelectedCrust(crust.name)}
                        />
                        <label
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                          onClick={() => setSelectedCrust(crust.name)}
                        >
                          {crust.name}
                        </label>
                        <p
                          style={{
                            fontFamily: "MyFont",
                            color: "#ff0000",
                            fontSize: width < "468" ? "6vw" : "2vw",
                            marginLeft: "auto",
                            marginTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          +${crust.price.toFixed(2)}
                        </p>
                      </div>
                    );
                })}
              </div>
            </div>
            <p
              style={{
                fontFamily: "MyFont",
                fontSize: width < "468" ? "8vw" : "3vw",
                color: "#ff0000",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              Sauce
            </p>
            <div className="size">
              {sauce.map((siz) => {
                if (siz.type == "sauce") {
                  return (
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        id={siz.name}
                        name="sauce"
                        value={siz.name}
                        checked={selectedSauce === siz.name}
                        onChange={(e) => setSelectedSauce(e.target.value)}
                      />
                      <label
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                        htmlFor={siz.name}
                      >
                        {siz.name}
                      </label>
                      <p
                        style={{
                          fontFamily: "MyFont",
                          color: "#ff0000",
                          fontSize: width < "468" ? "6vw" : "2vw",
                          marginLeft: "auto",
                          marginTop: 0,
                          marginBottom: 10,
                        }}
                      >
                        +$
                        {(!toppingcounts()
                          ? 0 * siz.multi
                          : selectedOption == "Large"
                          ? siz.multi * 2
                          : selectedOption == "Medium"
                          ? siz.multi * 1.5
                          : siz.multi * 1
                        ).toFixed(2)}
                      </p>
                    </div>
                  );
                }
              })}
            </div>
            <div className="instructions" style={{ marginBottom: "100px" }}>
              <label>
                <p
                  style={{
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "8vw" : "3vw",
                    color: "#ff0000",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  Special Instructions
                </p>

                <textarea
                  className="resizable"
                  value={instructions}
                  onChange={handleInstructionsChange}
                  style={{
                    width: "93%",
                    maxWidth: "800px",
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "6vw" : "2vw",
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <NavLink
          to={{
            pathname: "/party",
            search: createSearchParams({
              Name: pizzaname,
              price: pizzaprice,
            }).toString(),
          }}
          onClick={submit}
        >
          <button
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              position: "fixed",
              bottom: "10px",
              height: "auto",
              padding: 15,
              borderRadius: 25,
              background: "#ff0000",
            }}
          >
            <span
              style={{
                color: "white",
                fontFamily: "MyFont",
                fontSize: width < "450" ? 20 : 30,
              }}
            >
              Submit +$
              {
                /* {parseFloat(
                getSelectedToppings().reduce(
                  (acc, topping) =>
                    acc + topping.multi * topping.quantity * priceS,
                  sizeadd() +
                    parseFloat(pizzaprice) +
                    unedit.length *
                      (selectedOption == "Large"
                        ? 2
                        : selectedOption == "Medium"
                        ? 1.5
                        : 1)
                )
              ).toFixed(2)} */
                (pricecount() + sizeadd()).toFixed(2)
              }
            </span>
          </button>
        </NavLink>
      </div>
    </div>
  );
}

export default Partytopps;
