import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import plu from "./img/plus.png";
import min from "./img/minus.png";
import React, { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import "./topping.css";
import { MdOutlineDelete } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";

import { useLocation } from "react-router-dom";
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
  NavLink,
} from "react-router-dom";

function Partydeals() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();
  const pizzaname = searchparams.get("Name");
  const pizzaprice = searchparams.get("price");
  const [width, setWidth] = useState(window.innerWidth);
  const [pre, setPre] = useState([]);
  const [getwings, setGetwings] = useState(false);
  const [pizza1, setPizza1] = useState(
    JSON.parse(localStorage.getItem("pizza1") || "[]")
  );

  const [pizza2, setPizza2] = useState(
    JSON.parse(localStorage.getItem("pizza2") || "[]")
  );
  const [pizza3, setPizza3] = useState(
    JSON.parse(localStorage.getItem("pizza3") || "[]")
  );
  const [pizza4, setPizza4] = useState(
    JSON.parse(localStorage.getItem("pizza4") || "[]")
  );
  const [wings, setWings] = useState(
    JSON.parse(localStorage.getItem("wings") || "[]")
  );

  const [navi, setNavi] = useState("/partytops");
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");

  const handleChange = () => {
    setGetwings(!getwings);
  };
  useEffect(() => {
    const temp = searchparams.get("Name").split(" ");
    console.log(wings);
    setPre(temp);
    console.log("Pizza1 ", pizza1);
    console.log("Pizza2 ", pizza2);
    console.log("Wings ", wings);
    if (temp[0] == "3") {
      localStorage.removeItem("pizza4");
      setPizza4([]);
    }
    if (wings.length != 0) {
      if (temp[0] == "3" && wings[1] == "3 LB") {
        setGetwings(true);
      } else if (temp[0] == "4" && wings[1] == "4 LB") {
        setGetwings(true);
      } else {
        setGetwings(false);
        localStorage.removeItem("wings");
        setWings([]);
      }
    }
  }, [searchparams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //   const [error, setError] = useState(false);

  const handlesubmit = (e) => {
    //e.preventDefault();
    let order = [];
    order.push(
      pizzaname ==
        "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
        ? "Mega deal"
        : pizzaname
    );
    order.push("");
    let error = false;
    let pricee = parseFloat(pizzaprice);
    console.log(pizzaprice);
    console.log(pizzaname);
    console.log(pricee);

    if (pizza1.length > 0) {
      if (pizza2.length > 0) {
        if (pizza3.length > 0) {
          if (pre[0] == "4") {
            if (pizza4.length > 0) {
              pizza1.map((mapp, index) => {
                if (index < pizza1.length - 2 && index > 0) {
                  if (mapp != "") order.push(`Pizza 1: ${mapp}`);
                }
              });
              pizza2.map((mapp, index) => {
                if (index < pizza2.length - 2 && index > 0) {
                  if (mapp != "") order.push(`Pizza 2: ${mapp}`);
                }
              });
              pizza3.map((mapp, index) => {
                if (index < pizza3.length - 2 && index > 0) {
                  if (mapp != "") order.push(`Pizza 3: ${mapp}`);
                }
              });
              pizza4.map((mapp, index) => {
                if (index < pizza4.length - 2 && index > 0) {
                  if (mapp != "") order.push(`Pizza 4: ${mapp}`);
                }
              });
              if (getwings) {
                if (wings.length > 0) {
                  wings.map((mapp, index) => {
                    if (index < wings.length - 2 && index > 0) {
                      if (mapp != "") order.push(`Wings: ${mapp}`);
                    }
                  });
                } else {
                  error = true;
                }
              }
              pricee =
                pricee +
                parseFloat(pizza1[pizza1.length - 1]) +
                parseFloat(pizza2[pizza2.length - 1]) +
                parseFloat(pizza3[pizza3.length - 1]) +
                getwings
                  ? pre[0] == 3
                    ? 31.95
                    : 41.95
                  : 0 + parseFloat(pizza4[pizza4.length - 1]);
              console.log(pricee, "both pizza");
            } else {
              error = true;
            }
          } else {
            pizza1.map((mapp, index) => {
              if (index < pizza1.length - 2 && index > 0) {
                if (mapp != "") order.push(`Pizza 1: ${mapp}`);
              }
            });
            pizza2.map((mapp, index) => {
              if (index < pizza2.length - 2 && index > 0) {
                if (mapp != "") order.push(`Pizza 2: ${mapp}`);
              }
            });
            pizza3.map((mapp, index) => {
              if (index < pizza3.length - 2 && index > 0) {
                if (mapp != "") order.push(`Pizza 3: ${mapp}`);
              }
            });
            if (getwings) {
              if (wings.length > 0) {
                wings.map((mapp, index) => {
                  if (index < wings.length - 2 && index > 0) {
                    if (mapp != "") order.push(`Wings: ${mapp}`);
                  }
                });
              } else {
                error = true;
              }
            }

            pricee =
              pricee +
              parseFloat(pizza1[pizza1.length - 1]) +
              parseFloat(pizza2[pizza2.length - 1]) +
              getwings
                ? pre[0] == 3
                  ? 31.95
                  : 41.95
                : 0 + parseFloat(pizza3[pizza3.length - 1]);
            console.log(pricee, "pizza");
          }
        } else {
          error = true;
        }
      } else {
        error = true;
      }
    } else {
      error = true;
    }
    order.push("1");
    console.log(pizza1[pizza1.length - 1]);
    console.log(pizza2[pizza2.length - 1]);
    console.log(wings[wings.length - 1]);
    if (
      pizzaname ==
      "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
    ) {
      order.push(pricee.toString());
    } else {
      order.push(checkprice().toString());
    }
    if (error) {
      alert("Please choose all the options mentioned");
      e.preventDefault();
    } else {
      itemarr = [...itemarr, order];
      localStorage.setItem("array", JSON.stringify(itemarr));
      localStorage.removeItem("pizza1");
      localStorage.removeItem("pizza2");
      localStorage.removeItem("pizza3");
      localStorage.removeItem("pizza4");
      localStorage.removeItem("wings");
      setPizza1([]);
      setPizza2([]);
      setPizza3([]);
      setPizza4([]);
      setWings([]);
    }
    //return pricee;
  };
  const checkprice = () => {
    let pricee = parseFloat(pizzaprice);

    // if (panzi == false) {
    //   if (pizza1.length > 0) {
    //     if (pre[0] == "2") {
    //       if (pizza2.length > 0) {
    //         pricee =
    //           pricee +
    //           parseFloat(pizza1[pizza1.length - 1]) +
    //           parseFloat(pizza2[pizza2.length - 1]);
    //       }
    //     } else {
    //       pricee = pricee + parseFloat(pizza1[pizza1.length - 1]);
    //     }
    //     if (pre.length > 2) {
    //       if (wings.length > 1) {
    //         pricee = pricee + parseFloat(wings[wings.length - 1]);
    //         console.log(pricee, "wings");
    //       }
    //     }
    //   }
    // } else {
    //   if (panzi1.length > 0) {
    //     if (pre[0] == "2") {
    //       if (panzi2.length > 0) {
    //         pricee =
    //           pricee +
    //           parseFloat(panzi1[panzi1.length - 1]) +
    //           parseFloat(panzi2[panzi2.length - 1]);
    //       }
    //     } else {
    //       pricee = pricee + parseFloat(panzi1[panzi1.length - 1]);
    //     }
    //     if (pre.length > 2) {
    //       if (wings.length > 1) {
    //         pricee = pricee + parseFloat(wings[wings.length - 1]);
    //       }
    //     }
    //   }
    // }
    // if (
    //   pizzaname ==
    //   "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
    // ) {
    //   if (salad.length > 0) {
    //     pricee = pricee + parseFloat(salad[salad.length - 1]);
    //   }
    //   if (pop.length > 0) {
    //     pricee = pricee + parseFloat(pop[pop.length - 1]);
    //   }
    // }
    if (pizza1.length > 0) {
      pricee = pricee + parseFloat(pizza1[pizza1.length - 1]);
    }
    if (pizza2.length > 0) {
      pricee = pricee + parseFloat(pizza2[pizza2.length - 1]);
    }
    if (pizza3.length > 0) {
      pricee = pricee + parseFloat(pizza3[pizza3.length - 1]);
    }
    if (pizza4.length > 0) {
      pricee = pricee + parseFloat(pizza4[pizza4.length - 1]);
    }
    if (wings.length > 0) {
      pricee = pricee + parseFloat(wings[wings.length - 1]);
    }
    if (getwings) {
      if (pre[0] == "4") {
        pricee = pricee + 41.95;
      } else {
        pricee = pricee + 31.95;
      }
    }
    console.log(pricee, pizzaprice);
    return pricee;
  };
  return (
    <div>
      <div>
        <div
          style={{
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
            <img
              src={logo}
              style={{
                width: width > "450" ? "30vw" : "40vw",
                alignSelf: "flex-start",
                margin: 20,
              }}
            />
          </NavLink>
          <CartIcon color={"#ff0000"} />
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "90vw",
          }}
        >
          <div
            style={{
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 100,
            }}
          >
            <h1
              style={{
                color: "#ff0000",
                fontSize: width < "468" ? "8vw" : "5vw",
                fontFamily: "MyFont",
                marginTop: 0,
                textAlign: "center",
              }}
            >
              {pizzaname !=
              "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
                ? pizzaname
                : "Mega deal"}
            </h1>
            <div style={{ width: "100%", marginBottom: 100 }}>
              <>
                <>
                  {pizza1.length == 0 ? (
                    <NavLink
                      to={{
                        pathname: "/partytops",
                        search: createSearchParams({
                          from: "pwo",
                          Name: pizzaname,
                          price: pizzaprice,
                        }).toString(),
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          background: "#f5f5f5",
                          borderRadius: 10,
                          height: 60,
                          border: "1px dashed #ccc",
                          margin: 5,
                          display: "flex",
                          //   flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IoMdAddCircleOutline
                          color="red"
                          size={24}
                          style={{
                            fontFamily: "MyFont",
                            opacity: 0.7,
                            // border: "1px solid black",
                          }}
                        />
                        <p
                          style={{
                            // border: "1px solid black",
                            opacity: 0.7,
                            color: "black",
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                        >
                          Please add item
                        </p>
                      </div>
                    </NavLink>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "MyFont",
                            height: width < "468" ? "9vw" : "4vw",
                            fontSize: width < "468" ? "8vw" : "3vw",
                            color: "#ff0000",
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          {pizza1[0]}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "MyFont",
                              fontSize: width < 468 ? "8vw" : "3vw",
                              color: "#ff0000",
                              margin: 0,
                              padding: 0,
                              flexShrink: 0, // <-- add this
                            }}
                          >
                            <MdOutlineDelete
                              onClick={() => {
                                localStorage.removeItem("pizza1");
                                setPizza1([]);
                              }}
                              style={{
                                marginTop: 4.5,
                                color: "#ff0000",
                                fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                              }}
                            />
                          </p>
                        </div>
                      </div>

                      <div style={{ marginBottom: 10, display: "flex" }}>
                        <div>
                          {pizza1.map((mapp, index) => {
                            if (index > 0 && index < pizza1.length - 2) {
                              console.log(mapp);
                              return (
                                <p
                                  style={{
                                    marginRight: 0,
                                    marginBottom: 0,
                                    marginTop: 0,
                                    marginLeft: 15,
                                    fontFamily: "MyFont",
                                    fontSize: width < "468" ? "6vw" : "2vw",
                                  }}
                                >
                                  {mapp}
                                </p>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </>
                  )}

                  <>
                    {pizza2.length == 0 ? (
                      <NavLink
                        to={{
                          pathname: "/partytops",
                          search: createSearchParams({
                            from: "pwo",
                            Name: pizzaname,
                            price: pizzaprice,
                          }).toString(),
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            background: "#f5f5f5",
                            borderRadius: 10,
                            height: 60,
                            border: "1px dashed #ccc",
                            margin: 5,
                            display: "flex",
                            //   flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IoMdAddCircleOutline
                            color="red"
                            size={24}
                            style={{
                              fontFamily: "MyFont",
                              opacity: 0.7,
                              // border: "1px solid black",
                            }}
                          />
                          <p
                            style={{
                              // border: "1px solid black",
                              opacity: 0.7,
                              color: "black",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                            }}
                          >
                            Please add item
                          </p>
                        </div>
                      </NavLink>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "MyFont",
                              height: width < "468" ? "9vw" : "4vw",
                              fontSize: width < "468" ? "8vw" : "3vw",
                              color: "#ff0000",
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            {pizza2[0]}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "MyFont",
                                fontSize: width < 468 ? "8vw" : "3vw",
                                color: "#ff0000",
                                margin: 0,
                                padding: 0,
                                flexShrink: 0, // <-- add this
                              }}
                            >
                              <MdOutlineDelete
                                onClick={() => {
                                  localStorage.removeItem("pizza2");
                                  setPizza2([]);
                                }}
                                style={{
                                  marginTop: 4.5,
                                  color: "#ff0000",
                                  fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                }}
                              />
                            </p>
                          </div>
                        </div>

                        <div style={{ marginBottom: 10, display: "flex" }}>
                          <div>
                            {pizza2.map((mapp, index) => {
                              if (index > 0 && index < pizza2.length - 2)
                                return (
                                  <p
                                    style={{
                                      marginRight: 0,
                                      marginBottom: 0,
                                      marginTop: 0,
                                      marginLeft: 15,
                                      fontFamily: "MyFont",
                                      fontSize: width < "468" ? "6vw" : "2vw",
                                    }}
                                  >
                                    {mapp}
                                  </p>
                                );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <>
                    {pizza3.length == 0 ? (
                      <NavLink
                        to={{
                          pathname: "/partytops",
                          search: createSearchParams({
                            from: "pwo",
                            Name: pizzaname,
                            price: pizzaprice,
                          }).toString(),
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            background: "#f5f5f5",
                            borderRadius: 10,
                            height: 60,
                            border: "1px dashed #ccc",
                            margin: 5,
                            display: "flex",
                            //   flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IoMdAddCircleOutline
                            color="red"
                            size={24}
                            style={{
                              fontFamily: "MyFont",
                              opacity: 0.7,
                              // border: "1px solid black",
                            }}
                          />
                          <p
                            style={{
                              // border: "1px solid black",
                              opacity: 0.7,
                              color: "black",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                            }}
                          >
                            Please add item
                          </p>
                        </div>
                      </NavLink>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "MyFont",
                              height: width < "468" ? "9vw" : "4vw",
                              fontSize: width < "468" ? "8vw" : "3vw",
                              color: "#ff0000",
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            {pizza3[0]}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "MyFont",
                                fontSize: width < 468 ? "8vw" : "3vw",
                                color: "#ff0000",
                                margin: 0,
                                padding: 0,
                                flexShrink: 0, // <-- add this
                              }}
                            >
                              <MdOutlineDelete
                                onClick={() => {
                                  localStorage.removeItem("pizza3");
                                  setPizza3([]);
                                }}
                                style={{
                                  marginTop: 4.5,
                                  color: "#ff0000",
                                  fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                }}
                              />
                            </p>
                          </div>
                        </div>

                        <div style={{ marginBottom: 10, display: "flex" }}>
                          <div>
                            {pizza3.map((mapp, index) => {
                              if (index > 0 && index < pizza3.length - 2)
                                return (
                                  <p
                                    style={{
                                      marginRight: 0,
                                      marginBottom: 0,
                                      marginTop: 0,
                                      marginLeft: 15,
                                      fontFamily: "MyFont",
                                      fontSize: width < "468" ? "6vw" : "2vw",
                                    }}
                                  >
                                    {mapp}
                                  </p>
                                );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  {pre[0] == "4" && (
                    <>
                      {pizza4.length == 0 ? (
                        <NavLink
                          to={{
                            pathname: "/partytops",
                            search: createSearchParams({
                              from: "pwo",
                              Name: pizzaname,
                              price: pizzaprice,
                            }).toString(),
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              background: "#f5f5f5",
                              borderRadius: 10,
                              height: 60,
                              border: "1px dashed #ccc",
                              margin: 5,
                              display: "flex",
                              //   flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IoMdAddCircleOutline
                              color="red"
                              size={24}
                              style={{
                                fontFamily: "MyFont",
                                opacity: 0.7,
                                // border: "1px solid black",
                              }}
                            />
                            <p
                              style={{
                                // border: "1px solid black",
                                opacity: 0.7,
                                color: "black",
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "2vw",
                              }}
                            >
                              Please add item
                            </p>
                          </div>
                        </NavLink>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "MyFont",
                                height: width < "468" ? "9vw" : "4vw",
                                fontSize: width < "468" ? "8vw" : "3vw",
                                color: "#ff0000",
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                            >
                              {pizza4[0]}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "MyFont",
                                  fontSize: width < 468 ? "8vw" : "3vw",
                                  color: "#ff0000",
                                  margin: 0,
                                  padding: 0,
                                  flexShrink: 0, // <-- add this
                                }}
                              >
                                <MdOutlineDelete
                                  onClick={() => {
                                    localStorage.removeItem("pizza4");
                                    setPizza4([]);
                                  }}
                                  style={{
                                    marginTop: 4.5,
                                    color: "#ff0000",
                                    fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                  }}
                                />
                              </p>
                            </div>
                          </div>

                          <div style={{ marginBottom: 10, display: "flex" }}>
                            <div>
                              {pizza4.map((mapp, index) => {
                                if (index > 0 && index < pizza4.length - 2)
                                  return (
                                    <p
                                      style={{
                                        marginRight: 0,
                                        marginBottom: 0,
                                        marginTop: 0,
                                        marginLeft: 15,
                                        fontFamily: "MyFont",
                                        fontSize: width < "468" ? "6vw" : "2vw",
                                      }}
                                    >
                                      {mapp}
                                    </p>
                                  );
                              })}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>{" "}
              </>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: -80,
              }}
            >
              <input
                type="checkbox"
                name="Wings"
                onChange={handleChange}
                checked={getwings}
              />
              <label
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "6vw" : "2vw",
                }}
              >
                {pre[0] == "4" ? "Add 4LB wings" : "Add 3LB wings"}
              </label>
              <p
                style={{
                  fontFamily: "MyFont",
                  color: "#ff0000",
                  fontSize: width < "468" ? "6vw" : "2vw",
                  marginLeft: "auto",
                  marginTop: 0,
                  marginBottom: 10,
                }}
              >
                +${(pre[0] == "4" ? 41.95 : 31.95).toFixed(2)}
              </p>
            </div>
            {getwings && (
              <div>
                {wings.length == 0 ? (
                  <NavLink
                    to={{
                      pathname: "/partywings",
                      search: createSearchParams({
                        from: "pwo",
                        Name: pizzaname,
                        count: pre[0],
                        price: pizzaprice,
                      }).toString(),
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        background: "#f5f5f5",
                        borderRadius: 10,
                        height: 60,
                        border: "1px dashed #ccc",
                        margin: 5,
                        display: "flex",
                        //   flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IoMdAddCircleOutline
                        color="red"
                        size={24}
                        style={{
                          fontFamily: "MyFont",
                          opacity: 0.7,
                          // border: "1px solid black",
                        }}
                      />
                      <p
                        style={{
                          // border: "1px solid black",
                          opacity: 0.7,
                          color: "black",
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                      >
                        Please add item
                      </p>
                    </div>
                  </NavLink>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "MyFont",
                          height: width < "468" ? "9vw" : "4vw",
                          fontSize: width < "468" ? "8vw" : "3vw",
                          color: "#ff0000",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        {wings[0]}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < 468 ? "8vw" : "3vw",
                            color: "#ff0000",
                            margin: 0,
                            padding: 0,
                            flexShrink: 0, // <-- add this
                          }}
                        >
                          <MdOutlineDelete
                            onClick={() => {
                              localStorage.removeItem("wings");
                              setWings([]);
                            }}
                            style={{
                              marginTop: 4.5,
                              color: "#ff0000",
                              fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                            }}
                          />
                        </p>
                      </div>
                    </div>

                    <div style={{ marginBottom: 10, display: "flex" }}>
                      <div>
                        {wings.map((mapp, index) => {
                          if (index > 0 && index < wings.length - 2)
                            return (
                              <p
                                style={{
                                  marginRight: 0,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  marginLeft: 15,
                                  fontFamily: "MyFont",
                                  fontSize: width < "468" ? "6vw" : "2vw",
                                }}
                              >
                                {mapp}
                              </p>
                            );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            <NavLink to={"/cart"} onClick={handlesubmit}>
              <button
                style={{
                  left: "50%",
                  transform: "translateX(-50%)",
                  position: "fixed",
                  bottom: "10px",
                  height: "auto",
                  padding: 15,
                  borderRadius: 25,
                  background: "#ff0000",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontFamily: "MyFont",
                    fontSize: width < "450" ? 20 : 30,
                  }}
                >
                  Submit ${checkprice().toFixed(2)}
                </span>
              </button>
            </NavLink>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default Partydeals;
