import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import plu from "./img/plus.png";
import min from "./img/minus.png";
import React, { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import "./topping.css";
import { MdOutlineDelete } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";

import { useLocation } from "react-router-dom";
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
  NavLink,
} from "react-router-dom";

function Pwoption() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();
  const pizzaname = searchparams.get("Name");
  const pizzaprice = searchparams.get("price");
  const [width, setWidth] = useState(window.innerWidth);
  const [pre, setPre] = useState([]);
  const [pizza1, setPizza1] = useState(
    JSON.parse(localStorage.getItem("pizza1") || "[]")
  );
  const [panzi1, setPanzi1] = useState(
    JSON.parse(localStorage.getItem("panzi1") || "[]")
  );
  const [panzi2, setPanzi2] = useState(
    JSON.parse(localStorage.getItem("panzi2") || "[]")
  );
  const [pizza2, setPizza2] = useState(
    JSON.parse(localStorage.getItem("pizza2") || "[]")
  );

  const [wings, setWings] = useState(
    JSON.parse(localStorage.getItem("wings") || "[]")
  );
  const [salad, setSalad] = useState(
    JSON.parse(localStorage.getItem("salad") || "[]")
  );
  const [pop, setPop] = useState(
    JSON.parse(localStorage.getItem("pop") || "[]")
  );
  const [panzi, setpanzi] = useState(false);
  const [navi, setNavi] = useState("/dealtops");
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");

  useEffect(() => {
    const temp = searchparams.get("Name").split(" ");
    console.log(salad);
    setPre(temp);
    console.log("Pizza1 ", pizza1);
    console.log("Pizza2 ", pizza2);
    console.log("Wings ", wings);
    if (temp[1] == "panzerotti") {
      console.log("this is working", temp[1]);
      setNavi("/dealpanz");
      setpanzi(true);
    } else {
      setpanzi(false);
      setNavi("/dealtops");
    }
    if (temp[0] == "1") {
      localStorage.removeItem("pizza2");
      setPizza2([]);
    }
  }, [searchparams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //   const [error, setError] = useState(false);

  const handlesubmit = (e) => {
    //e.preventDefault();
    let order = [];
    order.push(
      pizzaname ==
        "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
        ? "Mega deal"
        : pizzaname
    );
    order.push("");
    let error = false;
    let pricee = parseFloat(pizzaprice);
    console.log(pizzaprice);
    console.log(pizzaname);
    console.log(pricee);
    if (panzi == false) {
      if (pizza1.length > 0) {
        if (pre[0] == "2") {
          if (pizza2.length > 0) {
            pizza1.map((mapp, index) => {
              if (index < pizza1.length - 2 && index > 0) {
                if (mapp != "") order.push(`Pizza 1: ${mapp}`);
              }
            });
            pizza2.map((mapp, index) => {
              if (index < pizza2.length - 2 && index > 0) {
                if (mapp != "") order.push(`Pizza 2: ${mapp}`);
              }
            });
            pricee =
              pricee +
              parseFloat(pizza1[pizza1.length - 1]) +
              parseFloat(pizza2[pizza2.length - 1]);
            console.log(pricee, "both pizza");
          } else {
            error = true;
          }
        } else {
          pizza1.map((mapp, index) => {
            if (index < pizza1.length - 2 && index > 0) {
              if (mapp != "") order.push(`Pizza 1: ${mapp}`);
            }
          });
          pricee = pricee + parseFloat(pizza1[pizza1.length - 1]);
          console.log(pricee, "pizza");
        }
        if (pre.length > 2) {
          if (wings.length > 1) {
            wings.map((mapp, index) => {
              if (index < wings.length - 2 && index > 0) {
                if (mapp != "") order.push(`Wings: ${mapp}`);
              }
            });

            pricee = pricee + parseFloat(wings[wings.length - 1]);
            console.log(pricee, "wings");
          } else {
            error = true;
          }
        }
      } else {
        error = true;
      }
    } else {
      if (panzi1.length > 0) {
        if (pre[0] == "2") {
          if (panzi2.length > 0) {
            panzi1.map((mapp, index) => {
              if (index < panzi1.length - 2 && index > 0) {
                if (mapp != "") order.push(`Panzerotti 1: ${mapp}`);
              }
            });
            panzi2.map((mapp, index) => {
              if (index < panzi2.length - 2 && index > 0) {
                if (mapp != "") order.push(`Panzerotti 2: ${mapp}`);
              }
            });
            pricee =
              pricee +
              parseFloat(panzi1[panzi1.length - 1]) +
              parseFloat(panzi2[panzi2.length - 1]);
          } else {
            error = true;
          }
        } else {
          panzi1.map((mapp, index) => {
            if (index < panzi1.length - 2 && index > 0) {
              if (mapp != "") order.push(`Panzerotti 1: ${mapp}`);
            }
          });
          pricee = pricee + parseFloat(panzi1[panzi1.length - 1]);
        }
        if (pre.length > 2) {
          if (wings.length > 1) {
            wings.map((mapp, index) => {
              if (index < wings.length - 2 && index > 0) {
                if (mapp != "") order.push(`Wings: ${mapp}`);
              }
            });
            pricee = pricee + parseFloat(wings[wings.length - 1]);
          } else {
            error = true;
          }
        }
      } else {
        error = true;
      }
    }
    if (
      pizzaname ==
      "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
    ) {
      if (salad.length > 1) {
        salad.map((mapp, index) => {
          if (index < salad.length - 2) {
            if (mapp != "") order.push(`Salad: ${mapp}`);
          }
        });
      } else {
        error = true;
      }
      if (pop.length > 1) {
        pop.map((mapp, index) => {
          if (index < pop.length - 2 && index > 0) {
            if (mapp != "") order.push(`Pop: ${mapp}`);
          }
        });
      } else {
        error = true;
      }
    }
    order.push("1");
    console.log(pizza1[pizza1.length - 1]);
    console.log(pizza2[pizza2.length - 1]);
    console.log(wings[wings.length - 1]);
    order.push(pricee.toString());
    if (error) {
      alert("Please choose all the options mentioned");
      e.preventDefault();
    } else {
      itemarr = [...itemarr, order];
      localStorage.setItem("array", JSON.stringify(itemarr));
      localStorage.removeItem("pizza1");
      localStorage.removeItem("pizza2");
      localStorage.removeItem("wings");
      localStorage.removeItem("panzi1");
      localStorage.removeItem("panzi2");
      localStorage.removeItem("salad");
      localStorage.removeItem("pop");
      setPanzi1([]);
      setPanzi2([]);
      setWings([]);
      setPizza1([]);
      setPizza2([]);
      setSalad([]);
      setPop([]);
    }
    //return pricee;
  };
  const checkprice = () => {
    let pricee = parseFloat(pizzaprice);

    // if (panzi == false) {
    //   if (pizza1.length > 0) {
    //     if (pre[0] == "2") {
    //       if (pizza2.length > 0) {
    //         pricee =
    //           pricee +
    //           parseFloat(pizza1[pizza1.length - 1]) +
    //           parseFloat(pizza2[pizza2.length - 1]);
    //       }
    //     } else {
    //       pricee = pricee + parseFloat(pizza1[pizza1.length - 1]);
    //     }
    //     if (pre.length > 2) {
    //       if (wings.length > 1) {
    //         pricee = pricee + parseFloat(wings[wings.length - 1]);
    //         console.log(pricee, "wings");
    //       }
    //     }
    //   }
    // } else {
    //   if (panzi1.length > 0) {
    //     if (pre[0] == "2") {
    //       if (panzi2.length > 0) {
    //         pricee =
    //           pricee +
    //           parseFloat(panzi1[panzi1.length - 1]) +
    //           parseFloat(panzi2[panzi2.length - 1]);
    //       }
    //     } else {
    //       pricee = pricee + parseFloat(panzi1[panzi1.length - 1]);
    //     }
    //     if (pre.length > 2) {
    //       if (wings.length > 1) {
    //         pricee = pricee + parseFloat(wings[wings.length - 1]);
    //       }
    //     }
    //   }
    // }
    // if (
    //   pizzaname ==
    //   "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
    // ) {
    //   if (salad.length > 0) {
    //     pricee = pricee + parseFloat(salad[salad.length - 1]);
    //   }
    //   if (pop.length > 0) {
    //     pricee = pricee + parseFloat(pop[pop.length - 1]);
    //   }
    // }
    if (pizza1.length > 0) {
      pricee = pricee + parseFloat(pizza1[pizza1.length - 1]);
    }
    if (pizza2.length > 0) {
      pricee = pricee + parseFloat(pizza2[pizza2.length - 1]);
    }
    if (wings.length > 0) {
      pricee = pricee + parseFloat(wings[wings.length - 1]);
    }
    if (panzi1.length > 0) {
      pricee = pricee + parseFloat(panzi1[panzi1.length - 1]);
    }
    if (panzi2.length > 0) {
      pricee = pricee + parseFloat(panzi2[panzi2.length - 1]);
    }
    if (salad.length > 0) {
      pricee = pricee + parseFloat(salad[salad.length - 1]);
    }
    if (pop.length > 0) {
      pricee = pricee + parseFloat(pop[pop.length - 1]);
    }
    console.log(pricee, pizzaprice);
    return pricee;
  };
  return (
    <div>
      <div>
        <div
          style={{
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
            <img
              src={logo}
              style={{
                width: width > "450" ? "30vw" : "40vw",
                alignSelf: "flex-start",
                margin: 20,
              }}
            />
          </NavLink>
          <CartIcon color={"#ff0000"} />
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "90vw",
          }}
        >
          <div
            style={{
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <h1
              style={{
                color: "#ff0000",
                fontSize: width < "468" ? "8vw" : "5vw",
                fontFamily: "MyFont",
                marginTop: 0,
                textAlign: "center",
              }}
            >
              {pizzaname !=
              "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop "
                ? pizzaname
                : "Mega deal"}
            </h1>
            <div style={{ width: "100%", marginBottom: 100 }}>
              <>
                {panzi == false ? (
                  <>
                    {pizza1.length == 0 ? (
                      <NavLink
                        to={{
                          pathname: "/dealtops",
                          search: createSearchParams({
                            from: "pwo",
                            Name: pizzaname,
                            price: pizzaprice,
                          }).toString(),
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            background: "#f5f5f5",
                            borderRadius: 10,
                            height: 60,
                            border: "1px dashed #ccc",
                            margin: 5,
                            display: "flex",
                            //   flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IoMdAddCircleOutline
                            color="red"
                            size={24}
                            style={{
                              fontFamily: "MyFont",
                              opacity: 0.7,
                              // border: "1px solid black",
                            }}
                          />
                          <p
                            style={{
                              // border: "1px solid black",
                              opacity: 0.7,
                              color: "black",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                            }}
                          >
                            Please add item
                          </p>
                        </div>
                      </NavLink>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "MyFont",
                              height: width < "468" ? "9vw" : "4vw",
                              fontSize: width < "468" ? "8vw" : "3vw",
                              color: "#ff0000",
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            {pizza1[0]}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "MyFont",
                                fontSize: width < 468 ? "8vw" : "3vw",
                                color: "#ff0000",
                                margin: 0,
                                padding: 0,
                                flexShrink: 0, // <-- add this
                              }}
                            >
                              <MdOutlineDelete
                                onClick={() => {
                                  localStorage.removeItem("pizza1");
                                  setPizza1([]);
                                }}
                                style={{
                                  marginTop: 4.5,
                                  color: "#ff0000",
                                  fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                }}
                              />
                            </p>
                          </div>
                        </div>

                        <div style={{ marginBottom: 10, display: "flex" }}>
                          <div>
                            {pizza1.map((mapp, index) => {
                              if (index > 0 && index < pizza1.length - 2) {
                                console.log(mapp);
                                return (
                                  <p
                                    style={{
                                      marginRight: 0,
                                      marginBottom: 0,
                                      marginTop: 0,
                                      marginLeft: 15,
                                      fontFamily: "MyFont",
                                      fontSize: width < "468" ? "6vw" : "2vw",
                                    }}
                                  >
                                    {mapp}
                                  </p>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </>
                    )}
                    {pre[0] == "2" && (
                      <>
                        {pizza2.length == 0 ? (
                          <NavLink
                            to={{
                              pathname: "/dealtops",
                              search: createSearchParams({
                                from: "pwo",
                                Name: pizzaname,
                                price: pizzaprice,
                              }).toString(),
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                background: "#f5f5f5",
                                borderRadius: 10,
                                height: 60,
                                border: "1px dashed #ccc",
                                margin: 5,
                                display: "flex",
                                //   flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IoMdAddCircleOutline
                                color="red"
                                size={24}
                                style={{
                                  fontFamily: "MyFont",
                                  opacity: 0.7,
                                  // border: "1px solid black",
                                }}
                              />
                              <p
                                style={{
                                  // border: "1px solid black",
                                  opacity: 0.7,
                                  color: "black",
                                  fontFamily: "MyFont",
                                  fontSize: width < "468" ? "6vw" : "2vw",
                                }}
                              >
                                Please add item
                              </p>
                            </div>
                          </NavLink>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "MyFont",
                                  height: width < "468" ? "9vw" : "4vw",
                                  fontSize: width < "468" ? "8vw" : "3vw",
                                  color: "#ff0000",
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {pizza2[0]}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "MyFont",
                                    fontSize: width < 468 ? "8vw" : "3vw",
                                    color: "#ff0000",
                                    margin: 0,
                                    padding: 0,
                                    flexShrink: 0, // <-- add this
                                  }}
                                >
                                  <MdOutlineDelete
                                    onClick={() => {
                                      localStorage.removeItem("pizza2");
                                      setPizza2([]);
                                    }}
                                    style={{
                                      marginTop: 4.5,
                                      color: "#ff0000",
                                      fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                    }}
                                  />
                                </p>
                              </div>
                            </div>

                            <div style={{ marginBottom: 10, display: "flex" }}>
                              <div>
                                {pizza2.map((mapp, index) => {
                                  if (index > 0 && index < pizza2.length - 2)
                                    return (
                                      <p
                                        style={{
                                          marginRight: 0,
                                          marginBottom: 0,
                                          marginTop: 0,
                                          marginLeft: 15,
                                          fontFamily: "MyFont",
                                          fontSize:
                                            width < "468" ? "6vw" : "2vw",
                                        }}
                                      >
                                        {mapp}
                                      </p>
                                    );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {pre.length > 2 && (
                      <>
                        {wings.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "MyFont",
                                  height: width < "468" ? "9vw" : "4vw",
                                  fontSize: width < "468" ? "8vw" : "3vw",
                                  color: "#ff0000",
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {wings[0]}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "MyFont",
                                    fontSize: width < 468 ? "8vw" : "3vw",
                                    color: "#ff0000",
                                    margin: 0,
                                    padding: 0,
                                    flexShrink: 0, // <-- add this
                                  }}
                                >
                                  <MdOutlineDelete
                                    onClick={() => {
                                      localStorage.removeItem("wings");
                                      setWings([]);
                                    }}
                                    style={{
                                      marginTop: 4.5,
                                      color: "#ff0000",
                                      fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                    }}
                                  />
                                </p>
                              </div>
                            </div>

                            <div style={{ marginBottom: 10, display: "flex" }}>
                              <div>
                                {wings.map((mapp, index) => {
                                  if (index > 0 && index < wings.length - 2)
                                    return (
                                      <p
                                        style={{
                                          marginRight: 0,
                                          marginBottom: 0,
                                          marginTop: 0,
                                          marginLeft: 15,
                                          fontFamily: "MyFont",
                                          fontSize:
                                            width < "468" ? "6vw" : "2vw",
                                        }}
                                      >
                                        {mapp}
                                      </p>
                                    );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <NavLink
                            to={{
                              pathname: "/dealtopwings",
                              search: createSearchParams({
                                size: pre[3],
                                price: pizzaprice,
                                Name: pizzaname,
                              }).toString(),
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                background: "#f5f5f5",
                                borderRadius: 10,
                                height: 60,
                                border: "1px dashed #ccc",
                                margin: 5,
                                display: "flex",
                                //   flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IoMdAddCircleOutline
                                color="red"
                                size={24}
                                style={{
                                  fontFamily: "MyFont",
                                  opacity: 0.7,
                                  // border: "1px solid black",
                                }}
                              />
                              <p
                                style={{
                                  // border: "1px solid black",
                                  opacity: 0.7,
                                  color: "black",
                                  fontFamily: "MyFont",
                                  fontSize: width < "468" ? "6vw" : "2vw",
                                }}
                              >
                                Please add item
                              </p>
                            </div>
                          </NavLink>
                        )}
                      </>
                    )}
                    {pizzaname ==
                      "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop " && (
                      <>
                        {salad.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "MyFont",
                                  height: width < "468" ? "9vw" : "4vw",
                                  fontSize: width < "468" ? "8vw" : "3vw",
                                  color: "#ff0000",
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {salad[0]}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "MyFont",
                                    fontSize: width < 468 ? "8vw" : "3vw",
                                    color: "#ff0000",
                                    margin: 0,
                                    padding: 0,
                                    flexShrink: 0, // <-- add this
                                  }}
                                >
                                  <MdOutlineDelete
                                    onClick={() => {
                                      localStorage.removeItem("salad");
                                      setSalad([]);
                                    }}
                                    style={{
                                      marginTop: 4.5,
                                      color: "#ff0000",
                                      fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                    }}
                                  />
                                </p>
                              </div>
                            </div>

                            <div style={{ marginBottom: 10, display: "flex" }}>
                              <div>
                                {salad.map((mapp, index) => {
                                  if (index > 0 && index < salad.length - 2)
                                    return (
                                      <p
                                        style={{
                                          marginRight: 0,
                                          marginBottom: 0,
                                          marginTop: 0,
                                          marginLeft: 15,
                                          fontFamily: "MyFont",
                                          fontSize:
                                            width < "468" ? "6vw" : "2vw",
                                        }}
                                      >
                                        {mapp}
                                      </p>
                                    );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <NavLink
                            to={{
                              pathname: "/salad",
                              search: createSearchParams({
                                size: "Large",
                                price: pizzaprice,
                                Name: pizzaname,
                                from: "pwo",
                              }).toString(),
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                background: "#f5f5f5",
                                borderRadius: 10,
                                height: 60,
                                border: "1px dashed #ccc",
                                margin: 5,
                                display: "flex",
                                //   flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IoMdAddCircleOutline
                                color="red"
                                size={24}
                                style={{
                                  fontFamily: "MyFont",
                                  opacity: 0.7,
                                  // border: "1px solid black",
                                }}
                              />
                              <p
                                style={{
                                  // border: "1px solid black",
                                  opacity: 0.7,
                                  color: "black",
                                  fontFamily: "MyFont",
                                  fontSize: width < "468" ? "6vw" : "2vw",
                                }}
                              >
                                Please add item
                              </p>
                            </div>
                          </NavLink>
                        )}
                      </>
                    )}
                    {pizzaname ==
                      "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop " && (
                      <>
                        {pop.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "MyFont",
                                  height: width < "468" ? "9vw" : "4vw",
                                  fontSize: width < "468" ? "8vw" : "3vw",
                                  color: "#ff0000",
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {pop[0]}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "MyFont",
                                    fontSize: width < 468 ? "8vw" : "3vw",
                                    color: "#ff0000",
                                    margin: 0,
                                    padding: 0,
                                    flexShrink: 0, // <-- add this
                                  }}
                                >
                                  <MdOutlineDelete
                                    onClick={() => {
                                      localStorage.removeItem("pop");
                                      setPop([]);
                                    }}
                                    style={{
                                      marginTop: 4.5,
                                      color: "#ff0000",
                                      fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                    }}
                                  />
                                </p>
                              </div>
                            </div>

                            <div style={{ marginBottom: 10, display: "flex" }}>
                              <div>
                                {pop.map((mapp, index) => {
                                  if (index > 0 && index < pop.length - 2)
                                    return (
                                      <p
                                        style={{
                                          marginRight: 0,
                                          marginBottom: 0,
                                          marginTop: 0,
                                          marginLeft: 15,
                                          fontFamily: "MyFont",
                                          fontSize:
                                            width < "468" ? "6vw" : "2vw",
                                        }}
                                      >
                                        {mapp}
                                      </p>
                                    );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <NavLink
                            to={{
                              pathname: "/pop",
                              search: createSearchParams({
                                size: pre[3],
                                price: pizzaprice,
                                Name: pizzaname,
                                from: "pwo",
                              }).toString(),
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                background: "#f5f5f5",
                                borderRadius: 10,
                                height: 60,
                                border: "1px dashed #ccc",
                                margin: 5,
                                display: "flex",
                                //   flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IoMdAddCircleOutline
                                color="red"
                                size={24}
                                style={{
                                  fontFamily: "MyFont",
                                  opacity: 0.7,
                                  // border: "1px solid black",
                                }}
                              />
                              <p
                                style={{
                                  // border: "1px solid black",
                                  opacity: 0.7,
                                  color: "black",
                                  fontFamily: "MyFont",
                                  fontSize: width < "468" ? "6vw" : "2vw",
                                }}
                              >
                                Please add item
                              </p>
                            </div>
                          </NavLink>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {panzi1.length == 0 ? (
                      <NavLink
                        to={{
                          pathname: "/panzitops",
                          search: createSearchParams({
                            from: "pwo",
                            Name: pizzaname,
                            price: pizzaprice,
                          }).toString(),
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            background: "#f5f5f5",
                            borderRadius: 10,
                            height: 60,
                            border: "1px dashed #ccc",
                            margin: 5,
                            display: "flex",
                            //   flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IoMdAddCircleOutline
                            color="red"
                            size={24}
                            style={{
                              fontFamily: "MyFont",
                              opacity: 0.7,
                              // border: "1px solid black",
                            }}
                          />
                          <p
                            style={{
                              // border: "1px solid black",
                              opacity: 0.7,
                              color: "black",
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "2vw",
                            }}
                          >
                            Please add item
                          </p>
                        </div>
                      </NavLink>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "MyFont",
                              height: width < "468" ? "9vw" : "4vw",
                              fontSize: width < "468" ? "8vw" : "3vw",
                              color: "#ff0000",
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            {panzi1[0]}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "MyFont",
                                fontSize: width < 468 ? "8vw" : "3vw",
                                color: "#ff0000",
                                margin: 0,
                                padding: 0,
                                flexShrink: 0, // <-- add this
                              }}
                            >
                              <MdOutlineDelete
                                onClick={() => {
                                  localStorage.removeItem("panzi1");
                                  setPanzi1([]);
                                }}
                                style={{
                                  marginTop: 4.5,
                                  color: "#ff0000",
                                  fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                }}
                              />
                            </p>
                          </div>
                        </div>

                        <div style={{ marginBottom: 10, display: "flex" }}>
                          <div>
                            {panzi1.map((mapp, index) => {
                              if (index > 0 && index < panzi1.length - 2) {
                                console.log(mapp);
                                return (
                                  <p
                                    style={{
                                      marginRight: 0,
                                      marginBottom: 0,
                                      marginTop: 0,
                                      marginLeft: 15,
                                      fontFamily: "MyFont",
                                      fontSize: width < "468" ? "6vw" : "2vw",
                                    }}
                                  >
                                    {mapp}
                                  </p>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </>
                    )}
                    {pre[0] == "2" && (
                      <>
                        {panzi2.length == 0 ? (
                          <NavLink
                            to={{
                              pathname: "/panzitops",
                              search: createSearchParams({
                                from: "pwo",
                                Name: pizzaname,
                                price: pizzaprice,
                              }).toString(),
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                background: "#f5f5f5",
                                borderRadius: 10,
                                height: 60,
                                border: "1px dashed #ccc",
                                margin: 5,
                                display: "flex",
                                //   flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IoMdAddCircleOutline
                                color="red"
                                size={24}
                                style={{
                                  fontFamily: "MyFont",
                                  opacity: 0.7,
                                  // border: "1px solid black",
                                }}
                              />
                              <p
                                style={{
                                  // border: "1px solid black",
                                  opacity: 0.7,
                                  color: "black",
                                  fontFamily: "MyFont",
                                  fontSize: width < "468" ? "6vw" : "2vw",
                                }}
                              >
                                Please add item
                              </p>
                            </div>
                          </NavLink>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "MyFont",
                                  height: width < "468" ? "9vw" : "4vw",
                                  fontSize: width < "468" ? "8vw" : "3vw",
                                  color: "#ff0000",
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {panzi2[0]}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "MyFont",
                                    fontSize: width < 468 ? "8vw" : "3vw",
                                    color: "#ff0000",
                                    margin: 0,
                                    padding: 0,
                                    flexShrink: 0, // <-- add this
                                  }}
                                >
                                  <MdOutlineDelete
                                    onClick={() => {
                                      localStorage.removeItem("panzi2");
                                      setPanzi2([]);
                                    }}
                                    style={{
                                      marginTop: 4.5,
                                      color: "#ff0000",
                                      fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                    }}
                                  />
                                </p>
                              </div>
                            </div>

                            <div style={{ marginBottom: 10, display: "flex" }}>
                              <div>
                                {panzi2.map((mapp, index) => {
                                  if (index > 0 && index < panzi2.length - 2)
                                    return (
                                      <p
                                        style={{
                                          marginRight: 0,
                                          marginBottom: 0,
                                          marginTop: 0,
                                          marginLeft: 15,
                                          fontFamily: "MyFont",
                                          fontSize:
                                            width < "468" ? "6vw" : "2vw",
                                        }}
                                      >
                                        {mapp}
                                      </p>
                                    );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {pre.length > 2 && (
                      <>
                        {wings.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "MyFont",
                                  height: width < "468" ? "9vw" : "4vw",
                                  fontSize: width < "468" ? "8vw" : "3vw",
                                  color: "#ff0000",
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {wings[0]}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "MyFont",
                                    fontSize: width < 468 ? "8vw" : "3vw",
                                    color: "#ff0000",
                                    margin: 0,
                                    padding: 0,
                                    flexShrink: 0, // <-- add this
                                  }}
                                >
                                  <MdOutlineDelete
                                    onClick={() => {
                                      localStorage.removeItem("wings");
                                      setWings([]);
                                    }}
                                    style={{
                                      marginTop: 4.5,
                                      color: "#ff0000",
                                      fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                                    }}
                                  />
                                </p>
                              </div>
                            </div>

                            <div style={{ marginBottom: 10, display: "flex" }}>
                              <div>
                                {wings.map((mapp, index) => {
                                  if (index > 0 && index < wings.length - 2)
                                    return (
                                      <p
                                        style={{
                                          marginRight: 0,
                                          marginBottom: 0,
                                          marginTop: 0,
                                          marginLeft: 15,
                                          fontFamily: "MyFont",
                                          fontSize:
                                            width < "468" ? "6vw" : "2vw",
                                        }}
                                      >
                                        {mapp}
                                      </p>
                                    );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <NavLink
                            to={{
                              pathname: "/dealtopwings",
                              search: createSearchParams({
                                size: pre[3],
                                Name: pizzaname,
                                price: pizzaprice,
                              }).toString(),
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                background: "#f5f5f5",
                                borderRadius: 10,
                                height: 60,
                                border: "1px dashed #ccc",
                                margin: 5,
                                display: "flex",
                                //   flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IoMdAddCircleOutline
                                color="red"
                                size={24}
                                style={{
                                  fontFamily: "MyFont",
                                  opacity: 0.7,
                                  // border: "1px solid black",
                                }}
                              />
                              <p
                                style={{
                                  // border: "1px solid black",
                                  opacity: 0.7,
                                  color: "black",
                                  fontFamily: "MyFont",
                                  fontSize: width < "468" ? "6vw" : "2vw",
                                }}
                              >
                                Please add item
                              </p>
                            </div>
                          </NavLink>
                        )}
                      </>
                    )}
                  </>
                )}{" "}
              </>
            </div>

            <NavLink to={"/cart"} onClick={handlesubmit}>
              <button
                style={{
                  left: "50%",
                  transform: "translateX(-50%)",
                  position: "fixed",
                  bottom: "10px",
                  height: "auto",
                  padding: 15,
                  borderRadius: 25,
                  background: "#ff0000",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontFamily: "MyFont",
                    fontSize: width < "450" ? 20 : 30,
                  }}
                >
                  Submit ${checkprice().toFixed(2)}
                </span>
              </button>
            </NavLink>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default Pwoption;
