import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import background from "./img/pizzabg.jpg";
import background2 from "./img/bg2.jpg";
import background3 from "./img/bg3.jpg";
import { useLocation } from "react-router-dom";
import lasagna from "./img/lasagna.png";
import pizza from "./img/pizza.png";
import coke from "./img/coke.png";
import fish from "./img/fishchip.png";
import salad from "./img/salad.png";
import sides from "./img/sides.png";
import slide2 from "./img/slide2.png";
import salad1 from "./img/salad1.jpg";
import salad2 from "./img/salad2.jpg";
import salad3 from "./img/salad3.jpg";
import React, { useState, useEffect } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import { useSearchParams, createSearchParams, NavLink } from "react-router-dom";

function Salad() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();

  const from = searchparams.get("from");
  const pizzaname = searchparams.get("Name");
  const pizzaprice = searchparams.get("price");
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(from);
  }, [pathname]);
  const [selectedSizes, setSelectedSizes] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const properties = {
    duration: 2000,
    transitionDuration: 250,
    infinite: true,
    indicators: false,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
  };
  const handleButtonClick = (where) => {
    const targetClass = document.querySelector(where);
    setIsButtonClicked(true);
    targetClass.scrollIntoView({ behavior: "smooth" });
  };
  const handleSizeChange = (index) => (e) => {
    setSelectedSizes({ ...selectedSizes, [index]: e.target.value });
  };
  const [salads, setSalads] = useState([
    {
      name: "fries",
      price: 0,
      toggle: false,
      description: "Served with renee Dressing, real Bacon, croutons, cheese",

      type: "fries",
    },
    {
      name: "Caesar",
      price: 10.95,
      toggle: false,
      description: "Served with renee Dressing, real Bacon, croutons, cheese",

      type: "salad",
    },
    {
      name: "Garden",
      price: 10.95,
      toggle: false,
      description:
        "Served with ranch dressin, mushrooms, onions, freen peppers, tomatoes, cucumbers",
      type: "salad",
    },
    {
      name: "Greek",
      price: 10.95,
      toggle: false,
      description:
        "Served with Greek Dressing, Tomatoes, Red Onions, Cucumbers, Feta Cheese",
      type: "salad",
    },
    {
      name: "Grilled bacon",
      price: 10.95,
      toggle: false,
      description:
        "Grilled chicken, real bacon, tomatoes, cucumber, read onions",
      type: "salad",
    },
    {
      name: "Chicken taco",
      price: 10.95,
      toggle: false,
      description:
        "Chicken, Tomatoes, jalapeno, cheddar, cheese & red onions, side of sour cream",
      type: "salad",
    },
  ]);
  const sizes = ["Small", "Large"];
  const handleSubmit = (index) => (e) => {
    var items = "";
    if (from == "pwo" || selectedSizes[index]) {
      items =
        salads[index].name +
        "," +
        (from == "pwo" ? "Large" : selectedSizes[index]) +
        "," +
        parseFloat(1) +
        "," +
        (from == "pwo"
          ? parseFloat(0)
          : parseFloat(
              !selectedSizes[index]
                ? salads[index].price
                : selectedSizes[index] == "Small"
                ? salads[index].price
                : parseFloat(salads[index].price) + 2.0
            ).toFixed(2));
      itemarr = [...itemarr, items.split(",")];
      if (from == "pwo") {
        localStorage.setItem("salad", JSON.stringify(items.split(",")));
      } else {
        localStorage.setItem("array", JSON.stringify(itemarr));
      }
    } else {
      e.preventDefault();
      alert(`Please select a size for your ${salads[index].name} salad.`);
    }
  };

  const fadeImages = [
    {
      url: salad1,
      caption: "First Slide",
    },
    {
      url: salad2,
      caption: "Second Slide",
    },
    {
      url: salad3,
      caption: "Third Slide",
    },
  ];
  return (
    <div className="slide-container">
      <Fade {...properties}>
        {fadeImages.map((fadeImage, index) => (
          <div className="each-fade" key={index}>
            <div
              style={{
                position: "absolute",
                width: "100vw",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "flex-start",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
                  <img
                    src={logo}
                    style={{
                      width: width > "450" ? "30vw" : "40vw",
                      alignSelf: "flex-start",
                      margin: 20,
                    }}
                  />
                </NavLink>
                <CartIcon color={"white"} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontSize: "8vw",
                    fontFamily: "MyFont",
                    textAlign: "center",
                    justifySelf: "flex-start",
                    marginTop: 0,
                  }}
                >
                  Check out our greens!
                </h1>
                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      width: "auto",
                      maxWidth: 200,
                      height: "auto",
                      padding: 15,
                      borderRadius: 25,
                      background: "#ff0000",
                    }}
                    onClick={() => handleButtonClick(".Salads")}
                  >
                    <span
                      style={{
                        color: "white",
                        fontFamily: "MyFont",
                        fontSize: width < "450" ? 20 : 30,
                      }}
                    >
                      {from == "pwo" ? "Please choose" : "Salads"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="image-container"
              style={{ overflow: "hidden", maxHeight: "100vh" }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  background: "black",
                  position: "absolute",
                  zIndex: 1,
                  opacity: 0.5,
                }}
              ></div>
              <img
                src={fadeImage.url}
                style={{
                  width: "100vw",
                  filter: "blur(3px)",
                }}
              />
            </div>
          </div>
        ))}
      </Fade>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="Salads"
            style={{
              width: "100vw",
              maxWidth: "800px",
            }}
          >
            {from == "pwo" && (
              <>
                <h1
                  style={{
                    color: "#ff0000",
                    fontSize: width < "468" ? "8vw" : "5vw",
                    fontFamily: "MyFont",
                    textAlign: "center",
                  }}
                >
                  Fries
                </h1>
                {salads.map((flav, index) => {
                  if (flav.type == "fries")
                    return (
                      <div
                        style={{
                          margin: 20,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          onClick={() => {
                            const newSalad = [...salads];
                            const index = newSalad.indexOf(flav);
                            newSalad[index].toggle = !newSalad[index].toggle;
                            setSalads(newSalad);
                            console.log(flav.toggle);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        >
                          <h1
                            style={{
                              fontFamily: "MyFont",
                              fontSize: width < "468" ? "6vw" : "3vw",
                              color: "#ff0000",
                            }}
                          >
                            {flav.name}
                          </h1>
                          {from != "pwo" && (
                            <h1
                              style={{
                                fontFamily: "MyFont",
                                fontSize: width < "468" ? "6vw" : "3vw",
                                color: "#ff0000",
                              }}
                            >
                              $
                              {!selectedSizes[index]
                                ? flav.price.toFixed(2)
                                : selectedSizes[index] == "Small"
                                ? flav.price.toFixed(2)
                                : parseFloat(flav.price.toFixed(2)) + 2.0}
                            </h1>
                          )}
                        </div>

                        <p
                          style={{
                            fontFamily: "MyFont",
                            margin: 0,
                            fontSize: width < "468" ? "4vw" : "2vw",
                            color: "black",
                          }}
                        >
                          {flav.description}
                        </p>
                        {flav.toggle && (
                          <div
                            style={{
                              alignSelf: "flex-end",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 20,
                            }}
                          >
                            <select
                              value={
                                from == "pwo"
                                  ? "Large"
                                  : selectedSizes[index] || ""
                              }
                              onChange={handleSizeChange(index)}
                              style={{
                                fontSize: width < "468" ? "4vw" : "2vw",
                                fontFamily: "MyFont",
                              }}
                              disabled={from == "pwo"}
                            >
                              <option value="" disabled>
                                Select Size
                              </option>
                              {sizes.map((size) => (
                                <option key={size} value={size}>
                                  {size}
                                </option>
                              ))}
                            </select>
                            <NavLink
                              to={{
                                pathname: "/saladtopping",
                                search: createSearchParams({
                                  Name: flav.name,
                                  price: flav.price,
                                  from: from == "pwo" ? "pwo" : "",
                                  pwoprice: pizzaprice,
                                  pwoname: pizzaname,
                                }).toString(),
                              }}
                              style={{
                                marginLeft: 10,
                                marginRight: 5,
                              }}
                            >
                              <button
                                style={{
                                  width: "auto",
                                  maxWidth: 200,
                                  height: "auto",
                                  padding: 10,
                                  borderRadius: 25,
                                  border: "1px solid #ff0000",
                                  background: "white",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#ff0000",
                                    fontFamily: "MyFont",
                                    fontSize: width < "450" ? "5vw" : "1.5vw",
                                  }}
                                >
                                  customize
                                </span>
                              </button>
                            </NavLink>
                            <NavLink
                              to={{
                                pathname: from == "pwo" ? "/pwo" : "/cart",
                                search: createSearchParams({
                                  Name: pizzaname,
                                  price: pizzaprice,
                                }).toString(),
                              }}
                              onClick={handleSubmit(index)}
                              style={{
                                marginLeft: 5,
                                marginRight: 10,
                              }}
                            >
                              <button
                                style={{
                                  width: "auto",
                                  maxWidth: 200,
                                  height: "auto",
                                  padding: 10,
                                  borderRadius: 25,
                                  background: "#ff0000",
                                }}
                              >
                                <span
                                  style={{
                                    color: "white",
                                    fontFamily: "MyFont",
                                    fontSize: width < "450" ? "5vw" : "1.5vw",
                                  }}
                                >
                                  add to cart
                                </span>
                              </button>
                            </NavLink>
                          </div>
                        )}
                      </div>
                    );
                })}
              </>
            )}
            <h1
              style={{
                color: "#ff0000",
                fontSize: width < "468" ? "8vw" : "5vw",
                fontFamily: "MyFont",
                textAlign: "center",
              }}
            >
              Salads
            </h1>
            {/* {salads.map((flav) => {
              return (
                <div
                  style={{
                    margin: 20,
                  }}
                >
                  <NavLink
                    to={{
                      pathname: "/saladtopping",
                      search: createSearchParams({
                        Name: flav.name,
                        price: flav.price,
                      }).toString(),
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textDecoration: "none",
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "3vw",
                        color: "#ff0000",
                      }}
                    >
                      {flav.name}
                    </h1>
                    <h1
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "3vw",
                        color: "#ff0000",
                      }}
                    >
                      ${flav.price.toFixed(2)}
                    </h1>
                  </NavLink>

                  <p
                    style={{
                      fontFamily: "MyFont",
                      margin: 0,
                      fontSize: width < "468" ? "4vw" : "2vw",
                      color: "black",
                    }}
                  >
                    {flav.description}
                  </p>
                </div>
              );
            })} */}
            {salads.map((flav, index) => {
              if (flav.type == "salad")
                return (
                  <div
                    style={{
                      margin: 20,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      onClick={() => {
                        const newSalad = [...salads];
                        const index = newSalad.indexOf(flav);
                        newSalad[index].toggle = !newSalad[index].toggle;
                        setSalads(newSalad);
                        console.log(flav.toggle);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <h1
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "3vw",
                          color: "#ff0000",
                        }}
                      >
                        {flav.name}
                      </h1>
                      {from != "pwo" && (
                        <h1
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "3vw",
                            color: "#ff0000",
                          }}
                        >
                          $
                          {!selectedSizes[index]
                            ? flav.price.toFixed(2)
                            : selectedSizes[index] == "Small"
                            ? flav.price.toFixed(2)
                            : parseFloat(flav.price.toFixed(2)) + 2.0}
                        </h1>
                      )}
                    </div>

                    <p
                      style={{
                        fontFamily: "MyFont",
                        margin: 0,
                        fontSize: width < "468" ? "4vw" : "2vw",
                        color: "black",
                      }}
                    >
                      {flav.description}
                    </p>
                    {flav.toggle && (
                      <div
                        style={{
                          alignSelf: "flex-end",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <select
                          value={
                            from == "pwo" ? "Large" : selectedSizes[index] || ""
                          }
                          onChange={handleSizeChange(index)}
                          style={{
                            fontSize: width < "468" ? "4vw" : "2vw",
                            fontFamily: "MyFont",
                          }}
                          disabled={from == "pwo"}
                        >
                          <option value="" disabled>
                            Select Size
                          </option>
                          {sizes.map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                        <NavLink
                          to={{
                            pathname: "/saladtopping",
                            search: createSearchParams({
                              Name: flav.name,
                              price: flav.price,
                              from: from == "pwo" ? "pwo" : "",
                              pwoprice: pizzaprice,
                              pwoname: pizzaname,
                            }).toString(),
                          }}
                          style={{
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        >
                          <button
                            style={{
                              width: "auto",
                              maxWidth: 200,
                              height: "auto",
                              padding: 10,
                              borderRadius: 25,
                              border: "1px solid #ff0000",
                              background: "white",
                            }}
                          >
                            <span
                              style={{
                                color: "#ff0000",
                                fontFamily: "MyFont",
                                fontSize: width < "450" ? "5vw" : "1.5vw",
                              }}
                            >
                              customize
                            </span>
                          </button>
                        </NavLink>
                        <NavLink
                          to={{
                            pathname: from == "pwo" ? "/pwo" : "/cart",
                            search: createSearchParams({
                              Name: pizzaname,
                              price: pizzaprice,
                            }).toString(),
                          }}
                          onClick={handleSubmit(index)}
                          style={{
                            marginLeft: 5,
                            marginRight: 10,
                          }}
                        >
                          <button
                            style={{
                              width: "auto",
                              maxWidth: 200,
                              height: "auto",
                              padding: 10,
                              borderRadius: 25,
                              background: "#ff0000",
                            }}
                          >
                            <span
                              style={{
                                color: "white",
                                fontFamily: "MyFont",
                                fontSize: width < "450" ? "5vw" : "1.5vw",
                              }}
                            >
                              add to cart
                            </span>
                          </button>
                        </NavLink>
                      </div>
                    )}
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Salad;
