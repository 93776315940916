import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import background from "./img/pizzabg.jpg";
import background2 from "./img/bg2.jpg";
import background3 from "./img/bg3.jpg";
import { useLocation } from "react-router-dom";
import familydeal1 from "./img/familydeals1.jpeg";
import familydeal2 from "./img/familydeals2.png";
import lasagna from "./img/lasagna.png";
import pizza from "./img/pizza.png";
import coke from "./img/coke.png";
import fish from "./img/fishchip.png";
import salad from "./img/salad.png";
import sides from "./img/sides.png";
import slide2 from "./img/slide2.png";
import React, { useState, useEffect } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import { createSearchParams, NavLink } from "react-router-dom";

function Family() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const sizes = ["Small", "Medium", "Large"];
  const [selectedSizes, setSelectedSizes] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");
  const properties = {
    duration: 2000,
    transitionDuration: 250,
    infinite: true,
    indicators: false,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
  };
  const handleButtonClick = (where) => {
    const targetClass = document.querySelector(where);
    setIsButtonClicked(true);
    targetClass.scrollIntoView({ behavior: "smooth" });
  };
  const [flavour, setFlavour] = useState([
    {
      name: "Mega deal",
      price: 49.95,
      toggle: false,
      type: "flavour",
      tops: "1 Pepperoni, 1 Bacon, 1 Ham, 1 Mushroom, 1 extra cheese",
      description:
        "2 pizzas with 2 lb wings + Large salad or Fries + 4 cans pop ",
    },
    {
      name: "3 large pizzas",
      price: 45.95,
      toggle: false,
      type: "flavour",
      tops: "1 Pepperoni, 1 Bacon, 1 Ham, 1 Mushroom, 1 extra cheese",
      description: "3 large pizzas with 4 items each",
    },
    {
      name: "4 large pizzas",
      price: 58.95,
      toggle: false,
      type: "flavour",
      tops: "1 Pepperoni, 1 Bacon, 1 Ham, 1 Mushroom, 1 extra cheese",
      description: "4 large pizzas with 4 items each",
    },
  ]);

  const fadeImages = [
    {
      url: familydeal1,
      caption: "First Slide",
    },
    {
      url: familydeal2,
      caption: "Second Slide",
    },
    {
      url: background3,
      caption: "Third Slide",
    },
  ];
  const handleSizeChange = (index) => (e) => {
    setSelectedSizes({ ...selectedSizes, [index]: e.target.value });
  };
  const [prices, setPrices] = useState([]);
  useEffect(() => {
    const updatedPrices = [];
    let pre;
    let count;
    flavour.map((mapp, index) => {
      pre = mapp.description.split(/,\s| and\s/);
      count = 0;
      pre.map((pree) => {
        console.log(selectedSizes[index]);
        if (
          [
            "Chicken",
            "Philly steak",
            "Steak",
            "Gyromeat",
            "Mozzarella cheese",
            "Feta",
          ].includes(pree)
        ) {
          count = count + 2;
        } else {
          count = count + 1;
          console.log(count);
        }
      });
      if (count > 4) {
        count =
          4 +
          (count - 4) *
            (selectedSizes[index] == "Medium"
              ? 1.5
              : selectedSizes[index] == "Large"
              ? 2
              : 1) +
          mapp.price +
          (selectedSizes[index] == "Medium"
            ? 2
            : selectedSizes[index] == "Large"
            ? 3
            : 0);
      } else {
        count =
          count +
          mapp.price +
          (selectedSizes[index] == "Medium"
            ? 2
            : selectedSizes[index] == "Large"
            ? 3
            : 0);
      }
      console.log(count);
      updatedPrices.push(count.toFixed(2));
    });
    setPrices(updatedPrices);
  }, [selectedSizes]);
  const handleSubmit = (index) => (e) => {
    var items = "";
    if (selectedSizes[index]) {
      items =
        flavour[index].name +
        "," +
        selectedSizes[index] +
        "," +
        flavour[index].tops +
        "," +
        parseFloat(1) +
        "," +
        flavour[index].price;
      itemarr = [...itemarr, items.split(",")];
      localStorage.setItem("array", JSON.stringify(itemarr));
    } else {
      e.preventDefault();
      alert(`Please select a size for your ${flavour[index].name} pizza.`);
    }
  };
  return (
    <div className="slide-container">
      <Fade {...properties}>
        {fadeImages.map((fadeImage, index) => (
          <div className="each-fade" key={index}>
            <div
              style={{
                position: "absolute",
                width: "100vw",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "flex-start",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
                  <img
                    src={logo}
                    style={{
                      width: width > "450" ? "30vw" : "40vw",
                      alignSelf: "flex-start",
                      margin: 20,
                    }}
                  />
                </NavLink>
                <CartIcon color={"white"} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontSize: "8vw",
                    fontFamily: "MyFont",
                    textAlign: "center",
                    justifySelf: "flex-start",
                    marginTop: 0,
                  }}
                >
                  Most famous family deal
                </h1>
                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      width: "auto",
                      maxWidth: 200,
                      height: "auto",
                      padding: 15,
                      borderRadius: 25,
                      background: "#ff0000",
                    }}
                    onClick={() => handleButtonClick(".flavour")}
                  >
                    <span
                      style={{
                        color: "white",
                        fontFamily: "MyFont",
                        fontSize: width < "450" ? 20 : 30,
                      }}
                    >
                      Mega deal
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="image-container"
              style={{ overflow: "hidden", maxHeight: "100vh" }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  background: "black",
                  position: "absolute",
                  zIndex: 1,
                  opacity: 0.5,
                }}
              ></div>
              <img
                src={fadeImage.url}
                style={{
                  width: "100vw",
                  filter: "blur(3px)",
                }}
              />
            </div>
          </div>
        ))}
      </Fade>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="flavour"
            style={{
              width: "100vw",
              maxWidth: "800px",
            }}
          >
            <h1
              style={{
                color: "#ff0000",
                fontSize: width < "468" ? "8vw" : "5vw",
                fontFamily: "MyFont",
                textAlign: "center",
              }}
            >
              Combos
            </h1>
            {flavour.map((flav, index) => {
              if (flav.type == "flavour") {
                return (
                  <div
                    style={{
                      margin: 20,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <NavLink
                      to={{
                        pathname: flav.name == "Mega deal" ? "/pwo" : "/party",
                        search: createSearchParams({
                          Name:
                            flav.name == "Mega deal"
                              ? flav.description
                              : flav.name,
                          price: flav.price,
                        }).toString(),
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        <h1
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "3vw",
                            color: "#ff0000",
                          }}
                        >
                          {flav.name}
                        </h1>
                        <h1
                          style={{
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "3vw",
                            color: "#ff0000",
                          }}
                        >
                          $ {flav.price}
                          {/* {!selectedSizes[index]
                          ? flav.price.toFixed(2)
                          : selectedSizes[index] == "Small"
                          ? flav.price.toFixed(2)
                          : selectedSizes[index] == "Medium"
                          ? parseFloat(flav.price.toFixed(2)) + 2
                          : parseFloat(flav.price.toFixed(2)) + 3} */}
                        </h1>
                      </div>
                    </NavLink>

                    <p
                      style={{
                        fontFamily: "MyFont",
                        margin: 0,
                        fontSize: width < "468" ? "4vw" : "2vw",
                        color: "black",
                      }}
                    >
                      {flav.description}
                    </p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Family;
