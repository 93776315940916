import logo from "./img/logonobgf.png";
import plu from "./img/plus.png";
import min from "./img/minus.png";
import React, { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import "./topping.css";
import { MdOutlineDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { db, auth } from "./firebase";
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
  NavLink,
} from "react-router-dom";

const Cart = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState();
  const [storedData, setStoredData] = useState(
    JSON.parse(localStorage.getItem("array") || "[]")
  );
  const handleDeleteItem = (index) => {
    // Remove an item from the list
    const newItems = [...storedData];
    newItems.splice(index, 1);
    setStoredData(newItems);

    // Store the updated list in local storage
    localStorage.setItem("array", JSON.stringify(newItems));
  };
  const [count, setCount] = useState(0);
  useEffect(() => {
    console.log(storedData);
    db.collection("open")
      .doc("JDntBt7qJi2PdxYuOxGK")
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            setIsOpen(doc.data().isOpen);
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.log("Error getting document:", error);
        }
      );
  }, []);

  const pricecount = () => {
    var temp = 0;
    storedData.map((item) => {
      temp =
        parseFloat(temp) +
        parseFloat(item[item.length - 1]) * item[item.length - 2];

      console.log(temp);
    });
    console.log(temp);
    console.log(storedData != [] ? storedData : "no data");
    console.log(temp);
    setCount(parseFloat(temp).toFixed(2));
  };
  useEffect(() => {
    pricecount();
    console.log(storedData);
  }, [storedData]);
  const handleQuantityChange = (index, operator) => {
    const newItems = [...storedData];
    const newinner = [...storedData[index]];
    if (operator == "add") {
      newinner.splice(
        newinner.length - 2,
        1,
        parseFloat(newinner[newinner.length - 2]) + 1
      );
    } else {
      newinner.splice(
        newinner.length - 2,
        1,
        parseFloat(newinner[newinner.length - 2]) - 1
      );
    }
    newItems.splice(index, 1, newinner);
    setStoredData(newItems);

    // Store the updated list in local storage
    localStorage.setItem("array", JSON.stringify(newItems));
  };
  return (
    <div>
      <div>
        <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
          <img
            src={logo}
            style={{
              width: width > "450" ? "30vw" : "40vw",
              alignSelf: "flex-start",
              margin: 20,
            }}
          />
        </NavLink>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "90vw",
          }}
        >
          {" "}
          {storedData.map((mapp, indexx) => (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontFamily: "MyFont",
                    height: width < "468" ? "9vw" : "4vw",
                    fontSize: width < "468" ? "8vw" : "3vw",
                    color: "#ff0000",
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  {mapp[0]}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "MyFont",
                      fontSize: width < 468 ? "8vw" : "3vw",
                      color: "#ff0000",
                      margin: 0,
                      padding: 0,
                      flexShrink: 0, // <-- add this
                    }}
                  >
                    $
                    {(
                      parseFloat(mapp[mapp.length - 1]) * mapp[mapp.length - 2]
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
              <div style={{ marginBottom: 10, display: "flex" }}>
                <div>
                  {" "}
                  {mapp.map((m, index) => {
                    if (index > 0 && index < mapp.length - 2)
                      return (
                        <p
                          style={{
                            marginRight: 0,
                            marginBottom: 0,
                            marginTop: 0,
                            marginLeft: 15,
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                        >
                          {m}
                        </p>
                      );
                  })}
                </div>
                {mapp[0] != "Pop" && mapp[0] != "Dip" && mapp[0] != "Wings" ? (
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                    {mapp[mapp.length - 2] <= 1 ? (
                      <p
                        onClick={() => handleDeleteItem(indexx)}
                        style={{
                          height: 0,

                          flexShrink: 0, // <-- add this
                          display: "flex", // <-- add this
                          alignItems: "center", // <-- add this
                          justifyContent: "center", // <-- add this
                          width: width < 468 ? "8vw" : "3vw", // <-- add this
                        }}
                      >
                        <MdOutlineDelete
                          style={{
                            marginTop: 4.5,
                            color: "#ff0000",
                            fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                          }}
                        />
                      </p>
                    ) : (
                      <>
                        <button
                          style={{ border: "0px", background: "#ffffff" }}
                          onClick={() => handleQuantityChange(indexx, "minus")}
                        >
                          <img
                            src={min}
                            style={{
                              width: width < "468" ? "3vw" : "1vw",
                              marginTop: 8,
                            }}
                          />
                        </button>
                      </>
                    )}
                    <span
                      style={{
                        margin: "0 10px",
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "2vw",
                      }}
                    >
                      {mapp[mapp.length - 2]}
                    </span>
                    <button
                      style={{ border: "0px", background: "#ffffff" }}
                      onClick={() => handleQuantityChange(indexx, "add")}
                    >
                      <img
                        src={plu}
                        style={{
                          width: width < "468" ? "4vw" : "1.5vw",
                          marginTop: 8,
                        }}
                      />
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                    <p
                      onClick={() => handleDeleteItem(indexx)}
                      style={{
                        height: 0,

                        flexShrink: 0, // <-- add this
                        display: "flex", // <-- add this
                        alignItems: "center", // <-- add this
                        justifyContent: "center", // <-- add this
                        width: width < 468 ? "8vw" : "3vw", // <-- add this
                      }}
                    >
                      <MdOutlineDelete
                        style={{
                          marginTop: 4.5,
                          color: "#ff0000",
                          fontSize: width < 468 ? "6vw" : "2vw", // <-- add this
                        }}
                      />
                    </p>
                  </div>
                )}
              </div>
            </>
          ))}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            {storedData.length > 0 ? (
              <>
                {isOpen ? (
                  <>
                    <NavLink
                      to={{
                        pathname: "/an",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        style={{
                          bottom: "10px",
                          fontFamily: "MyFont",
                          color: "white",
                          width: "25vh",
                          fontSize: width < "468" ? "6.5vw" : "2vw",
                          height: "auto",
                          padding: 0,
                          borderRadius: 25,
                          background: "#ff0000",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Submit ${count}
                        <p style={{ fontFamily: "MyFont" }}>*</p>
                      </button>
                    </NavLink>
                    <p style={{ fontFamily: "MyFont" }}>
                      * - The price listed is before taxes, delivery charges and
                      any other applicable charges.
                    </p>
                  </>
                ) : (
                  <p
                    style={{
                      fontFamily: "MyFont",
                      fontSize: width > "468" && "2vw",
                      color: "#ff0000",
                    }}
                  >
                    Sorry for the inconvenience we are not open yet please
                    submit your order once we are open.
                  </p>
                )}
              </>
            ) : (
              <>
                <p
                  style={{
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "6.5vw" : "2vw",
                  }}
                >
                  The cart is empty please choose and add food from menu.
                </p>
                <NavLink to={"/"} style={{ textDecoration: "none" }}>
                  {" "}
                  <button
                    style={{
                      bottom: "10px",
                      fontFamily: "MyFont",
                      color: "white",
                      width: "30vh",
                      fontSize: width < "468" ? "6.5vw" : "2vw",
                      height: "auto",
                      padding: 15,
                      borderRadius: 25,
                      background: "#ff0000",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    // onClick={() => {
                    //   setStoredData([]);
                    //   localStorage.removeItem("array");
                    // }}
                  >
                    Menu
                  </button>
                </NavLink>
              </>
            )}
          </div>
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Cart;
