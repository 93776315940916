import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import background from "./img/pizzabg.jpg";
import background2 from "./img/bg2.jpg";
import background3 from "./img/bg3.jpg";
import { useLocation } from "react-router-dom";
import lasagna from "./img/lasagna.png";
import pizza from "./img/pizza.png";
import coke from "./img/coke.png";
import fish from "./img/fishchip.png";
import salad from "./img/salad.png";
import sides from "./img/sides.png";
import slide2 from "./img/slide2.png";
import salad1 from "./img/salad1.jpg";
import salad2 from "./img/salad2.jpg";
import salad3 from "./img/salad3.jpg";
import putine1 from "./img/putine1.png";
import putine2 from "./img/putine2.png";
import putine3 from "./img/putine3.png";
import React, { useState, useEffect } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import { useSearchParams, createSearchParams, NavLink } from "react-router-dom";

function Fries() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();

  const from = searchparams.get("from");
  const pizzaname = searchparams.get("Name");
  const pizzaprice = searchparams.get("price");
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(pizzaprice);
  }, [pathname]);
  const [selectedSizes, setSelectedSizes] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const properties = {
    duration: 2000,
    transitionDuration: 250,
    infinite: true,
    indicators: false,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
  };
  const handleButtonClick = (where) => {
    const targetClass = document.querySelector(where);
    setIsButtonClicked(true);
    targetClass.scrollIntoView({ behavior: "smooth" });
  };
  const handleSizeChange = (index) => (e) => {
    setSelectedSizes({ ...selectedSizes, [index]: e.target.value });
  };
  const [salads, setSalads] = useState([
    {
      name: "French Fries",
      price: 6.95,
      toggle: false,
      description: "Fresh french fries",
    },
    {
      name: "Poutine",
      price: 10.95,
      toggle: false,
      description: "Fresh fries topped with flavourful gravy and cheese curds",
    },
    {
      name: "Fries or Nacho supreme",
      price: 14.95,
      toggle: false,
      description:
        "Get loaded fries or nachos and top with any toppings you want",
    },
  ]);
  const sizes = ["Small", "Large"];
  const option = ["Fries Supreme", "Nacho Supreme"];
  const handleSubmit = (index) => (e) => {
    var items = "";
    if (from == "pwo" || selectedSizes[index]) {
      items =
        salads[index].name +
        "," +
        (from == "pwo" ? "Large" : selectedSizes[index]) +
        "," +
        parseFloat(1) +
        "," +
        (from == "pwo"
          ? parseFloat(0)
          : parseFloat(
              selectedSizes[index] == "Fries Supreme" ||
                selectedSizes[index] == "Nacho Supreme"
                ? salads[index].price
                : selectedSizes[index] == "Small"
                ? salads[index].price
                : salads[index].name == "Poutine"
                ? parseFloat(salads[index].price) + 2.0
                : parseFloat(salads[index].price) + 3.0
            ).toFixed(2));
      itemarr = [...itemarr, items.split(",")];
      if (from == "pwo") {
        localStorage.setItem("salad", JSON.stringify(items.split(",")));
      } else {
        localStorage.setItem("array", JSON.stringify(itemarr));
      }
    }
    // else if (salads[index].name=="Fries or Nacho supreme"){
    //     items =
    //     salads[index].name +
    //     "," +
    //    "" +
    //     "," +
    //     parseFloat(1) +
    //     "," +
    //     (from == "pwo"
    //       ? parseFloat(0)
    //       : parseFloat(
    //          salads[index].price
    //         ).toFixed(2));
    //   itemarr = [...itemarr, items.split(",")];

    //     localStorage.setItem("array", JSON.stringify(itemarr));

    // }
    else {
      e.preventDefault();
      alert(`Please select a size for your ${salads[index].name} salad.`);
    }
  };

  const fadeImages = [
    {
      url: putine1,
      caption: "First Slide",
    },
    {
      url: putine2,
      caption: "Second Slide",
    },
    {
      url: putine3,
      caption: "Third Slide",
    },
  ];
  return (
    <div className="slide-container">
      <Fade {...properties}>
        {fadeImages.map((fadeImage, index) => (
          <div className="each-fade" key={index}>
            <div
              style={{
                position: "absolute",
                width: "100vw",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "flex-start",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
                  <img
                    src={logo}
                    style={{
                      width: width > "450" ? "30vw" : "40vw",
                      alignSelf: "flex-start",
                      margin: 20,
                    }}
                  />
                </NavLink>
                <CartIcon color={"white"} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontSize: "8vw",
                    fontFamily: "MyFont",
                    textAlign: "center",
                    justifySelf: "flex-start",
                    marginTop: 0,
                  }}
                >
                  Fries or Nachos
                </h1>
                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      width: "auto",
                      maxWidth: 200,
                      height: "auto",
                      padding: 15,
                      borderRadius: 25,
                      background: "#ff0000",
                    }}
                    onClick={() => handleButtonClick(".Salads")}
                  >
                    <span
                      style={{
                        color: "white",
                        fontFamily: "MyFont",
                        fontSize: width < "450" ? 20 : 30,
                      }}
                    >
                      Dig in
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="image-container"
              style={{ overflow: "hidden", maxHeight: "100vh" }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  background: "black",
                  position: "absolute",
                  zIndex: 1,
                  opacity: 0.5,
                }}
              ></div>
              <img
                src={fadeImage.url}
                style={{
                  width: "100vw",
                  filter: "blur(3px)",
                }}
              />
            </div>
          </div>
        ))}
      </Fade>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="Salads"
            style={{
              width: "100vw",
              maxWidth: "800px",
            }}
          >
            <h1
              style={{
                color: "#ff0000",
                fontSize: width < "468" ? "8vw" : "5vw",
                fontFamily: "MyFont",
                textAlign: "center",
              }}
            >
              Fries and Nachos
            </h1>
            {/* {salads.map((flav) => {
              return (
                <div
                  style={{
                    margin: 20,
                  }}
                >
                  <NavLink
                    to={{
                      pathname: "/saladtopping",
                      search: createSearchParams({
                        Name: flav.name,
                        price: flav.price,
                      }).toString(),
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textDecoration: "none",
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "3vw",
                        color: "#ff0000",
                      }}
                    >
                      {flav.name}
                    </h1>
                    <h1
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "3vw",
                        color: "#ff0000",
                      }}
                    >
                      ${flav.price.toFixed(2)}
                    </h1>
                  </NavLink>

                  <p
                    style={{
                      fontFamily: "MyFont",
                      margin: 0,
                      fontSize: width < "468" ? "4vw" : "2vw",
                      color: "black",
                    }}
                  >
                    {flav.description}
                  </p>
                </div>
              );
            })} */}
            {salads.map((flav, index) => {
              return (
                <div
                  style={{
                    margin: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    onClick={() => {
                      const newSalad = [...salads];
                      const index = newSalad.indexOf(flav);
                      newSalad[index].toggle = !newSalad[index].toggle;
                      setSalads(newSalad);
                      console.log(flav.toggle);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "3vw",
                        color: "#ff0000",
                      }}
                    >
                      {flav.name}
                    </h1>
                    {flav.name != "Fries or Nacho supreme" ? (
                      <h1
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "3vw",
                          color: "#ff0000",
                        }}
                      >
                        $
                        {!selectedSizes[index]
                          ? flav.price.toFixed(2)
                          : selectedSizes[index] == "Small"
                          ? flav.price.toFixed(2)
                          : flav.name == "Poutine"
                          ? parseFloat(flav.price.toFixed(2)) + 2.0
                          : parseFloat(flav.price.toFixed(2)) + 3.0}
                      </h1>
                    ) : (
                      <h1
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "3vw",
                          color: "#ff0000",
                        }}
                      >
                        ${parseFloat(flav.price.toFixed(2))}
                      </h1>
                    )}
                  </div>

                  <p
                    style={{
                      fontFamily: "MyFont",
                      margin: 0,
                      fontSize: width < "468" ? "4vw" : "2vw",
                      color: "black",
                    }}
                  >
                    {flav.description}
                  </p>
                  {flav.toggle && (
                    <div
                      style={{
                        alignSelf: "flex-end",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      {flav.name != "Fries or Nacho supreme" ? (
                        <select
                          value={
                            from == "pwo" ? "Large" : selectedSizes[index] || ""
                          }
                          onChange={handleSizeChange(index)}
                          style={{
                            fontSize: width < "468" ? "4vw" : "2vw",
                            fontFamily: "MyFont",
                          }}
                          disabled={from == "pwo"}
                        >
                          <option value="" disabled>
                            Select Size
                          </option>
                          {sizes.map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <select
                          value={
                            from == "pwo" ? "Large" : selectedSizes[index] || ""
                          }
                          onChange={handleSizeChange(index)}
                          style={{
                            fontSize: width < "468" ? "4vw" : "2vw",
                            fontFamily: "MyFont",
                          }}
                          disabled={from == "pwo"}
                        >
                          <option value="" disabled>
                            Select option
                          </option>
                          {option.map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                      )}
                      <NavLink
                        to={{
                          pathname: "/saladtopping",
                          search: createSearchParams({
                            Name: flav.name,
                            price: flav.price,
                            from: "Fries",
                          }).toString(),
                        }}
                        style={{
                          marginLeft: 10,
                          marginRight: 5,
                        }}
                      >
                        <button
                          style={{
                            width: "auto",
                            maxWidth: 200,
                            height: "auto",
                            padding: 10,
                            borderRadius: 25,
                            border: "1px solid #ff0000",
                            background: "white",
                          }}
                        >
                          <span
                            style={{
                              color: "#ff0000",
                              fontFamily: "MyFont",
                              fontSize: width < "450" ? "5vw" : "1.5vw",
                            }}
                          >
                            customize
                          </span>
                        </button>
                      </NavLink>
                      <NavLink
                        to={{
                          pathname: from == "pwo" ? "/pwo" : "/cart",
                          search: createSearchParams({
                            Name: pizzaname,
                            price: pizzaprice,
                          }).toString(),
                        }}
                        onClick={handleSubmit(index)}
                        style={{
                          marginLeft: 5,
                          marginRight: 10,
                        }}
                      >
                        <button
                          style={{
                            width: "auto",
                            maxWidth: 200,
                            height: "auto",
                            padding: 10,
                            borderRadius: 25,
                            background: "#ff0000",
                          }}
                        >
                          <span
                            style={{
                              color: "white",
                              fontFamily: "MyFont",
                              fontSize: width < "450" ? "5vw" : "1.5vw",
                            }}
                          >
                            add to cart
                          </span>
                        </button>
                      </NavLink>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fries;
