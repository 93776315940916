import ReactToPrint from "react-to-print";
import React, { forwardRef, useState } from "react";
import "./print.css";
import logo from "./img/logonobgf.png";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
const ComponentToPrint = forwardRef((props, ref) => {
  const { item } = props;
  const timeString = dayjs.utc(item.Timewant).tz("GMT").format("hh:mm A");
  // const timeStrings = dayjs(item.Time).tz("GMT").format("hh:mm A");
  const dates = new Date(item.Time.seconds * 1000);
  return (
    <div ref={ref}>
      <img
        src={logo}
        style={{
          width: 100,
        }}
      />
      <p>Name: {item.Name}</p>
      <p>Phone: {item.Phone}</p>
      <p>E-mail: {item.Email}</p>
      <p>{item.Timeoption}</p>
      <p>ordered at: {dayjs(dates.toString()).format("hh:mm A")}</p>
      <p>{item.Timewant}</p>
      {/* {item.Timeoption == "Schedule" && <p>{timeString}</p>} */}
      {item.Method == "Pick Up" ? (
        <p>Pick up</p>
      ) : (
        <p>Adress: {item.Address}</p>
      )}
      {JSON.parse(item.Order).map((itemss, index) => {
        const toppingsArray = itemss.slice(2).reduce((acc, topping) => {
          if (topping !== "") {
            acc.push(topping);
          }
          return acc;
        }, []);

        return (
          <div key={index}>
            <h2>
              {toppingsArray[toppingsArray.length - 2]}x{" "}
              {itemss[1] != "" && `${itemss[1]} `}
              {(itemss[0] != "" && itemss[1] != "") ||
                (itemss[0] != "Fries or Nacho supreme" && "- ")}
              {itemss[0] == "Build your pizza"
                ? "Pizza"
                : itemss[0] != "Fries or Nacho supreme"
                ? itemss[0]
                : ""}
              {/* $
              {(
                toppingsArray[toppingsArray.length - 1] *
                toppingsArray[toppingsArray.length - 2]
              ).toFixed(2)} */}
            </h2>
            <ul>
              {toppingsArray.map((topping, index) => {
                if (index < toppingsArray.length - 2) {
                  return <li style={{ fontSize:"22px"}}key={index}>{topping}</li>;
                }
              })}
            </ul>
          </div>
        );
      })}
      <p>
        Subtotal: $
        {(
          parseFloat(item.Price) / 1.13 -
          (item.Method == "Delivery" ? (item.Pay == "Cash" ? 4 : 5) : 0)
        ).toFixed(2)}
      </p>
      <p>
        Tax: $
        {(parseFloat(item.Price) - parseFloat(item.Price) / 1.13).toFixed(2)}
      </p>
      {item.Method == "Delivery" && (
        <p>Delivery Charges: ${(item.Pay == "Cash" ? 4 : 5).toFixed(2)}</p>
      )}
      <p>Price: ${item.Price}</p>
    </div>
  );
});

function ThermalReceipt(props) {
  const { item } = props;
  const componentRef = React.useRef();
  const [width, setWidth] = useState(window.innerWidth);

  return (
    <div>
      <ComponentToPrint ref={componentRef} item={item} />
      <ReactToPrint
        trigger={() => (
          <button
            style={{
              bottom: "10px",
              fontFamily: "MyFont",
              color: "white",
              width: width < "468" ? "10vh" : "13vh",
              // width: "auto",
              // maxWidth: 200,
              fontSize: width < "468" ? "6vw" : "2vw",
              height: "auto",
              padding: 5,
              alignSelf: "center",
              borderRadius: 25,
              background: "#ff0000",
              marginBottom: 10,
            }}
          >
            Print
          </button>
        )}
        content={() => componentRef.current}
      />
    </div>
  );
}

export default ThermalReceipt;
