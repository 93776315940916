import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import background from "./img/pizzabg.jpg";
import background2 from "./img/bg2.jpg";
import background3 from "./img/bg3.jpg";
import { useLocation } from "react-router-dom";
import fish1 from "./img/fish1.png";
import fish2 from "./img/fish2.png";
import fish3 from "./img/fish3.png";

import lasagna from "./img/lasagna.png";
import pizza from "./img/pizza.png";
import coke from "./img/coke.png";
import fish from "./img/fishchip.png";
import salad from "./img/salad.png";
import sides from "./img/sides.png";
import slide2 from "./img/slide2.png";
import salad1 from "./img/salad1.jpg";
import salad2 from "./img/salad2.jpg";
import salad3 from "./img/salad3.jpg";
import React, { useState, useEffect } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import { createSearchParams, NavLink } from "react-router-dom";

function Seafood() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [selectedSizes, setSelectedSizes] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const properties = {
    duration: 2000,
    transitionDuration: 250,
    infinite: true,
    indicators: false,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
  };
  const handleButtonClick = (where) => {
    const targetClass = document.querySelector(where);
    setIsButtonClicked(true);
    targetClass.scrollIntoView({ behavior: "smooth" });
  };
  const handleSizeChange = (index) => (e) => {
    setSelectedSizes({ ...selectedSizes, [index]: e.target.value });
  };
  const [salads, setSalads] = useState([
    {
      name: "Fish and fries",
      price: 15,
      toggle: false,
      description: "Fish with fresh crispy fries.",
    },
    {
      name: "Shrimp and fries",
      price: 15,
      toggle: false,
      description: "Shrimp with fresh crispy fries.",
    },
  ]);
  const sizes = ["Small", "Large"];
  const handleSubmit = (index) => (e) => {
    var items = "";

    items =
      salads[index].name +
      "," +
      "" +
      "," +
      parseFloat(1) +
      "," +
      parseFloat(salads[index].price).toFixed(2);
    itemarr = [...itemarr, items.split(",")];
    localStorage.setItem("array", JSON.stringify(itemarr));
  };

  const fadeImages = [
    {
      url: fish1,
      caption: "First Slide",
    },
    {
      url: fish2,
      caption: "Second Slide",
    },
    {
      url: fish3,
      caption: "Third Slide",
    },
  ];
  return (
    <div className="slide-container">
      <Fade {...properties}>
        {fadeImages.map((fadeImage, index) => (
          <div className="each-fade" key={index}>
            <div
              style={{
                position: "absolute",
                width: "100vw",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "flex-start",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
                  <img
                    src={logo}
                    style={{
                      width: width > "450" ? "30vw" : "40vw",
                      alignSelf: "flex-start",
                      margin: 20,
                    }}
                  />
                </NavLink>
                <CartIcon color={"white"} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontSize: "8vw",
                    fontFamily: "MyFont",
                    textAlign: "center",
                    justifySelf: "flex-start",
                    marginTop: 0,
                  }}
                >
                  Freshly made seafood
                </h1>
                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      width: "auto",
                      maxWidth: 200,
                      height: "auto",
                      padding: 15,
                      borderRadius: 25,
                      background: "#ff0000",
                    }}
                    onClick={() => handleButtonClick(".Salads")}
                  >
                    <span
                      style={{
                        color: "white",
                        fontFamily: "MyFont",
                        fontSize: width < "450" ? 20 : 30,
                      }}
                    >
                      get now
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="image-container"
              style={{ overflow: "hidden", maxHeight: "100vh" }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  background: "black",
                  position: "absolute",
                  zIndex: 1,
                  opacity: 0.5,
                }}
              ></div>
              <img
                src={fadeImage.url}
                style={{
                  width: "100vw",
                  filter: "blur(3px)",
                }}
              />
            </div>
          </div>
        ))}
      </Fade>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="Salads"
            style={{
              width: "100vw",
              maxWidth: "800px",
            }}
          >
            <h1
              style={{
                color: "#ff0000",
                fontSize: width < "468" ? "8vw" : "5vw",
                fontFamily: "MyFont",
                textAlign: "center",
              }}
            >
              Sea food
            </h1>
            {/* {salads.map((flav) => {
              return (
                <div
                  style={{
                    margin: 20,
                  }}
                >
                  <NavLink
                    to={{
                      pathname: "/shrimptops",
                      search: createSearchParams({
                        Name: flav.name,
                        price: flav.price,
                      }).toString(),
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textDecoration: "none",
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "3vw",
                        color: "#ff0000",
                      }}
                    >
                      {flav.name}
                    </h1>
                    <h1
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "3vw",
                        color: "#ff0000",
                      }}
                    >
                      ${flav.price.toFixed(2)}
                    </h1>
                  </NavLink>

                  <p
                    style={{
                      fontFamily: "MyFont",
                      margin: 0,
                      fontSize: width < "468" ? "4vw" : "2vw",
                      color: "black",
                    }}
                  >
                    {flav.description}
                  </p>
                </div>
              );
            })} */}
            {salads.map((flav, index) => {
              return (
                <div
                  style={{
                    margin: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    onClick={() => {
                      const newSalad = [...salads];
                      const index = newSalad.indexOf(flav);
                      newSalad[index].toggle = !newSalad[index].toggle;
                      setSalads(newSalad);
                      console.log(flav.toggle);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    {/* <NavLink
                    to={{
                      pathname: "/panzitops",
                      search: createSearchParams({
                        Name: flav.name,
                        price: flav.price,
                      }).toString(),
                    }}
                    
                  > */}
                    <h1
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "3vw",
                        color: "#ff0000",
                      }}
                    >
                      {flav.name}
                    </h1>
                    <h1
                      style={{
                        fontFamily: "MyFont",
                        fontSize: width < "468" ? "6vw" : "3vw",
                        color: "#ff0000",
                      }}
                    >
                      $
                      {!selectedSizes[index]
                        ? flav.price.toFixed(2)
                        : selectedSizes[index] == "Small"
                        ? flav.price.toFixed(2)
                        : parseFloat(flav.price.toFixed(2)) + 2.0}
                    </h1>
                  </div>

                  <p
                    style={{
                      fontFamily: "MyFont",
                      margin: 0,
                      fontSize: width < "468" ? "4vw" : "2vw",
                      color: "black",
                    }}
                  >
                    {flav.description}
                  </p>
                  {flav.toggle && (
                    <div
                      style={{
                        alignSelf: "flex-end",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      {/* <select
                        value={selectedSizes[index] || ""}
                        onChange={handleSizeChange(index)}
                        style={{
                          fontSize: width < "468" ? "4vw" : "2vw",
                          fontFamily: "MyFont",
                        }}
                      >
                        <option value="" disabled>
                          Select Size
                        </option>
                        {sizes.map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select> */}
                      <NavLink
                        to={{
                          pathname: "/shrimptops",
                          search: createSearchParams({
                            Name: flav.name,
                            price: flav.price,
                          }).toString(),
                        }}
                        style={{
                          marginLeft: 10,
                          marginRight: 5,
                        }}
                      >
                        <button
                          style={{
                            marginLeft: 10,
                            marginRight: 5,
                            width: "auto",
                            maxWidth: 200,
                            height: "auto",
                            padding: 10,
                            borderRadius: 25,
                            border: "1px solid #ff0000",
                            background: "white",
                          }}
                        >
                          <span
                            style={{
                              color: "#ff0000",
                              fontFamily: "MyFont",
                              fontSize: width < "450" ? "5vw" : "1.5vw",
                            }}
                          >
                            customize
                          </span>
                        </button>
                      </NavLink>
                      <NavLink
                        to={"/cart"}
                        onClick={handleSubmit(index)}
                        style={{
                          marginLeft: 5,
                          marginRight: 10,
                        }}
                      >
                        <button
                          style={{
                            marginLeft: 5,
                            marginRight: 10,
                            width: "auto",
                            maxWidth: 200,
                            height: "auto",
                            padding: 10,
                            borderRadius: 25,
                            background: "#ff0000",
                          }}
                        >
                          <span
                            style={{
                              color: "white",
                              fontFamily: "MyFont",
                              fontSize: width < "450" ? "5vw" : "1.5vw",
                            }}
                          >
                            add to cart
                          </span>
                        </button>
                      </NavLink>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Seafood;
