import CartIcon from "./carticon";
import logo from "./img/logonobgf.png";
import plu from "./img/plus.png";
import min from "./img/minus.png";
import React, { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import "./topping.css";
import { useLocation } from "react-router-dom";
import { useSearchParams, useNavigate, NavLink } from "react-router-dom";

function Shrimptops() {
  const { pathname } = useLocation();
  const [searchparams] = useSearchParams();
  const pizzaname = searchparams.get("Name");
  const pizzaprice = searchparams.get("price");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [width, setWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedforn, setSelectedforn] = useState(null);
  const [selectedCrust, setSelectedCrust] = useState(null);
  const [priceS, setPriceS] = useState(1);
  const [instructions, setInstructions] = useState("");
  var itemarr = JSON.parse(localStorage.getItem("array") || "[]");

  const handleInstructionsChange = (event) => {
    setInstructions(event.target.value);
  };

  const size = [
    { name: "Small", price: "+$0.00" },
    { name: "Large", price: "+$2.00" },
  ];
  const forn = [
    {
      name: "Fries",
    },
    { name: "Nachos" },
  ];
  useEffect(() => {
    if (
      (selectedOption != "Small" && selectedCrust == "Cauliflower") ||
      (selectedOption != "Medium" && selectedCrust == "Gluten-free")
    ) {
      setSelectedCrust(null);
    }
  }, [selectedOption]);

  const Toppin = [
    { name: "Upgrade to poutine", multi: 3, quantity: 1, type: "saltop" },
  ];

  const [toppings, setToppings] = useState(Toppin);
  const handleChange = (e) => {
    const { name, checked } = e.target;
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          return {
            ...topping,
            selected: checked,
            quantity: checked ? topping.quantity : 1,
          };
        }
        return topping;
      })
    );
  };
  const submit = (event) => {
    var items = "";

    console.log(getSelectedToppings());
    console.log(selectedCrust);
    console.log(selectedOption);
    console.log(instructions);
    items =
      pizzaname +
      "," +
      "" +
      "," +
      toppings
        .filter((topping) => topping.selected)
        .map((t) => t.quantity + " " + t.name)
        .join(",") +
      "," +
      (instructions!=""?"Instructions: " +instructions:"" 
        )+
      "," +
      parseFloat(1) +
      "," +
      parseFloat(
        getSelectedToppings().reduce(
          (acc, topping) => acc + topping.multi * topping.quantity * priceS,
          sizeadd() + parseFloat(pizzaprice)
        )
      ).toFixed(2);
    itemarr = [...itemarr, items.split(",")];
    localStorage.setItem("array", JSON.stringify(itemarr));
  };

  const handleQuantityChange = (name, operation) => {
    setToppings((prevToppings) =>
      prevToppings.map((topping) => {
        if (topping.name === name) {
          let newQuantity =
            operation === "add" ? topping.quantity + 1 : topping.quantity - 1;
          if (newQuantity < 1) newQuantity = 1;
          return { ...topping, quantity: newQuantity };
        }
        return topping;
      })
    );
  };

  const getSelectedToppings = () => {
    return toppings.filter((topping) => topping.selected);
  };

  const sizeadd = () => {
    if (selectedCrust == "Gluten-free" || selectedCrust == "Cauliflower") {
      if (selectedOption == "Large") {
        return 8.55;
      } else if (selectedOption == "Medium") {
        return 6.55;
      } else {
        return 5.0;
      }
    } else {
      if (selectedOption == "Large") {
        return 2;
      } else {
        return 0.0;
      }
    }
  };
  return (
    <div>
      <div>
        <div
          style={{
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
            <img
              src={logo}
              style={{
                width: width > "450" ? "30vw" : "40vw",
                alignSelf: "flex-start",
                margin: 20,
              }}
            />
          </NavLink>
          <CartIcon color={"#ff0000"} />
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <h1
          style={{
            color: "#ff0000",
            fontSize: width < "468" ? "8vw" : "5vw",
            fontFamily: "MyFont",
            marginTop: 0,
            textAlign: "center",
          }}
        >
          {pizzaname}
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "800px",

              width: "90vw",
            }}
          >
            <div className="toppings">
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "8vw" : "3vw",
                  color: "#ff0000",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Fries or Poutine
              </p>
              {toppings.map(
                (topping) =>
                  topping.type == "saltop" && (
                    <div
                      key={topping.name}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        name={topping.name}
                        onChange={handleChange}
                        checked={topping.selected}
                      />
                      <label
                        style={{
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                      >
                        {topping.name}
                      </label>
                      <p
                        style={{
                          fontFamily: "MyFont",
                          color: "#ff0000",
                          fontSize: width < "468" ? "6vw" : "2vw",
                          marginLeft: "auto",
                          marginTop: 0,
                          marginBottom: 10,
                        }}
                      >
                        +${(topping.multi * priceS).toFixed(2)}
                      </p>
                    </div>
                  )
              )}
            </div>

            <div className="instructions" style={{ marginBottom: "100px" }}>
              <label>
                <p
                  style={{
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "8vw" : "3vw",
                    color: "#ff0000",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  Special Instructions
                </p>

                <textarea
                  className="resizable"
                  value={instructions}
                  onChange={handleInstructionsChange}
                  style={{
                    width: "93%",
                    maxWidth: "800px",
                    fontFamily: "MyFont",
                    fontSize: width < "468" ? "6vw" : "2vw",
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <NavLink to={"/cart"} onClick={submit}>
          <button
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              position: "fixed",
              bottom: "10px",
              height: "auto",
              padding: 15,
              borderRadius: 25,
              background: "#ff0000",
            }}
          >
            <span
              style={{
                color: "white",
                fontFamily: "MyFont",
                fontSize: width < "450" ? 20 : 30,
              }}
            >
              Submit $
              {parseFloat(
                getSelectedToppings().reduce(
                  (acc, topping) =>
                    acc + topping.multi * topping.quantity * priceS,
                  sizeadd() + parseFloat(pizzaprice)
                )
              ).toFixed(2)}
            </span>
          </button>
        </NavLink>
      </div>
    </div>
  );
}

export default Shrimptops;
